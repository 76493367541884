import { SUCCESS_COLLAB_POSTS_ACTION } from "Actions/CollabPosts";
import {
  START_CREATE_POST,
  POST_CREATED_LOCAL,
  SUCCESS_CREATE_POST,
  ERROR_CREATE_POST,
  START_GET_ALL_POST,
  SUCCESS_GET_ALL_POST,
  ERROR_GET_ALL_POST,
  RESET_POST_REDUCER,
  START_DELETE_POST,
  SUCCESS_DELETE_POST,
  ERROR_DELETE_POST,
  START_GET_SINGLE_POST_BYID,
  SUCCESS_GET_SINGLE_POST_BYID,
  ERROR_GET_SINGLE_POST_BYID,
  START_LINK_PREVIEW,
  SUCCESS_LINK_PREVIEW,
  ERROR_LINK_PREVIEW,
  START_UPDATE_POST,
  SUCCESS_UPDATE_POST,
  ERROR_UPDATE_POST,
  START_GET_OTHER_USER_POSTS,
  SUCCESS_GET_OTHER_USER_POSTS,
  ERROR_GET_OTHER_USER_POSTS,
  START_USER_POST_LIKE,
  SUCCESS_USER_POST_LIKE,
  ERROR_USER_POST_LIKE,
  START_USER_POST_DISLIKE,
  SUCCESS_USER_POST_DISLIKE,
  ERROR_USER_POST_DISLIKE,
  START_GET_POST_COMMENTS,
  SUCCESS_GET_POST_COMMENTS,
  ERROR_GET_POST_COMMENTS,
  START_CREATE_POST_COMMENT,
  SUCCESS_CREATE_POST_COMMENT,
  ERROR_CREATE_POST_COMMENT,
  START_UPDATE_COMMENT,
  SUCCESS_UPDATE_COMMENT,
  ERROR_UPDATE_COMMENT,
  START_DELETE_COMMENT,
  SUCCESS_DELETE_COMMENT,
  ERROR_DELETE_COMMENT,
  START_GET_COMMENT_REPLIES,
  SUCCESS_GET_COMMENT_REPLIES,
  ERROR_GET_COMMENT_REPLIES,
  SOCKET_ADD_POST_LIKE,
  SOCKET_REMOVE_POST_LIKE,
  SOCKET_ADD_POST_COMMENT,
  SOCKET_DELETE_POST_COMMENT,
  SOCKET_UPDATE_POST_COMMENT,
  SOCKET_POST_VIDEO_UPLOAD,
  SOCKET_CREATE_POST,
  SOCKET_DELETE_POST,
  SOCKET_UPDATE_POST,
  START_ADD_BOOKMARK,
  SUCCESS_ADD_BOOKMARK,
  ERROR_ADD_BOOKMARK,
  START_REMOVE_BOOKMARK,
  SUCCESS_REMOVE_BOOKMARK,
  ERROR_REMOVE_BOOKMARK,
  START_GET_BOOKMARKS,
  SUCCESS_GET_BOOKMARKS,
  ERROR_GET_BOOKMARKS,
  START_GET_BOOKMARK_MEDIA,
  SUCCESS_GET_BOOKMARK_MEDIA,
  ERROR_GET_BOOKMARK_MEDIA,
  START_GET_PROFILE_MEDIA,
  SUCCESS_GET_PROFILE_MEDIA,
  ERROR_GET_PROFILE_MEDIA,
  POST_MEDIA_PROGRESS,
  START_UNLOCK_POST,
  SUCCESS_UNLOCK_POST,
  ERROR_UNLOCK_POST,
  START_CREATE_NEW_POST,
  ERROR_CREATE_NEW_POST,
  SUCCESS_CREATE_NEW_POST,
  START_UPDATE_POST_CREATORS,
  SUCCESS_UPDATE_POST_CREATORS,
  ERROR_UPDATE_POST_CREATORS,
  SOCKET_UPDATE_POST_CREATORS,
  SOCKET_IMAGE_MEDIA_UPLOADED,
  START_GET_MENTION_USERS,
  SUCCESS_GET_MENTION_USERS,
  ERROR_GET_MENTION_USERS,
  SET_POST_CONFIG_DATA,
  START_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  ERROR_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  START_I_DONT_LIKE_POST_ACTION,
  SUCCESS_I_DONT_LIKE_POST_ACTION,
  ERROR_I_DONT_LIKE_POST_ACTION,
} from "Actions/PostAction";
import { SOCKET_LOGIN_USER, SOCKET_LOGOUT_USER } from "Actions/UserAction";

const initialState = {
  createPostConfig: {
    collabUsers: [],
  },
  createPostDetail: {
    isLoading: false,
    data: {},
    error: "",
    postCreatedStatus: false,
  },
  getAllPostData: {
    isLoading: false,
    data: {},
    error: "",
    isLazyLoading: false,
  },
  myPostsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deletePostData: {
    isLoading: false,
    data: {},
    error: "",
  },
  singlePostData: {
    isLoading: false,
    data: {},
    error: "",
  },
  linkPreviewDetail: {
    isLoading: false,
    data: {},
    error: "",
  },
  updatePostDetail: {
    isLoading: false,
    data: {},
    error: "",
    postUpdatedStatus: false,
  },
  otherUserPostsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  postLikeData: {
    isLoading: false,
    data: {},
    error: "",
  },
  postDislikeData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getCommentsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  createCommentData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateCommentData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteCommentData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getCommentRepliesData: {
    isLoading: false,
    data: {},
    error: "",
  },
  addBookmarkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  removeBookmarkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getBookmarkData: {
    isLoading: false,
    data: {},
    error: "",
    isLazyLoading: false,
  },
  getProfileMediaData: {
    isLoading: false,
    data: {},
    error: "",
  },
  mediaProgressData: {
    data: {},
  },
  unlockPostData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updatePostCreatorsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  mentionUsersData: {
    isLoading: false,
    data: {},
    error: "",
  },
  hideUserPostsFromFeedData: {
    isLoading: false,
    data: {},
    error: "",
  },
  iDontLikePostData: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const postReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_POST_REDUCER: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }

    case SET_POST_CONFIG_DATA: {
      return {
        ...state,
        createPostConfig: {
          ...state?.createPostConfig,
          ...action.payload,
        },
      };
    }

    case START_UPDATE_POST_CREATORS: {
      return {
        ...state,
        updatePostCreatorsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_UPDATE_POST_CREATORS: {
      return {
        ...state,
        updatePostCreatorsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }

    case ERROR_UPDATE_POST_CREATORS: {
      return {
        ...state,
        updatePostCreatorsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SOCKET_UPDATE_POST_CREATORS: {
      const { post_id, release_forms } = action.payload;
      let postData = { ...state?.getAllPostData?.data };
      postData.posts = postData?.posts?.map((item) =>
        item?.id === post_id ? { ...item, release_forms } : item
      );
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) =>
        item?.id === post_id ? { ...item, release_forms } : item
      );
      let myPosts = { ...state?.myPostsData?.data };
      myPosts.posts = myPosts?.posts?.map((item) =>
        item?.id === post_id ? { ...item, release_forms } : item
      );
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) =>
        item?.post?.id == post_id
          ? { ...item, post: { ...item?.post, release_forms } }
          : item
      );

      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: postData,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case POST_MEDIA_PROGRESS: {
      return {
        ...state,
        mediaProgressData: {
          data: action?.payload,
        },
      };
    }

    case START_CREATE_POST: {
      return {
        ...state,
        createPostDetail: {
          isLoading: true,
          data: {},
          error: "",
          postCreatedStatus: false,
        },
      };
    }
    case POST_CREATED_LOCAL: {
      let oldPosts = { ...state?.getAllPostData?.data };
      let newPost = action?.payload?.post;
      newPost.post_videos = action?.payload?.post_videos;
      let isPostAvailable = oldPosts?.posts?.some(
        (item) => item?.id == newPost?.id
      );
      if (isPostAvailable) {
        oldPosts.posts = oldPosts?.posts?.map((item) => {
          if (item?.id == newPost?.id) {
            return {
              ...item,
              ...newPost,
            };
          } else {
            return item;
          }
        });
      } else {
        if (oldPosts?.posts?.length > 0) {
          oldPosts.posts = [newPost, ...oldPosts?.posts];
          oldPosts.post_count = oldPosts.post_count + 1;
        } else {
          oldPosts.posts = [newPost];
          oldPosts.post_count = oldPosts.post_count + 1;
        }
      }
      let myOldPosts = { ...state?.getAllPostData?.data };
      newPost.post_videos = action?.payload?.post_videos;
      let isMyPostAvailable = myOldPosts?.posts?.some(
        (item) => item?.id == newPost?.id
      );
      if (isMyPostAvailable) {
        myOldPosts.posts = myOldPosts?.posts?.map((item) => {
          if (item?.id == newPost?.id) {
            return {
              ...item,
              ...newPost,
            };
          } else {
            return item;
          }
        });
      } else {
        if (myOldPosts?.posts?.length > 0) {
          myOldPosts.posts = [newPost, ...myOldPosts?.posts];
          myOldPosts.post_count = myOldPosts.post_count + 1;
        } else {
          myOldPosts.posts = [newPost];
          myOldPosts.post_count = myOldPosts.post_count + 1;
        }
      }
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
      };
    }
    case SUCCESS_CREATE_POST: {
      return {
        ...state,
        createPostDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
          postCreatedStatus: true,
        },
      };
    }
    case ERROR_CREATE_POST: {
      return {
        ...state,
        createPostDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
          postCreatedStatus: false,
        },
      };
    }

    case START_CREATE_NEW_POST: {
      return {
        ...state,
        createPostDetail: {
          isLoading: true,
          data: {},
          error: "",
          postCreatedStatus: false,
        },
      };
    }

    case SUCCESS_CREATE_NEW_POST: {
      return {
        ...state,
        createPostDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
          postCreatedStatus: true,
        },
      };
    }

    case ERROR_CREATE_NEW_POST: {
      return {
        ...state,
        createPostDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
          postCreatedStatus: false,
        },
      };
    }

    case START_GET_ALL_POST: {
      let oldPosts = { ...state?.getAllPostData?.data };
      let myOldPosts = { ...state?.myPostsData?.data };
      return {
        ...state,
        getAllPostData: {
          isLoading:
            action?.payload?.offset > 0
              ? false
              : action?.payload?.owned
              ? false
              : true,
          data: action?.payload?.offset > 0 ? oldPosts : {},
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
        myPostsData: {
          isLoading:
            action?.payload?.offset > 0
              ? false
              : action?.payload?.owned
              ? true
              : false,
          data: action?.payload?.offset > 0 ? myOldPosts : {},
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
      };
    }
    case SUCCESS_GET_ALL_POST: {
      let oldPosts = { ...state?.getAllPostData?.data };
      let myOldPosts = { ...state?.myPostsData?.data };
      if (action?.isMyPost) {
        if (myOldPosts?.posts?.length > 0) {
          myOldPosts.posts = [...myOldPosts?.posts, ...action?.payload?.posts];
        } else {
          myOldPosts = action.payload;
        }
      } else {
        if (oldPosts?.posts?.length > 0) {
          oldPosts.posts = [...oldPosts?.posts, ...action?.payload?.posts];
        } else {
          oldPosts = action.payload;
        }
      }

      return {
        ...state,
        getAllPostData: {
          isLoading: action?.isMyPost
            ? state?.getAllPostData?.isLoading
            : false,
          data: oldPosts,
          error: "",
          isLazyLoading: false,
        },
        myPostsData: {
          isLoading: action?.isMyPost ? false : state?.myPostsData?.isLoading,
          data: myOldPosts,
          error: "",
          isLazyLoading: false,
        },
      };
    }
    case ERROR_GET_ALL_POST: {
      let oldPosts = { ...state?.getAllPostData?.data };
      let myOldPosts = { ...state?.myPostsData?.data };
      return {
        ...state,
        getAllPostData: {
          isLoading: false,
          data: oldPosts,
          error: action.payload,
          isLazyLoading: false,
        },
        myPostsData: {
          isLoading: false,
          data: myOldPosts,
          error: action.payload,
          isLazyLoading: false,
        },
      };
    }
    case START_DELETE_POST: {
      return {
        ...state,
        deletePostData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_POST: {
      return {
        ...state,
        deletePostData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DELETE_POST: {
      return {
        ...state,
        deletePostData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_SINGLE_POST_BYID: {
      return {
        ...state,
        singlePostData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_SINGLE_POST_BYID: {
      return {
        ...state,
        singlePostData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_SINGLE_POST_BYID: {
      return {
        ...state,
        singlePostData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_LINK_PREVIEW: {
      return {
        ...state,
        linkPreviewDetail: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_LINK_PREVIEW: {
      return {
        ...state,
        linkPreviewDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_LINK_PREVIEW: {
      return {
        ...state,
        linkPreviewDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_POST: {
      return {
        ...state,
        updatePostDetail: {
          isLoading: true,
          data: {},
          error: "",
          postUpdatedStatus: false,
        },
      };
    }
    case SUCCESS_UPDATE_POST: {
      return {
        ...state,
        updatePostDetail: {
          isLoading: false,
          data: action.payload,
          error: "",
          postUpdatedStatus: true,
        },
      };
    }
    case ERROR_UPDATE_POST: {
      return {
        ...state,
        updatePostDetail: {
          isLoading: false,
          data: {},
          error: action.payload,
          postUpdatedStatus: false,
        },
      };
    }
    case START_GET_OTHER_USER_POSTS: {
      let oldPosts = { ...state?.otherUserPostsData?.data };
      return {
        ...state,
        otherUserPostsData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldPosts : {},
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
      };
    }
    case SUCCESS_GET_OTHER_USER_POSTS: {
      let oldPosts = { ...state?.otherUserPostsData?.data };
      if (oldPosts?.posts?.length > 0) {
        oldPosts.posts = [...oldPosts?.posts, ...action?.payload?.posts];
      } else {
        oldPosts = action.payload;
      }
      return {
        ...state,
        otherUserPostsData: {
          isLoading: false,
          data: oldPosts,
          error: "",
          isLazyLoading: false,
        },
      };
    }
    case ERROR_GET_OTHER_USER_POSTS: {
      let oldPosts = { ...state?.otherUserPostsData?.data };
      return {
        ...state,
        otherUserPostsData: {
          isLoading: false,
          data: oldPosts,
          error: action.payload,
          isLazyLoading: false,
        },
      };
    }
    case START_USER_POST_LIKE: {
      const { post_id } = action;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: 1,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: 1,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: 1,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: 1,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == post_id) {
          return {
            ...item,
            post: {
              ...item?.post,
              like_count: item?.post?.like_count + 1,
              liked_by_user: 1,
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        postLikeData: {
          isLoading: true,
          data: {},
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }
    case SUCCESS_USER_POST_LIKE: {
      return {
        ...state,
        postLikeData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_USER_POST_LIKE: {
      return {
        ...state,
        postLikeData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_USER_POST_DISLIKE: {
      const { post_id } = action;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: 0,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: 0,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: 0,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: 0,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == post_id) {
          return {
            ...item,
            post: {
              ...item?.post,
              like_count: item?.post?.like_count - 1,
              liked_by_user: 0,
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        postDislikeData: {
          isLoading: true,
          data: {},
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }
    case SUCCESS_USER_POST_DISLIKE: {
      return {
        ...state,
        postDislikeData: {
          isLoading: false,
          data: action?.payload,
          error: "",
        },
      };
    }
    case ERROR_USER_POST_DISLIKE: {
      return {
        ...state,
        postDislikeData: {
          isLoading: false,
          data: {},
          error: action?.payload,
        },
      };
    }
    case START_GET_POST_COMMENTS: {
      return {
        ...state,
        getCommentsData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_POST_COMMENTS: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            comments: [...item?.comments, ...action?.payload?.comments],
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            comments: [...item?.comments, ...action?.payload?.comments],
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            comments: [...item?.comments, ...action?.payload?.comments],
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == action?.post_id) {
          return {
            ...item,
            post: {
              ...item?.posts,
              comments: [...item?.post?.comments, ...action?.payload?.comments],
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        getCommentsData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }
    case ERROR_GET_POST_COMMENTS: {
      return {
        ...state,
        getCommentsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_POST_COMMENT: {
      return {
        ...state,
        createCommentData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_POST_COMMENT: {
      return {
        ...state,
        createCommentData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_CREATE_POST_COMMENT: {
      return {
        ...state,
        createCommentData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_COMMENT: {
      return {
        ...state,
        updateCommentData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_COMMENT: {
      return {
        ...state,
        updateCommentData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_UPDATE_COMMENT: {
      return {
        ...state,
        updateCommentData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_COMMENT: {
      return {
        ...state,
        deleteCommentData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_COMMENT: {
      return {
        ...state,
        deleteCommentData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DELETE_COMMENT: {
      return {
        ...state,
        deleteCommentData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_COMMENT_REPLIES: {
      return {
        ...state,
        getCommentRepliesData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_COMMENT_REPLIES: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          let newComments = item?.comments;
          newComments = newComments?.map((comment) => {
            if (comment?.id == action?.comment_id) {
              let replyedComments = [
                ...comment?.replied_comments,
                ...action?.payload?.replied_comments,
              ];
              return {
                ...comment,
                replied_comments: replyedComments,
              };
            } else {
              return comment;
            }
          });
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          let newComments = item?.comments;
          newComments = newComments?.map((comment) => {
            if (comment?.id == action?.comment_id) {
              let replyedComments = [
                ...comment?.replied_comments,
                ...action?.payload?.replied_comments,
              ];
              return {
                ...comment,
                replied_comments: replyedComments,
              };
            } else {
              return comment;
            }
          });
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          let newComments = item?.comments;
          newComments = newComments?.map((comment) => {
            if (comment?.id == action?.comment_id) {
              let replyedComments = [
                ...comment?.replied_comments,
                ...action?.payload?.replied_comments,
              ];
              return {
                ...comment,
                replied_comments: replyedComments,
              };
            } else {
              return comment;
            }
          });
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == action?.post_id) {
          let newComments = item?.post?.comments;
          newComments = newComments?.map((comment) => {
            if (comment?.id == action?.comment_id) {
              let replyedComments = [
                ...comment?.replied_comments,
                ...action?.payload?.replied_comments,
              ];
              return {
                ...comment,
                replied_comments: replyedComments,
              };
            } else {
              return comment;
            }
          });
          return {
            ...item,
            post: {
              ...item?.post,
              comments: newComments,
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        getCommentRepliesData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }
    case ERROR_GET_COMMENT_REPLIES: {
      return {
        ...state,
        getCommentRepliesData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_ADD_BOOKMARK: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.payload?.id) {
          return {
            ...item,
            bookmark_id: true,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == action?.payload?.id) {
          return {
            ...item,
            bookmark_id: true,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == action?.payload?.id) {
          return {
            ...item,
            bookmark_id: true,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == action?.payload?.id) {
          return {
            ...item,
            bookmark_id: true,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        addBookmarkData: {
          isLoading: true,
          data: {},
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
      };
    }
    case SUCCESS_ADD_BOOKMARK: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            bookmark_id: action?.payload?.bookmark?.id,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            bookmark_id: action?.payload?.bookmark?.id,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            bookmark_id: action?.payload?.bookmark?.id,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            bookmark_id: action?.payload?.bookmark?.id,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        addBookmarkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
      };
    }
    case ERROR_ADD_BOOKMARK: {
      return {
        ...state,
        addBookmarkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_REMOVE_BOOKMARK: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.payload?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.payload?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.payload?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.bookmark_id == action?.payload?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        removeBookmarkData: {
          isLoading: true,
          data: {},
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
      };
    }
    case SUCCESS_REMOVE_BOOKMARK: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.bookmark_id == action?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });

      let oldBookmarks = { ...state?.getBookmarkData?.data };
      oldBookmarks.bookmarks = oldBookmarks?.bookmarks?.filter(
        (bookmark) => bookmark?.id != action?.bookmark_id
      );
      oldBookmarks.bookmark_count = oldBookmarks?.bookmark_count - 1;
      return {
        ...state,
        removeBookmarkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: oldBookmarks,
        },
      };
    }
    case ERROR_REMOVE_BOOKMARK: {
      return {
        ...state,
        removeBookmarkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_BOOKMARKS: {
      let oldBookmarks = { ...state?.getBookmarkData?.data };
      if (oldBookmarks?.length > 0) {
        oldBookmarks.bookmarks = [
          ...oldBookmarks?.bookmarks,
          action?.payload?.bookmarks,
        ];
      }

      return {
        ...state,
        getBookmarkData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldBookmarks : {},
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
      };
    }
    case SUCCESS_GET_BOOKMARKS: {
      let oldBookmarks = { ...state?.getBookmarkData?.data };
      if (oldBookmarks?.bookmarks?.length > 0) {
        oldBookmarks.bookmarks = [
          ...oldBookmarks?.bookmarks,
          ...action?.payload?.bookmarks,
        ];
      } else {
        oldBookmarks = action?.payload;
      }
      return {
        ...state,
        getBookmarkData: {
          isLoading: false,
          data: oldBookmarks,
          error: "",
          isLazyLoading: false,
        },
      };
    }
    case ERROR_GET_BOOKMARKS: {
      return {
        ...state,
        getBookmarkData: {
          isLoading: false,
          data: {},
          error: action.payload,
          isLazyLoading: false,
        },
      };
    }

    case START_GET_BOOKMARK_MEDIA: {
      let oldBookmarks = { ...state?.getBookmarkData?.data };
      if (oldBookmarks?.length > 0) {
        oldBookmarks.bookmarks = [
          ...oldBookmarks?.bookmarks,
          action?.payload?.bookmarks,
        ];
      }

      return {
        ...state,
        getBookmarkData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldBookmarks : {},
          error: "",
          isLazyLoading: action?.payload?.offset > 0 ? true : false,
        },
      };
    }
    case SUCCESS_GET_BOOKMARK_MEDIA: {
      let oldBookmarks = { ...state?.getBookmarkData?.data };
      if (oldBookmarks?.bookmarks?.length > 0) {
        oldBookmarks.bookmarks = [
          ...oldBookmarks?.bookmarks,
          ...action?.payload?.bookmarks,
        ];
      } else {
        oldBookmarks = action?.payload;
      }
      return {
        ...state,
        getBookmarkData: {
          isLoading: false,
          data: oldBookmarks,
          error: "",
          isLazyLoading: false,
        },
      };
    }
    case ERROR_GET_BOOKMARK_MEDIA: {
      return {
        ...state,
        getBookmarkData: {
          isLoading: false,
          data: {},
          error: action.payload,
          isLazyLoading: false,
        },
      };
    }

    case START_GET_PROFILE_MEDIA: {
      let oldMedia = { ...state?.getProfileMediaData?.data };
      return {
        ...state,
        getProfileMediaData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldMedia : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_PROFILE_MEDIA: {
      let oldMedia = { ...state?.getProfileMediaData?.data };
      if (oldMedia?.posts?.length > 0) {
        oldMedia.posts = [...oldMedia?.posts, ...action?.payload?.posts];
      } else {
        oldMedia = action?.payload;
      }
      return {
        ...state,
        getProfileMediaData: {
          isLoading: false,
          data: oldMedia,
          error: "",
        },
      };
    }
    case ERROR_GET_PROFILE_MEDIA: {
      return {
        ...state,
        getProfileMediaData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UNLOCK_POST: {
      return {
        ...state,
        unlockPostData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UNLOCK_POST: {
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            ...action?.payload?.post,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            ...action?.payload?.post,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            ...action?.payload?.post,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            ...action?.payload?.post,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == action?.post_id) {
          return {
            ...item,
            post: {
              ...item?.post,
              ...action?.payload?.post,
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        unlockPostData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }
    case ERROR_UNLOCK_POST: {
      return {
        ...state,
        unlockPostData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_MENTION_USERS: {
      let oldData = { ...(state?.mentionUsersData?.data || {}) };
      return {
        ...state,
        mentionUsersData: {
          isLoading: true,
          data: oldData,
          error: "",
        },
      };
    }
    case SUCCESS_GET_MENTION_USERS: {
      let oldData = { ...state?.mentionUsersData?.data };

      if (oldData?.rows?.length > 0) {
        oldData["rows"] = [...oldData?.rows, ...action?.payload?.data?.rows];
      } else {
        oldData = action.payload.data;
      }

      return {
        ...state,
        mentionUsersData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_MENTION_USERS: {
      return {
        ...state,
        mentionUsersData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SUCCESS_COLLAB_POSTS_ACTION: {
      let singlePostData = { ...state?.singlePostData?.data };
      if (singlePostData?.posts?.length > 0) {
        singlePostData["posts"] = singlePostData["posts"].map((item) => {
          if (item?.id == action.post_id) {
            const updatedCollabs = item?.collabs?.map((collab) => {
              if (collab.user.id == root?.user?.userDetail?.data?.data?.id) {
                return {
                  ...collab,
                  status: action.actionStatus,
                };
              }
              return collab;
            });

            return {
              ...item,
              collabs: updatedCollabs,
              collab_status: action?.payload?.is_post_created
                ? "accepted"
                : action.actionStatus == "rejected"
                ? "rejected"
                : "pending",
            };
          }
          return item;
        });
      }

      return {
        ...state,
        singlePostData: {
          ...state?.singlePostData,
          data: singlePostData,
        },
      };
    }

    case START_HIDE_USERS_POSTS_FROM_FEED_ACTION: {
      return {
        ...state,
        hideUsersPostsFromFeedData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION: {
      let oldGetAllPostData = { ...state?.getAllPostData?.data };
      let getAllPostData = { ...state?.getAllPostData?.data };
      if (getAllPostData?.posts?.length > 0) {
        getAllPostData["posts"] = getAllPostData["posts"].filter((item) => {
          return item?.user_id !== action.hide_user_id;
        });

        getAllPostData.post_count =
          getAllPostData?.post_count -
          (oldGetAllPostData?.posts?.length - getAllPostData?.posts?.length);
      }

      return {
        ...state,
        hideUsersPostsFromFeedData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: getAllPostData,
        },
      };
    }

    case ERROR_HIDE_USERS_POSTS_FROM_FEED_ACTION: {
      return {
        ...state,
        hideUsersPostsFromFeedData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_I_DONT_LIKE_POST_ACTION: {
      return {
        ...state,
        iDontLikePostData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_I_DONT_LIKE_POST_ACTION: {
      let getAllPostData = { ...state?.getAllPostData?.data };
      if (getAllPostData?.posts?.length > 0) {
        getAllPostData["posts"] = getAllPostData["posts"].filter((item) => {
          return item?.id !== action.id;
        });
      }
      return {
        ...state,
        iDontLikePostData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getAllPostData: {
          ...state?.getAllPostData,
          data: getAllPostData,
        },
      };
    }

    case ERROR_I_DONT_LIKE_POST_ACTION: {
      return {
        ...state,
        iDontLikePostData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    // Posts Socket Cases

    case SOCKET_ADD_POST_LIKE: {
      const { post_id, liked_by } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == post_id) {
          return {
            ...item,
            post: {
              ...item?.post,
              like_count: item?.post?.like_count + 1,
              liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case SOCKET_REMOVE_POST_LIKE: {
      const { post_id, unliked_by } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == post_id) {
          return {
            ...item,
            post: {
              ...item?.post,
              like_count: item?.post?.like_count - 1,
              liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case SOCKET_ADD_POST_COMMENT: {
      const { post_comment_count, comment } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;

      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = [...(item?.comments || [])];
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift({
                  ...comment,
                  editable: comment?.User?.id == myUserId ? true : false,
                });
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift({
              ...comment,
              editable: comment?.User?.id == myUserId ? true : false,
            });
          }
          return {
            ...item,
            comment_count: post_comment_count,
            comments: newComments,
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift({
                  ...comment,
                  editable: comment?.User?.id == myUserId ? true : false,
                });
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift({
              ...comment,
              editable: comment?.User?.id == myUserId ? true : false,
            });
          }
          return {
            ...item,
            comment_count: post_comment_count,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift({
                  ...comment,
                  editable: comment?.User?.id == myUserId ? true : false,
                });
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift({
              ...comment,
              editable: comment?.User?.id == myUserId ? true : false,
            });
          }
          return {
            ...item,
            comment_count: post_comment_count,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift(comment);
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift(comment);
          }
          return {
            ...item,
            comment_count: post_comment_count,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == comment?.post_id) {
          let newComments = item?.post?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift(comment);
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift(comment);
          }
          return {
            ...item,
            post: {
              ...item?.post,
              comment_count: post_comment_count,
              comments: newComments,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case SOCKET_DELETE_POST_COMMENT: {
      const { comment_id, parent_comment_id, post_id } = action.payload;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          let newComments = item?.comments;
          let commentCount = item?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.comment_count - 1;
          }
          return {
            ...item,
            comment_count: commentCount,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          let newComments = item?.comments;
          let commentCount = item?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.comment_count - 1;
          }
          return {
            ...item,
            comment_count: commentCount,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == post_id) {
          let newComments = item?.comments;
          let commentCount = item?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.comment_count - 1;
          }
          return {
            ...item,
            comment_count: commentCount,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          let newComments = item?.comments;
          let commentCount = item?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.comment_count - 1;
          }
          return {
            ...item,
            comment_count: commentCount,
            comments: newComments,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == post_id) {
          let newComments = item?.post?.comments;
          let commentCount = item?.post?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.post?.comment_count - 1;
          }
          return {
            ...item,
            post: {
              ...item?.post,
              comment_count: commentCount,
              comments: newComments,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case SOCKET_UPDATE_POST_COMMENT: {
      const { comment } = action.payload;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == comment?.post_id) {
          let newComments = item?.post?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            post: {
              ...item?.post,
              comments: newComments,
            },
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case SOCKET_POST_VIDEO_UPLOAD: {
      const { post_id, uploaded_video } = action.payload;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            post_videos: item?.post_videos?.map((video) => {
              if (video?.id == uploaded_video?.id) {
                return {
                  ...video,
                  thumbnail: uploaded_video?.thumbnail_url,
                  video_url: uploaded_video?.video_url,
                  is_uploaded: true,
                };
              } else {
                return video;
              }
            }),
          };
        } else {
          return item;
        }
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            post_videos: item?.post_videos?.map((video) => {
              if (video?.id == uploaded_video?.id) {
                return {
                  ...video,
                  thumbnail: uploaded_video?.thumbnail_url,
                  video_url: uploaded_video?.video_url,
                  is_uploaded: true,
                };
              } else {
                return video;
              }
            }),
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            post_videos: item?.post_videos?.map((video) => {
              if (video?.id == uploaded_video?.id) {
                return {
                  ...video,
                  thumbnail: uploaded_video?.thumbnail_url,
                  video_url: uploaded_video?.video_url,
                  is_uploaded: true,
                };
              } else {
                return video;
              }
            }),
          };
        } else {
          return item;
        }
      });
      let singlePost = { ...state?.singlePostData?.data };
      singlePost.posts = singlePost?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            post_videos: item?.post_videos?.map((video) => {
              if (video?.id == uploaded_video?.id) {
                return {
                  ...video,
                  thumbnail: uploaded_video?.thumbnail_url,
                  video_url: uploaded_video?.video_url,
                  is_uploaded: true,
                };
              } else {
                return video;
              }
            }),
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
      };
    }
    case SOCKET_IMAGE_MEDIA_UPLOADED: {
      const { category, data } = action.payload;
      const { post_id, id } = data;
      let oldPosts = { ...state?.getAllPostData?.data };
      let othersPosts = { ...state?.otherUserPostsData?.data };
      let singlePost = { ...state?.singlePostData?.data };
      let myOldPosts = { ...state?.myPostsData?.data };
      if (category == "post_image") {
        oldPosts.posts = oldPosts?.posts?.map((item) => {
          if (item?.id == post_id) {
            return {
              ...item,
              post_images: item?.post_images?.map((image) => {
                if (image?.id == id) {
                  return {
                    ...image,
                    ...data,
                  };
                } else {
                  return image;
                }
              }),
            };
          } else {
            return item;
          }
        });
        myOldPosts.posts = myOldPosts?.posts?.map((item) => {
          if (item?.id == post_id) {
            return {
              ...item,
              post_images: item?.post_images?.map((image) => {
                if (image?.id == id) {
                  return {
                    ...image,
                    ...data,
                  };
                } else {
                  return image;
                }
              }),
            };
          } else {
            return item;
          }
        });
        othersPosts.posts = othersPosts?.posts?.map((item) => {
          if (item?.id == post_id) {
            return {
              ...item,
              post_images: item?.post_images?.map((image) => {
                if (image?.id == id) {
                  return {
                    ...image,
                    ...data,
                  };
                } else {
                  return image;
                }
              }),
            };
          } else {
            return item;
          }
        });
        singlePost.posts = singlePost?.posts?.map((item) => {
          if (item?.id == post_id) {
            return {
              ...item,
              post_images: item?.post_images?.map((image) => {
                if (image?.id == id) {
                  return {
                    ...image,
                    ...data,
                  };
                } else {
                  return image;
                }
              }),
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
      };
    }

    case SOCKET_CREATE_POST: {
      const { post } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let oldPosts = { ...state?.getAllPostData?.data };
      let myOldPosts = { ...state?.getAllPostData?.data };
      let isPostAvailable = oldPosts?.posts?.some(
        (item) => item?.id == post?.id
      );
      let isMyPostAvailable = myOldPosts?.posts?.some(
        (item) => item?.id == post?.id
      );
      if (isPostAvailable) {
        oldPosts.posts = oldPosts?.posts?.map((item) => {
          if (item?.id == post?.id) {
            return {
              ...item,
              ...post,
            };
          } else {
            return item;
          }
        });
      } else {
        if (oldPosts?.posts?.length > 0) {
          oldPosts.posts = [
            {
              ...post,
              editable: post?.user_id == myUserId ? true : false,
            },
            ...oldPosts?.posts,
          ];
          oldPosts.post_count = oldPosts.post_count + 1;
        } else {
          oldPosts.posts = [
            {
              ...post,
              editable: post?.user_id == myUserId ? true : false,
            },
          ];
          oldPosts.post_count = oldPosts.post_count + 1;
        }
      }
      if (isMyPostAvailable) {
        myOldPosts.posts = myOldPosts?.posts?.map((item) => {
          if (item?.id == post?.id) {
            return {
              ...item,
              ...post,
            };
          } else {
            return item;
          }
        });
      } else {
        if (myOldPosts?.posts?.length > 0) {
          myOldPosts.posts = [
            {
              ...post,
              editable: post?.user_id == myUserId ? true : false,
            },
            ...myOldPosts?.posts,
          ];
          myOldPosts.post_count = myOldPosts.post_count + 1;
        } else {
          myOldPosts.posts = [
            {
              ...post,
              editable: post?.user_id == myUserId ? true : false,
            },
          ];
          myOldPosts.post_count = myOldPosts.post_count + 1;
        }
      }
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
      };
    }

    case SOCKET_DELETE_POST: {
      const { post_id } = action.payload;
      let newPosts = { ...state?.getAllPostData?.data };
      let myNewPosts = { ...state?.myPostsData?.data };
      let othersPosts = { ...state?.otherUserPostsData?.data };
      newPosts.posts = newPosts?.posts?.filter((post) => post?.id != post_id);
      newPosts.post_count = newPosts.post_count - 1;
      if (Object.keys(state?.otherUserPostsData?.data)?.length > 0) {
        othersPosts.posts = othersPosts?.posts?.filter(
          (post) => post?.id != post_id
        );
        othersPosts.post_count = othersPosts.post_count - 1;
      }
      if (Object.keys(state?.myPostsData?.data)?.length > 0) {
        myNewPosts.posts = myNewPosts?.posts?.filter(
          (post) => post?.id != post_id
        );
        myNewPosts.post_count = myNewPosts.post_count - 1;
      }
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      if (bookmarkPosts?.bookmarks?.length > 0) {
        bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.filter(
          (bookmark) => bookmark?.post?.id != post_id
        );
        myNewPosts.post_count = myNewPosts.post_count - 1;
      }
      let singlePost = { ...state?.singlePostData?.data };
      if (Object.keys(state?.singlePostData?.data)?.length > 0) {
        singlePost.posts = singlePost?.posts?.filter(
          (post) => post?.id != post_id
        );
      }
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: newPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myNewPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          othersPosts,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
        singlePostData: {
          ...state?.singlePostData,
          data: singlePost,
        },
      };
    }

    case SOCKET_UPDATE_POST: {
      const { post } = action.payload;
      let updatedPost = { ...state?.getAllPostData?.data };
      updatedPost.posts = updatedPost?.posts?.map((item) => {
        if (item?.id == post?.id) {
          return {
            ...item,
            ...post,
          };
        } else {
          return item;
        }
      });
      let myUpdatedPost = { ...state?.myPostsData?.data };
      myUpdatedPost.posts = myUpdatedPost?.posts?.map((item) => {
        if (item?.id == post?.id) {
          return {
            ...item,
            ...post,
          };
        } else {
          return item;
        }
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        if (item?.id == post?.id) {
          return {
            ...item,
            ...post,
          };
        } else {
          return item;
        }
      });
      let bookmarkPosts = { ...state?.getBookmarkData?.data };
      bookmarkPosts.bookmarks = bookmarkPosts?.bookmarks?.map((item) => {
        if (item?.post?.id == post?.id) {
          return {
            ...item,
            post: {
              ...item?.post,
              ...post,
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: updatedPost,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myUpdatedPost,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
        getBookmarkData: {
          ...state?.getBookmarkData,
          data: bookmarkPosts,
        },
      };
    }

    case SOCKET_LOGIN_USER: {
      const { user_id, show_activity_status = true } = action.payload;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts.posts?.map((item) => {
        if (item?.user?.id == user_id) {
          return {
            ...item,
            user: {
              ...item?.user,
              is_active: 1,
              show_activity_status: show_activity_status,
            },
          };
        } else {
          return item;
        }
      });
      oldPosts.posts = oldPosts.posts?.map((item) => {
        return {
          ...item,
          suggested_users: item?.suggested_users?.map((user) => {
            if (user?.id == user_id) {
              return {
                ...user,
                is_active: 1,
                show_activity_status: show_activity_status,
              };
            } else {
              return user;
            }
          }),
        };
      });
      oldPosts.posts = oldPosts.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            if (comment?.User?.id == user_id) {
              return {
                ...comment,
                User: {
                  ...comment?.User,
                  is_active: 1,
                  show_activity_status: show_activity_status,
                },
              };
            } else {
              return comment;
            }
          }),
        };
      });
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            return {
              ...comment,
              replied_comments: comment?.replied_comments?.map((reply) => {
                if (reply?.User?.id == user_id) {
                  return {
                    ...reply,
                    User: {
                      ...reply?.User,
                      is_active: 1,
                      show_activity_status: show_activity_status,
                    },
                  };
                } else {
                  return reply;
                }
              }),
            };
          }),
        };
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts.posts?.map((item) => {
        if (item?.user?.id == user_id) {
          return {
            ...item,
            user: {
              ...item?.user,
              is_active: 1,
              show_activity_status: show_activity_status,
            },
          };
        } else {
          return item;
        }
      });
      myOldPosts.posts = myOldPosts.posts?.map((item) => {
        return {
          ...item,
          suggested_users: item?.suggested_users?.map((user) => {
            if (user?.id == user_id) {
              return {
                ...user,
                is_active: 1,
                show_activity_status: show_activity_status,
              };
            } else {
              return user;
            }
          }),
        };
      });
      myOldPosts.posts = myOldPosts.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            if (comment?.User?.id == user_id) {
              return {
                ...comment,
                User: {
                  ...comment?.User,
                  is_active: 1,
                  show_activity_status: show_activity_status,
                },
              };
            } else {
              return comment;
            }
          }),
        };
      });
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            return {
              ...comment,
              replied_comments: comment?.replied_comments?.map((reply) => {
                if (reply?.User?.id == user_id) {
                  return {
                    ...reply,
                    User: {
                      ...reply?.User,
                      is_active: 1,
                      show_activity_status: show_activity_status,
                    },
                  };
                } else {
                  return reply;
                }
              }),
            };
          }),
        };
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts.posts?.map((item) => {
        if (item?.user?.id == user_id) {
          return {
            ...item,
            user: {
              ...item?.user,
              is_active: 1,
              show_activity_status: show_activity_status,
            },
          };
        } else {
          return item;
        }
      });
      othersPosts.posts = othersPosts.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            if (comment?.User?.id == user_id) {
              return {
                ...comment,
                User: {
                  ...comment?.User,
                  is_active: 1,
                  show_activity_status: show_activity_status,
                },
              };
            } else {
              return comment;
            }
          }),
        };
      });
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            return {
              ...comment,
              replied_comments: comment?.replied_comments?.map((reply) => {
                if (reply?.User?.id == user_id) {
                  return {
                    ...reply,
                    User: {
                      ...reply?.User,
                      is_active: 1,
                      show_activity_status: show_activity_status,
                    },
                  };
                } else {
                  return reply;
                }
              }),
            };
          }),
        };
      });
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
      };
    }

    case SOCKET_LOGOUT_USER: {
      const { user_id } = action.payload;
      let oldPosts = { ...state?.getAllPostData?.data };
      oldPosts.posts = oldPosts.posts?.map((item) => {
        if (item?.user?.id == user_id) {
          return {
            ...item,
            user: {
              ...item?.user,
              is_active: 0,
            },
          };
        } else {
          return item;
        }
      });
      oldPosts.posts = oldPosts.posts?.map((item) => {
        return {
          ...item,
          suggested_users: item?.suggested_users?.map((user) => {
            if (user?.id == user_id) {
              return {
                ...user,
                is_active: 0,
              };
            } else {
              return user;
            }
          }),
        };
      });
      oldPosts.posts = oldPosts.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            if (comment?.User?.id == user_id) {
              return {
                ...comment,
                User: {
                  ...comment?.User,
                  is_active: 0,
                },
              };
            } else {
              return comment;
            }
          }),
        };
      });
      oldPosts.posts = oldPosts?.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            return {
              ...comment,
              replied_comments: comment?.replied_comments?.map((reply) => {
                if (reply?.User?.id == user_id) {
                  return {
                    ...reply,
                    User: {
                      ...reply?.User,
                      is_active: 0,
                    },
                  };
                } else {
                  return reply;
                }
              }),
            };
          }),
        };
      });
      let myOldPosts = { ...state?.myPostsData?.data };
      myOldPosts.posts = myOldPosts.posts?.map((item) => {
        if (item?.user?.id == user_id) {
          return {
            ...item,
            user: {
              ...item?.user,
              is_active: 0,
            },
          };
        } else {
          return item;
        }
      });
      myOldPosts.posts = myOldPosts.posts?.map((item) => {
        return {
          ...item,
          suggested_users: item?.suggested_users?.map((user) => {
            if (user?.id == user_id) {
              return {
                ...user,
                is_active: 0,
              };
            } else {
              return user;
            }
          }),
        };
      });
      myOldPosts.posts = myOldPosts.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            if (comment?.User?.id == user_id) {
              return {
                ...comment,
                User: {
                  ...comment?.User,
                  is_active: 0,
                },
              };
            } else {
              return comment;
            }
          }),
        };
      });
      myOldPosts.posts = myOldPosts?.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            return {
              ...comment,
              replied_comments: comment?.replied_comments?.map((reply) => {
                if (reply?.User?.id == user_id) {
                  return {
                    ...reply,
                    User: {
                      ...reply?.User,
                      is_active: 0,
                    },
                  };
                } else {
                  return reply;
                }
              }),
            };
          }),
        };
      });
      let othersPosts = { ...state?.otherUserPostsData?.data };
      othersPosts.posts = othersPosts.posts?.map((item) => {
        if (item?.user?.id == user_id) {
          return {
            ...item,
            user: {
              ...item?.user,
              is_active: 0,
            },
          };
        } else {
          return item;
        }
      });
      othersPosts.posts = othersPosts.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            if (comment?.User?.id == user_id) {
              return {
                ...comment,
                User: {
                  ...comment?.User,
                  is_active: 0,
                },
              };
            } else {
              return comment;
            }
          }),
        };
      });
      othersPosts.posts = othersPosts?.posts?.map((item) => {
        return {
          ...item,
          comments: item?.comments?.map((comment) => {
            return {
              ...comment,
              replied_comments: comment?.replied_comments?.map((reply) => {
                if (reply?.User?.id == user_id) {
                  return {
                    ...reply,
                    User: {
                      ...reply?.User,
                      is_active: 0,
                    },
                  };
                } else {
                  return reply;
                }
              }),
            };
          }),
        };
      });
      return {
        ...state,
        getAllPostData: {
          ...state?.getAllPostData,
          data: oldPosts,
        },
        myPostsData: {
          ...state?.myPostsData,
          data: myOldPosts,
        },
        otherUserPostsData: {
          ...state?.otherUserPostsData,
          data: othersPosts,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
