import {
  ERROR_CREATE_NEW_STREAM,
  ERROR_GET_STREAM_BY_ID,
  ERROR_STREAM_STOP,
  RESET_STREAM_STATE,
  SOCKET_END_STREAM,
  START_CREATE_NEW_STREAM,
  START_GET_STREAM_BY_ID,
  START_STREAM_STOP,
  SUCCESS_CREATE_NEW_STREAM,
  SUCCESS_GET_STREAM_BY_ID,
  SUCCESS_STREAM_STOP,
  START_SHARE_STREAM_AS_POST,
  SUCCESS_SHARE_STREAM_AS_POST,
  ERROR_SHARE_STREAM_AS_POST,
  START_PURCHASE_STREAM,
  SUCCESS_PURCHASE_STREAM,
  ERROR_PURCHASE_STREAM,
  START_LEAVE_LIVE_STREAM,
  SUCCESS_LEAVE_LIVE_STREAM,
  ERROR_LEAVE_LIVE_STREAM,
  SOCKET_USER_LEAVE_STREAM,
  SOCKET_USER_JOIN_STREAM,
  SOCKET_TIP_ADD_LIVE_STREAM,
  START_UPDATE_TIP_GOAL,
  SUCCESS_UPDATE_TIP_GOAL,
  ERROR_UPDATE_TIP_GOAL,
  SOCKET_UPDATE_TIP_GOAL,
} from "Actions/StreamAction";

const initialState = {
  createNewStreamData: {
    isLoading: false,
    data: {},
    error: "",
    started: false,
  },
  stopStreamData: {
    isLoading: false,
    data: {},
    error: "",
    stopped: false,
  },
  getStreamData: {
    isLoading: false,
    data: {},
    error: "",
  },
  shareStreamAsPostData: {
    isLoading: false,
    data: {},
    error: "",
  },
  purchaseStreamData: {
    isLoading: false,
    data: {},
    error: "",
  },
  leaveStreamData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateTipGoalData: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const streamReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_STREAM_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_CREATE_NEW_STREAM: {
      return {
        ...state,
        createNewStreamData: {
          isLoading: true,
          data: {},
          error: "",
          started: false,
        },
      };
    }
    case SUCCESS_CREATE_NEW_STREAM: {
      return {
        ...state,
        createNewStreamData: {
          isLoading: false,
          data: action.payload,
          error: "",
          started: true,
        },
      };
    }
    case ERROR_CREATE_NEW_STREAM: {
      return {
        ...state,
        createNewStreamData: {
          isLoading: false,
          data: {},
          error: action.payload,
          started: false,
        },
      };
    }
    case START_STREAM_STOP: {
      return {
        ...state,
        stopStreamData: {
          isLoading: true,
          data: {},
          error: "",
          stopped: false,
        },
      };
    }
    case SUCCESS_STREAM_STOP: {
      return {
        ...state,
        stopStreamData: {
          isLoading: false,
          data: action.payload,
          error: "",
          stopped: true,
        },
      };
    }
    case ERROR_STREAM_STOP: {
      return {
        ...state,
        stopStreamData: {
          isLoading: false,
          data: {},
          error: action.payload,
          stopped: false,
        },
      };
    }
    case START_GET_STREAM_BY_ID: {
      return {
        ...state,
        getStreamData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STREAM_BY_ID: {
      return {
        ...state,
        getStreamData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STREAM_BY_ID: {
      return {
        ...state,
        getStreamData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_SHARE_STREAM_AS_POST: {
      return {
        ...state,
        shareStreamAsPostData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SHARE_STREAM_AS_POST: {
      return {
        ...state,
        shareStreamAsPostData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SHARE_STREAM_AS_POST: {
      return {
        ...state,
        shareStreamAsPostData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_PURCHASE_STREAM: {
      return {
        ...state,
        purchaseStreamData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_PURCHASE_STREAM: {
      let streamData = { ...state?.getStreamData?.data };
      streamData["user_live_streaming"] = {
        ...streamData?.user_live_streaming,
        ...action?.payload?.userLiveStreaming,
      };
      return {
        ...state,
        purchaseStreamData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getStreamData: {
          ...state.getStreamData,
          data: streamData,
        },
      };
    }
    case ERROR_PURCHASE_STREAM: {
      return {
        ...state,
        purchaseStreamData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_LEAVE_LIVE_STREAM: {
      return {
        ...state,
        leaveStreamData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_LEAVE_LIVE_STREAM: {
      return {
        ...state,
        leaveStreamData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_LEAVE_LIVE_STREAM: {
      return {
        ...state,
        leaveStreamData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UPDATE_TIP_GOAL: {
      let oldData = { ...state?.createNewStreamData?.data };

      oldData["data"]["goal_tip"] = action?.payload?.goal_tip;
      oldData["data"]["goal_tip_clear_amount"] =
        action?.payload?.goal_tip_clear_amount;
      oldData["data"]["goal_tip_reward"] = action?.payload?.goal_tip_reward;
      oldData["data"]["goal_tip_show_viewer"] =
        action?.payload?.goal_tip_show_viewer;

      return {
        ...state,
        updateTipGoalData: {
          isLoading: true,
          data: {},
          error: "",
        },
        createNewStreamData: {
          ...state.createNewStreamData,
          data: oldData,
        },
      };
    }

    case SUCCESS_UPDATE_TIP_GOAL: {
      return {
        ...state,
        updateTipGoalData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_UPDATE_TIP_GOAL: {
      return {
        ...state,
        updateTipGoalData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case SOCKET_END_STREAM: {
      let streamData = { ...state?.getStreamData?.data };
      streamData["user_live_streaming"]["status"] = "stopped";
      return {
        ...state,
        getStreamData: {
          ...state.getStreamData,
          data: streamData,
        },
      };
    }

    case SOCKET_USER_LEAVE_STREAM: {
      const { total_viewer_count } = action.payload;
      let oldData = { ...state?.createNewStreamData?.data };
      oldData["data"]["total_viewer_count"] = total_viewer_count;
      return {
        ...state,
        createNewStreamData: {
          ...state.createNewStreamData,
          data: oldData,
        },
      };
    }

    case SOCKET_USER_JOIN_STREAM: {
      const { total_viewer_count } = action.payload;
      let oldData = { ...state?.createNewStreamData?.data };
      oldData["data"]["total_viewer_count"] = total_viewer_count;
      return {
        ...state,
        createNewStreamData: {
          ...state?.createNewStreamData,
          data: oldData,
        },
      };
    }

    case SOCKET_TIP_ADD_LIVE_STREAM: {
      const { total_tip_amount, goal_tip_clear_temp_amount } = action.payload;
      let oldData = { ...state?.createNewStreamData?.data };
      if (Object.keys(oldData)?.length) {
        oldData["data"]["total_tip_amount"] = total_tip_amount;
      }
      let streamData = { ...state?.getStreamData?.data };
      if (Object.keys(streamData)?.length) {
        streamData["user_live_streaming"]["total_tip_amount"] =
          total_tip_amount;
        streamData["user_live_streaming"]["tipping"] = true;
        streamData["user_live_streaming"]["goal_tip_clear_temp_amount"] =
          goal_tip_clear_temp_amount;
      }
      return {
        ...state,
        getStreamData: {
          isLoading: false,
          data: streamData,
          error: "",
        },
        createNewStreamData: {
          ...state.createNewStreamData,
          data: oldData,
        },
      };
    }

    case SOCKET_UPDATE_TIP_GOAL: {
      const {
        goal_tip,
        goal_tip_clear_amount,
        goal_tip_reward,
        goal_tip_show_viewer,
        goal_tip_clear_temp_amount,
      } = action.payload;
      let streamData = { ...state?.getStreamData?.data };
      streamData["user_live_streaming"]["goal_tip"] = goal_tip;
      streamData["user_live_streaming"]["goal_tip_clear_amount"] =
        goal_tip_clear_amount;
      streamData["user_live_streaming"]["goal_tip_reward"] = goal_tip_reward;
      streamData["user_live_streaming"]["goal_tip_show_viewer"] =
        goal_tip_show_viewer;
      streamData["user_live_streaming"]["goal_tip_clear_temp_amount"] =
        goal_tip_clear_temp_amount;

      return {
        ...state,
        getStreamData: {
          ...state.getStreamData,
          data: streamData,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
