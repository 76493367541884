import { createContext, useState } from "react";

export const defaults = {
  selectedPostTags: [],
  tagOtherCreatorDialogType: "",
};

const CommonContext = createContext(defaults);

const CommonContextProvider = (props) => {
  const [state, setState] = useState(defaults);
  return (
    <CommonContext.Provider value={[state, setState]}>
      {props.children}
    </CommonContext.Provider>
  );
};

export { CommonContext, CommonContextProvider };
