import {
  ERROR_GET_NOTIFICATIONS,
  ERROR_READ_ALL_NOTIFICATION,
  ERROR_READ_SINGLE_NOTIFICATION,
  START_GET_NOTIFICATIONS,
  START_READ_ALL_NOTIFICATION,
  START_READ_SINGLE_NOTIFICATION,
  SUCCESS_GET_NOTIFICATIONS,
  SUCCESS_READ_ALL_NOTIFICATION,
  SUCCESS_READ_SINGLE_NOTIFICATION,
} from "Actions/NotificationAction";
import {
  getNotificationAPI,
  readAllNotificationAPI,
  readSingleNotificationAPI,
} from "Api/NotificationApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* getNotificationWorker({ payload }) {
  try {
    const response = yield call(getNotificationAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_NOTIFICATIONS,
        payload: response,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_NOTIFICATIONS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* readSingleNotificationWorker({ payload }) {
  try {
    const response = yield call(readSingleNotificationAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_READ_SINGLE_NOTIFICATION,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_READ_SINGLE_NOTIFICATION,
        payload: response,
      });
    }
  } catch (error) {}
}

function* readAllNotificationWorker() {
  try {
    const response = yield call(readAllNotificationAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_READ_ALL_NOTIFICATION,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_READ_ALL_NOTIFICATION,
        payload: response,
      });
    }
  } catch (error) {}
}

export function* NotificationWatcher() {
  yield takeEvery(START_GET_NOTIFICATIONS, getNotificationWorker);
  yield takeEvery(START_READ_SINGLE_NOTIFICATION, readSingleNotificationWorker);
  yield takeEvery(START_READ_ALL_NOTIFICATION, readAllNotificationWorker);
}
