import {
  ERROR_GET_PUBLIC_POST_BY_ID,
  ERRRO_GET_PUBLIC_PROFILE_MEDIA,
  ERRRO_GET_PUBLIC_PROFILE_POSTS,
  RESET_GLOBAL_STATE,
  START_GET_PUBLIC_POST_BY_ID,
  START_GET_PUBLIC_PROFILE_MEDIA,
  START_GET_PUBLIC_PROFILE_POSTS,
  SUCCESS_GET_PUBLIC_POST_BY_ID,
  SUCCESS_GET_PUBLIC_PROFILE_MEDIA,
  SUCCESS_GET_PUBLIC_PROFILE_POSTS,
} from "Actions/GlobalProfileAction";

const initialState = {
  publicProfilePostsData: {
    isLoading: false,
    data: {},
    error: "",
  },
  publicProfileMediaData: {
    isLoading: false,
    data: {},
    error: "",
  },
  publicSinglePostData: {
    isLoading: false,
    data: {},
    error: "",
  },
  globalUserData: {},
  isEnteredGlobal: false,
};

export const globalProfileReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_GLOBAL_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_GET_PUBLIC_PROFILE_POSTS: {
      let oldPosts = { ...state?.publicProfilePostsData?.data };
      return {
        ...state,
        publicProfilePostsData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldPosts : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_PUBLIC_PROFILE_POSTS: {
      let oldPosts = { ...state?.publicProfilePostsData?.data };
      if (oldPosts?.posts?.length > 0) {
        oldPosts["posts"] = [...oldPosts?.posts, ...action?.payload?.posts];
      } else {
        oldPosts = action.payload;
      }
      return {
        ...state,
        publicProfilePostsData: {
          isLoading: false,
          data: oldPosts,
          error: "",
        },
      };
    }
    case ERRRO_GET_PUBLIC_PROFILE_POSTS: {
      return {
        ...state,
        publicProfilePostsData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_PUBLIC_PROFILE_MEDIA: {
      let oldMedia = { ...state?.publicProfileMediaData?.data };
      return {
        ...state,
        publicProfileMediaData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldMedia : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_PUBLIC_PROFILE_MEDIA: {
      let oldMedia = { ...state?.publicProfileMediaData?.data };
      if (oldMedia?.posts?.length > 0) {
        oldMedia["posts"] = [...oldMedia?.posts, ...action?.payload?.posts];
      } else {
        oldMedia = action?.payload;
      }
      return {
        ...state,
        publicProfileMediaData: {
          isLoading: false,
          data: oldMedia,
          error: "",
        },
      };
    }
    case ERRRO_GET_PUBLIC_PROFILE_MEDIA: {
      return {
        ...state,
        publicProfileMediaData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_PUBLIC_POST_BY_ID: {
      return {
        ...state,
        publicSinglePostData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_PUBLIC_POST_BY_ID: {
      return {
        ...state,
        publicSinglePostData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_PUBLIC_POST_BY_ID: {
      return {
        ...state,
        publicSinglePostData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
