export const RESET_POST_REDUCER = "RESET_POST_REDUCER";

export const SET_POST_CONFIG_DATA = "SET_POST_CONFIG_DATA";

export const START_CREATE_POST = "START_CREATE_POST";
export const POST_CREATED_LOCAL = "POST_CREATED_LOCAL";
export const SUCCESS_CREATE_POST = "SUCCESS_CREATE_POST";
export const ERROR_CREATE_POST = "ERROR_CREATE_POST";

export const START_CREATE_NEW_POST = "START_CREATE_NEW_POST";
export const SUCCESS_CREATE_NEW_POST = "SUCCESS_CREATE_NEW_POST";
export const ERROR_CREATE_NEW_POST = "ERROR_CREATE_NEW_POST";

export const START_GET_ALL_POST = "START_GET_ALL_POST";
export const SUCCESS_GET_ALL_POST = "SUCCESS_GET_ALL_POST";
export const ERROR_GET_ALL_POST = "ERROR_GET_ALL_POST";

export const START_DELETE_POST = "START_DELETE_POST";
export const SUCCESS_DELETE_POST = "SUCCESS_DELETE_POST";
export const ERROR_DELETE_POST = "ERROR_DELETE_POST";

export const START_GET_SINGLE_POST_BYID = "START_GET_SINGLE_POST_BYID";
export const SUCCESS_GET_SINGLE_POST_BYID = "SUCCESS_GET_SINGLE_POST_BYID";
export const ERROR_GET_SINGLE_POST_BYID = "ERROR_GET_SINGLE_POST_BYID";

export const START_LINK_PREVIEW = "START_LINK_PREVIEW";
export const SUCCESS_LINK_PREVIEW = "SUCCESS_LINK_PREVIEW";
export const ERROR_LINK_PREVIEW = "ERROR_LINK_PREVIEW";

export const START_UPDATE_POST = "START_UPDATE_POST";
export const SUCCESS_UPDATE_POST = "SUCCESS_UPDATE_POST";
export const ERROR_UPDATE_POST = "ERROR_UPDATE_POST";

export const START_GET_OTHER_USER_POSTS = "START_GET_OTHER_USER_POSTS";
export const SUCCESS_GET_OTHER_USER_POSTS = "SUCCESS_GET_OTHER_USER_POSTS";
export const ERROR_GET_OTHER_USER_POSTS = "ERROR_GET_OTHER_USER_POSTS";

export const START_USER_POST_LIKE = "START_USER_POST_LIKE";
export const SUCCESS_USER_POST_LIKE = "SUCCESS_USER_POST_LIKE";
export const ERROR_USER_POST_LIKE = "ERROR_USER_POST_LIKE";

export const START_USER_POST_DISLIKE = "START_USER_POST_DISLIKE";
export const SUCCESS_USER_POST_DISLIKE = "SUCCESS_USER_POST_DISLIKE";
export const ERROR_USER_POST_DISLIKE = "ERROR_USER_POST_DISLIKE";

export const START_GET_POST_COMMENTS = "START_GET_POST_COMMENTS";
export const SUCCESS_GET_POST_COMMENTS = "SUCCESS_GET_POST_COMMENTS";
export const ERROR_GET_POST_COMMENTS = "ERROR_GET_POST_COMMENTS";

export const START_CREATE_POST_COMMENT = "START_CREATE_POST_COMMENT";
export const SUCCESS_CREATE_POST_COMMENT = "SUCCESS_CREATE_POST_COMMENT";
export const ERROR_CREATE_POST_COMMENT = "ERROR_CREATE_POST_COMMENT";

export const START_UPDATE_COMMENT = "START_UPDATE_COMMENT";
export const SUCCESS_UPDATE_COMMENT = "SUCCESS_UPDATE_COMMENT";
export const ERROR_UPDATE_COMMENT = "ERROR_UPDATE_COMMENT";

export const START_DELETE_COMMENT = "START_DELETE_COMMENT";
export const SUCCESS_DELETE_COMMENT = "SUCCESS_DELETE_COMMENT";
export const ERROR_DELETE_COMMENT = "ERROR_DELETE_COMMENT";

export const START_GET_COMMENT_REPLIES = "START_GET_COMMENT_REPLIES";
export const SUCCESS_GET_COMMENT_REPLIES = "SUCCESS_GET_COMMENT_REPLIES";
export const ERROR_GET_COMMENT_REPLIES = "ERROR_GET_COMMENT_REPLIES";

export const START_ADD_BOOKMARK = "START_ADD_BOOKMARK";
export const SUCCESS_ADD_BOOKMARK = "SUCCESS_ADD_BOOKMARK";
export const ERROR_ADD_BOOKMARK = "ERROR_ADD_BOOKMARK";

export const START_REMOVE_BOOKMARK = "START_REMOVE_BOOKMARK";
export const SUCCESS_REMOVE_BOOKMARK = "SUCCESS_REMOVE_BOOKMARK";
export const ERROR_REMOVE_BOOKMARK = "ERROR_REMOVE_BOOKMARK";

export const START_GET_BOOKMARKS = "START_GET_BOOKMARKS";
export const SUCCESS_GET_BOOKMARKS = "SUCCESS_GET_BOOKMARKS";
export const ERROR_GET_BOOKMARKS = "ERROR_GET_BOOKMARKS";

export const START_GET_BOOKMARK_MEDIA = "START_GET_BOOKMARK_MEDIA";
export const SUCCESS_GET_BOOKMARK_MEDIA = "SUCCESS_GET_BOOKMARK_MEDIA";
export const ERROR_GET_BOOKMARK_MEDIA = "ERROR_GET_BOOKMARK_MEDIA";

export const START_GET_PROFILE_MEDIA = "START_GET_PROFILE_MEDIA";
export const SUCCESS_GET_PROFILE_MEDIA = "SUCCESS_GET_PROFILE_MEDIA";
export const ERROR_GET_PROFILE_MEDIA = "ERROR_GET_PROFILE_MEDIA";

export const POST_MEDIA_PROGRESS = "POST_MEDIA_PROGRESS";

export const START_UNLOCK_POST = "START_UNLOCK_POST";
export const SUCCESS_UNLOCK_POST = "SUCCESS_UNLOCK_POST";
export const ERROR_UNLOCK_POST = "ERROR_UNLOCK_POST";

export const START_UPDATE_POST_CREATORS = "START_UPDATE_POST_CREATORS";
export const SUCCESS_UPDATE_POST_CREATORS = "SUCCESS_UPDATE_POST_CREATORS";
export const ERROR_UPDATE_POST_CREATORS = "ERROR_UPDATE_POST_CREATORS";

export const START_GET_MENTION_USERS = "START_GET_MENTION_USERS";
export const SUCCESS_GET_MENTION_USERS = "SUCCESS_GET_MENTION_USERS";
export const ERROR_GET_MENTION_USERS = "ERROR_GET_MENTION_USERS";

export const START_HIDE_USERS_POSTS_FROM_FEED_ACTION =
  "START_HIDE_USERS_POSTS_FROM_FEED_ACTION";
export const SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION =
  "SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION";
export const ERROR_HIDE_USERS_POSTS_FROM_FEED_ACTION =
  "ERROR_HIDE_USERS_POSTS_FROM_FEED_ACTION";

export const START_I_DONT_LIKE_POST_ACTION = "START_I_DONT_LIKE_POST_ACTION";
export const SUCCESS_I_DONT_LIKE_POST_ACTION =
  "SUCCESS_I_DONT_LIKE_POST_ACTION";
export const ERROR_I_DONT_LIKE_POST_ACTION = "ERROR_I_DONT_LIKE_POST_ACTION";

// Posts Socket

export const SOCKET_ADD_POST_LIKE = "SOCKET_ADD_POST_LIKE";
export const SOCKET_REMOVE_POST_LIKE = "SOCKET_REMOVE_POST_LIKE";
export const SOCKET_ADD_POST_COMMENT = "SOCKET_ADD_POST_COMMENT";
export const SOCKET_DELETE_POST_COMMENT = "SOCKET_DELETE_POST_COMMENT";
export const SOCKET_UPDATE_POST_COMMENT = "SOCKET_UPDATE_POST_COMMENT";
export const SOCKET_POST_VIDEO_UPLOAD = "SOCKET_POST_VIDEO_UPLOAD";
export const SOCKET_CREATE_POST = "SOCKET_CREATE_POST";
export const SOCKET_UPDATE_POST = "SOCKET_UPDATE_POST";
export const SOCKET_DELETE_POST = "SOCKET_DELETE_POST";
export const SOCKET_UPDATE_POST_CREATORS = "SOCKET_UPDATE_POST_CREATORS";
export const SOCKET_IMAGE_MEDIA_UPLOADED = "SOCKET_IMAGE_MEDIA_UPLOADED";
