import {
  START_SET_SUBSCRIPTION_PRICE,
  SUCCESS_SET_SUBSCRIPTION_PRICE,
  ERROR_SET_SUBSCRIPTION_PRICE,
  RESET_SUBCRIPTION_STATE,
  START_GET_FREE_TRIAL_LINKS,
  SUCCESS_GET_FREE_TRIAL_LINKS,
  ERROR_GET_FREE_TRIAL_LINKS,
  START_CREATE_FREE_TRIAL_LINKS,
  SUCCESS_CREATE_FREE_TRIAL_LINKS,
  ERROR_CREATE_FREE_TRIAL_LINKS,
  START_DELETE_FREE_TRIAL_LINKS,
  SUCCESS_DELETE_FREE_TRIAL_LINKS,
  ERROR_DELETE_FREE_TRIAL_LINKS,
  START_CHECK_FREE_TRIAL_AVAILABILITY,
  SUCCESS_CHECK_FREE_TRIAL_AVAILABILITY,
  ERROR_CHECK_FREE_TRIAL_AVAILABILITY,
  START_JOIN_FREE_TRIAL,
  SUCCESS_JOIN_FREE_TRIAL,
  ERROR_JOIN_FREE_TRIAL,
  START_ADD_SUBSCRIPTION_BUNDLE,
  SUCCCESS_ADD_SUBSCRIPTION_BUNDLE,
  ERROR_ADD_SUBSCRIPTION_BUNDLE,
  START_DELETE_SUBSCRIPTION_BUNDLE,
  SUCCESS_DELETE_SUBSCRIPTION_BUNDLE,
  ERROR_DELETE_SUBSCRIPTION_BUNDLE,
  START_ADD_PROMOTION_CAMPAIGN,
  SUCCESS_ADD_PROMOTION_CAMPAIGN,
  ERROR_ADD_PROMOTION_CAMPAIGN,
  START_DELETE_PROMOTION_CAMPAIGN,
  SUCCESS_DELETE_PROMOTION_CAMPAIGN,
  ERROR_DELETE_PROMOTION_CAMPAIGN,
  START_STOP_PROMOTION_CAMPAIGN,
  SUCCESS_STOP_PROMOTION_CAMPAIGN,
  ERROR_STOP_PROMOTION_CAMPAIGN,
  START_SUBSCRIPTION_ACTION,
  SUCCESS_SUBSCRIPTION_ACTION,
  ERROR_SUBSCRIPTION_ACTION,
} from "Actions/SubscriptionAction";
import { parseAmount } from "utils/generalUtils";

const initialState = {
  setSubscriptionPriceData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getFreeTrialLinksData: {
    isLoading: false,
    data: {},
    error: "",
  },
  createFreeTrialLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteFreeTrialLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  checkFreeTrialAvailabilityData: {
    isLoading: false,
    data: {},
    error: "",
  },
  joinFreeTrialData: {
    isLoading: false,
    data: {},
    error: "",
  },
  addSubscriptionBundleData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteSubscriptionBundleData: {
    isLoading: false,
    data: {},
    error: "",
  },
  addPromotionCampaignData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deletePromotionCampaignData: {
    isLoading: false,
    data: {},
    error: "",
  },
  stopPromotionCampaignData: {
    isLoading: false,
    data: {},
    error: "",
  },
  subscriptionActionData: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const subscriptionReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_SUBCRIPTION_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_SET_SUBSCRIPTION_PRICE: {
      return {
        ...state,
        setSubscriptionPriceData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SET_SUBSCRIPTION_PRICE: {
      return {
        ...state,
        setSubscriptionPriceData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SET_SUBSCRIPTION_PRICE: {
      return {
        ...state,
        setSubscriptionPriceData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_FREE_TRIAL_LINKS: {
      return {
        ...state,
        getFreeTrialLinksData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_FREE_TRIAL_LINKS: {
      return {
        ...state,
        getFreeTrialLinksData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_FREE_TRIAL_LINKS: {
      return {
        ...state,
        getFreeTrialLinksData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CREATE_FREE_TRIAL_LINKS: {
      return {
        ...state,
        createFreeTrialLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_FREE_TRIAL_LINKS: {
      let linksList = { ...state?.getFreeTrialLinksData?.data };
      if (linksList?.datas?.length > 0) {
        linksList["datas"] = [
          action?.payload?.subscription,
          ...linksList?.datas,
        ];
      } else {
        linksList["datas"] = [action?.payload?.subscription];
      }
      return {
        ...state,
        createFreeTrialLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getFreeTrialLinksData: {
          ...state.getFreeTrialLinksData,
          data: linksList,
        },
      };
    }
    case ERROR_CREATE_FREE_TRIAL_LINKS: {
      return {
        ...state,
        createFreeTrialLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_FREE_TRIAL_LINKS: {
      return {
        ...state,
        deleteFreeTrialLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_FREE_TRIAL_LINKS: {
      let linksList = { ...state?.getFreeTrialLinksData?.data };
      linksList["datas"] = linksList?.datas?.filter(
        (item) => item?.id != action?.link_id
      );
      return {
        ...state,
        deleteFreeTrialLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getFreeTrialLinksData: {
          ...state.getFreeTrialLinksData,
          data: linksList,
        },
      };
    }
    case ERROR_DELETE_FREE_TRIAL_LINKS: {
      return {
        ...state,
        deleteFreeTrialLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CHECK_FREE_TRIAL_AVAILABILITY: {
      return {
        ...state,
        checkFreeTrialAvailabilityData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CHECK_FREE_TRIAL_AVAILABILITY: {
      return {
        ...state,
        checkFreeTrialAvailabilityData: {
          isLoading: false,
          data: action?.payload,
          error: "",
        },
      };
    }
    case ERROR_CHECK_FREE_TRIAL_AVAILABILITY: {
      return {
        ...state,
        checkFreeTrialAvailabilityData: {
          isLoading: false,
          data: {},
          error: action?.payload,
        },
      };
    }
    case START_JOIN_FREE_TRIAL: {
      return {
        ...state,
        joinFreeTrialData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_JOIN_FREE_TRIAL: {
      return {
        ...state,
        joinFreeTrialData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_JOIN_FREE_TRIAL: {
      return {
        ...state,
        joinFreeTrialData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_ADD_SUBSCRIPTION_BUNDLE: {
      return {
        ...state,
        addSubscriptionBundleData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCCESS_ADD_SUBSCRIPTION_BUNDLE: {
      return {
        ...state,
        addSubscriptionBundleData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_ADD_SUBSCRIPTION_BUNDLE: {
      return {
        ...state,
        addSubscriptionBundleData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_SUBSCRIPTION_BUNDLE: {
      return {
        ...state,
        deleteSubscriptionBundleData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_SUBSCRIPTION_BUNDLE: {
      return {
        ...state,
        deleteSubscriptionBundleData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DELETE_SUBSCRIPTION_BUNDLE: {
      return {
        ...state,
        deleteSubscriptionBundleData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_ADD_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        addPromotionCampaignData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        addPromotionCampaignData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_ADD_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        addPromotionCampaignData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        deletePromotionCampaignData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        deletePromotionCampaignData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DELETE_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        deletePromotionCampaignData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_STOP_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        stopPromotionCampaignData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_STOP_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        stopPromotionCampaignData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_STOP_PROMOTION_CAMPAIGN: {
      return {
        ...state,
        stopPromotionCampaignData: {
          isLoading: false,
          data: {},
          errro: action.payload,
        },
      };
    }
    case START_SUBSCRIPTION_ACTION: {
      return {
        ...state,
        subscriptionActionData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SUBSCRIPTION_ACTION: {
      return {
        ...state,
        subscriptionActionData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SUBSCRIPTION_ACTION: {
      return {
        ...state,
        subscriptionActionData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    default: {
      return {
        ...(state || initialState),
      };
    }
  }
};
