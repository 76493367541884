export const RESET_QUEUE_ACTION = "RESET_QUEUE_ACTION";

export const SET_QUEUE_CONFIG_DATA = "SET_QUEUE_CONFIG_DATA";

export const START_GET_QUEUE_COUNTERS = "START_GET_QUEUE_COUNTERS";
export const SUCCESS_GET_QUEUE_COUNTERS = "SUCCESS_GET_QUEUE_COUNTERS";
export const ERROR_GET_QUEUE_COUNTERS = "ERROR_GET_QUEUE_COUNTERS";

export const START_GET_QUEUE_LIST = "START_GET_QUEUE_LIST";
export const SUCCESS_GET_QUEUE_LIST = "SUCCESS_GET_QUEUE_LIST";
export const ERROR_GET_QUEUE_LIST = "ERROR_GET_QUEUE_LIST";

export const START_TAKE_SCHEDULE_ACTION = "START_TAKE_SCHEDULE_ACTION";
export const SUCCESS_TAKE_SCHEDULE_ACTION = "SUCCESS_TAKE_SCHEDULE_ACTION";
export const ERROR_TAKE_SCHEDULE_ACTION = "ERROR_TAKE_SCHEDULE_ACTION";

export const START_GET_POST_SAVED_FOR_LATER_CONFIG =
  "START_GET_POST_SAVED_FOR_LATER_CONFIG";
export const SUCCESS_GET_POST_SAVED_FOR_LATER_CONFIG =
  "SUCCESS_GET_POST_SAVED_FOR_LATER_CONFIG";
export const ERROR_GET_POST_SAVED_FOR_LATER_CONFIG =
  "ERROR_GET_POST_SAVED_FOR_LATER_CONFIG";

export const START_UPDATE_POST_SAVED_FOR_LATER_CONFIG =
  "START_UPDATE_POST_SAVED_FOR_LATER_CONFIG";
export const SUCCESS_UPDATE_POST_SAVED_FOR_LATER_CONFIG =
  "SUCCESS_UPDATE_POST_SAVED_FOR_LATER_CONFIG";
export const ERROR_UPDATE_POST_SAVED_FOR_LATER_CONFIG =
  "ERROR_UPDATE_POST_SAVED_FOR_LATER_CONFIG";

export const START_GET_SAVED_FOR_LATER_POSTS =
  "START_GET_SAVED_FOR_LATER_POSTS";
export const SUCCESS_GET_SAVED_FOR_LATER_POSTS =
  "SUCCESS_GET_SAVED_FOR_LATER_POSTS";
export const ERROR_GET_SAVED_FOR_LATER_POSTS =
  "ERROR_GET_SAVED_FOR_LATER_POSTS";

// Socket
export const SOCKET_REFRESH_SCHEDULE_ACTION = "SOCKET_REFRESH_SCHEDULE_ACTION";
