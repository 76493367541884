import { call, put, take, takeEvery } from "redux-saga/effects";
import {
  START_CREATE_POST,
  SUCCESS_CREATE_POST,
  ERROR_CREATE_POST,
  START_GET_ALL_POST,
  SUCCESS_GET_ALL_POST,
  ERROR_GET_ALL_POST,
  START_DELETE_POST,
  SUCCESS_DELETE_POST,
  ERROR_DELETE_POST,
  START_GET_SINGLE_POST_BYID,
  SUCCESS_GET_SINGLE_POST_BYID,
  ERROR_GET_SINGLE_POST_BYID,
  START_LINK_PREVIEW,
  SUCCESS_LINK_PREVIEW,
  ERROR_LINK_PREVIEW,
  START_UPDATE_POST,
  SUCCESS_UPDATE_POST,
  ERROR_UPDATE_POST,
  START_GET_OTHER_USER_POSTS,
  SUCCESS_GET_OTHER_USER_POSTS,
  ERROR_GET_OTHER_USER_POSTS,
  START_USER_POST_LIKE,
  SUCCESS_USER_POST_LIKE,
  ERROR_USER_POST_LIKE,
  START_USER_POST_DISLIKE,
  SUCCESS_USER_POST_DISLIKE,
  ERROR_USER_POST_DISLIKE,
  START_GET_POST_COMMENTS,
  SUCCESS_GET_POST_COMMENTS,
  ERROR_GET_POST_COMMENTS,
  START_CREATE_POST_COMMENT,
  SUCCESS_CREATE_POST_COMMENT,
  ERROR_CREATE_POST_COMMENT,
  START_UPDATE_COMMENT,
  SUCCESS_UPDATE_COMMENT,
  ERROR_UPDATE_COMMENT,
  START_DELETE_COMMENT,
  SUCCESS_DELETE_COMMENT,
  ERROR_DELETE_COMMENT,
  START_GET_COMMENT_REPLIES,
  SUCCESS_GET_COMMENT_REPLIES,
  ERROR_GET_COMMENT_REPLIES,
  POST_CREATED_LOCAL,
  START_ADD_BOOKMARK,
  START_REMOVE_BOOKMARK,
  START_GET_BOOKMARKS,
  SUCCESS_ADD_BOOKMARK,
  ERROR_ADD_BOOKMARK,
  SUCCESS_REMOVE_BOOKMARK,
  ERROR_REMOVE_BOOKMARK,
  SUCCESS_GET_BOOKMARKS,
  ERROR_GET_BOOKMARKS,
  START_GET_PROFILE_MEDIA,
  ERROR_GET_PROFILE_MEDIA,
  SUCCESS_GET_PROFILE_MEDIA,
  POST_MEDIA_PROGRESS,
  START_UNLOCK_POST,
  SUCCESS_UNLOCK_POST,
  ERROR_UNLOCK_POST,
  START_CREATE_NEW_POST,
  SUCCESS_CREATE_NEW_POST,
  ERROR_CREATE_NEW_POST,
  START_UPDATE_POST_CREATORS,
  SUCCESS_UPDATE_POST_CREATORS,
  ERROR_UPDATE_POST_CREATORS,
  START_GET_MENTION_USERS,
  SUCCESS_GET_MENTION_USERS,
  ERROR_GET_MENTION_USERS,
  START_GET_BOOKMARK_MEDIA,
  SUCCESS_GET_BOOKMARK_MEDIA,
  ERROR_GET_BOOKMARK_MEDIA,
  START_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  ERROR_HIDE_USERS_POSTS_FROM_FEED_ACTION,
  START_I_DONT_LIKE_POST_ACTION,
  SUCCESS_I_DONT_LIKE_POST_ACTION,
  ERROR_I_DONT_LIKE_POST_ACTION,
} from "Actions/PostAction";

import {
  createPostAPI,
  createPostServerCallAPI,
  getAllPostAPI,
  uploadPostMediaOnAwsAPI,
  deletePostAPI,
  getPostsByID,
  getLinkPriviewAPI,
  updatePostAPI,
  getOtherUserPostsAPI,
  likePostAPI,
  dislikePostAPI,
  getCommentsAPI,
  createPostCommentAPI,
  updateCommentAPI,
  deleteCommentAPI,
  getCommentRepliesAPI,
  addBookmarkAPI,
  getBookmarkAPI,
  removeBookmarkAPI,
  getProfileMediaAPI,
  unlockPostAPI,
  createNewPostAPI,
  updatePostCreatorsAPI,
  getMentionUsersAPI,
  getBookmarkMediaAPI,
  hideUserPostsFromFeedAPI,
  iDontLikePostAPI,
} from "Api/PostApi";
import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";

function* updatePostCreatorsWorker({ payload, updateRfType }) {
  try {
    const res = yield call(updatePostCreatorsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_POST_CREATORS,
        payload: res,
      });
      if (updateRfType === "remove") {
        yield put({
          type: ADD_NEW_NOTIFICATION,
          payload: {
            message: "Tag has been removed",
            type: "success",
          },
        });
      } else if (updateRfType === "add") {
        yield put({
          type: ADD_NEW_NOTIFICATION,
          payload: {
            message: "Tag has been added",
            type: "success",
          },
        });
      }
    } else {
      yield put({
        type: ERROR_UPDATE_POST_CREATORS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* createPostWorker({ payload }) {
  try {
    const response = yield call(createPostAPI, {
      link_preview: payload?.link_preview,
      description: payload?.description,
      post_images: payload?.post_images,
      post_videos: payload?.post_videos,
      post_price: payload?.post_price,
    });
    if (response.status) {
      yield put({
        type: POST_CREATED_LOCAL,
        payload: response,
      });
      let response1;
      let response2;
      if (
        payload?.post_images?.length > 0 ||
        payload?.post_videos?.length > 0
      ) {
        response1 = yield call(
          uploadPostMediaOnAwsAPI,
          response?.images_signed_urls
            ?.concat(response?.videos_signed_urls)
            ?.map((item) => item?.url),
          (payload?.post_media || []).concat(payload?.post_video_media || [])
        );
      } else {
        response1 = true;
      }
      if (response1) {
        if (payload?.post_images?.length > 0) {
          response2 = yield call(createPostServerCallAPI, response?.post?.id, {
            file_key: response?.images_signed_urls?.map(
              (item) => item?.signed_url?.fields?.key
            ),
          });
        } else {
          response2 = true;
        }
        if (response2) {
          yield put({
            type: SUCCESS_CREATE_POST,
            payload: response,
            files: response2,
          });
        } else {
          yield put({
            type: ERROR_CREATE_POST,
            payload: response2,
          });
        }
      } else {
        yield put({
          type: ERROR_CREATE_POST,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_CREATE_POST,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* createNewPostWorker({ payload }) {
  try {
    const res = yield call(createNewPostAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_NEW_POST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CREATE_NEW_POST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getAllPostWorker({ payload }) {
  try {
    const response = yield call(getAllPostAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_ALL_POST,
        payload: response,
        isMyPost: payload?.owned,
      });
      function* temp(tempRes, postCount) {
        if (postCount != tempRes?.post_count && postCount < 10) {
          const response1 = yield call(getAllPostAPI, {
            offset: postCount,
            limit: 3,
          });
          if (response1.status) {
            yield put({
              type: SUCCESS_GET_ALL_POST,
              payload: response1,
              isMyPost: payload?.owned,
            });
            if (postCount + response1?.posts?.length < 10) {
              yield call(temp, response1, postCount + response1?.posts?.length);
            }
          } else {
            yield put({
              type: ERROR_GET_ALL_POST,
              payload: response1,
            });
          }
        }
      }
      if (payload?.limit == 3) {
        yield call(temp, response, response?.posts?.length);
      }
    } else {
      yield put({
        type: ERROR_GET_ALL_POST,
        payload: response,
      });
    }
  } catch (error) {}
}

function* deletePostWorker({ post_id }) {
  try {
    const response = yield call(deletePostAPI, post_id);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_POST,
        payload: response,
        post_id: post_id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Post deleted successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_POST,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getSinglePostWorker({ payload }) {
  try {
    const response = yield call(getPostsByID, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_SINGLE_POST_BYID,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_SINGLE_POST_BYID,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getLinkPriviewWorker({ payload }) {
  try {
    const response = yield call(getLinkPriviewAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_LINK_PREVIEW,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_LINK_PREVIEW,
        payload: response,
      });
    }
  } catch (error) {}
}

function* updatePostWorker({ post_id, payload }) {
  try {
    const response = yield call(updatePostAPI, post_id, {
      description: payload?.description,
      post_images: payload?.post_images,
      delete_post_image_ids: payload?.delete_post_image_ids?.map(
        (item) => item?.id
      ),
      rfDoc: payload?.rfDoc,
      rfLink: payload?.rfLink,
      rfTag: payload?.rfTag,
      rfGuest: payload?.rfGuest,
      is_scheduled: payload?.is_scheduled,
      scheduled_date: payload?.scheduled_date,
      saved_for_later: payload?.saved_for_later,
      collab_users: payload?.collab_users,
    });
    if (response.status) {
      let response1;
      let response2 = false;
      if (payload?.post_images?.length > 0) {
        response1 = yield call(
          uploadPostMediaOnAwsAPI,
          response?.new_image_signed_urls?.map((item) => item?.url),
          payload?.post_media
        );
      } else {
        response1 = true;
      }
      if (response1) {
        if (payload?.post_images?.length > 0) {
          response2 = yield call(createPostServerCallAPI, response?.post?.id, {
            file_key: response?.new_image_signed_urls?.map(
              (item) => item?.signed_url?.fields?.key
            ),
          });
        } else {
          response2 = true;
        }
        if (response2.status || response2) {
          yield put({
            type: SUCCESS_UPDATE_POST,
            payload: response,
            files: response2,
            deletedImages: payload?.delete_post_image_ids,
          });
          yield put({
            type: ADD_NEW_NOTIFICATION,
            payload: {
              message: "Post updated successfully.",
              type: "success",
            },
          });
        } else {
          yield put({
            type: ERROR_UPDATE_POST,
            payload: response2,
          });
        }
      } else {
        yield put({
          type: ERROR_UPDATE_POST,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_UPDATE_POST,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getOtherUserPostsWorker({ user_id, payload }) {
  try {
    const response = yield call(getOtherUserPostsAPI, user_id, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_OTHER_USER_POSTS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_OTHER_USER_POSTS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* postLikeWorker({ post_id }) {
  try {
    const response = yield call(likePostAPI, post_id);
    if (response.status) {
      yield put({
        type: SUCCESS_USER_POST_LIKE,
        payload: response,
        post_id: post_id,
      });
    } else {
      yield put({
        type: ERROR_USER_POST_LIKE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* postDislikeWorker({ post_id }) {
  try {
    const response = yield call(dislikePostAPI, post_id);
    if (response.status) {
      yield put({
        type: SUCCESS_USER_POST_DISLIKE,
        payload: response,
        post_id: post_id,
      });
    } else {
      yield put({
        type: ERROR_USER_POST_DISLIKE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getCommentsWorker({ post_id, payload }) {
  try {
    const response = yield call(getCommentsAPI, post_id, payload);
    if (response?.status) {
      yield put({
        type: SUCCESS_GET_POST_COMMENTS,
        payload: response,
        post_id: post_id,
      });
    } else {
      yield put({
        type: ERROR_GET_POST_COMMENTS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* createPostCommentWorker({ post_id, payload }) {
  try {
    const response = yield call(createPostCommentAPI, post_id, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_CREATE_POST_COMMENT,
        payload: response,
        post_id: post_id,
        comment_id: payload?.comment_id,
      });
    } else {
      yield put({
        type: ERROR_CREATE_POST_COMMENT,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateCommentWorker({ post_id, comment_id, payload }) {
  try {
    const response = yield call(updateCommentAPI, post_id, comment_id, {
      comment: payload?.comment,
    });
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_COMMENT,
        payload: response,
        post_id,
        comment_id,
        commentData: payload,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_COMMENT,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteCommentWorker({ post_id, payload }) {
  try {
    const response = yield call(deleteCommentAPI, post_id, payload?.id);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_COMMENT,
        payload: response,
        post_id,
        commentData: payload,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Comment deleted successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_DELETE_COMMENT,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getCommentRepliesWorker({ post_id, comment_id, payload }) {
  try {
    const response = yield call(
      getCommentRepliesAPI,
      post_id,
      comment_id,
      payload
    );
    if (response.status) {
      yield put({
        type: SUCCESS_GET_COMMENT_REPLIES,
        payload: response,
        post_id: post_id,
        comment_id: comment_id,
      });
    } else {
      yield put({
        type: ERROR_GET_COMMENT_REPLIES,
        payload: response,
      });
    }
  } catch (error) {}
}

function* addBookmarkWorker({ payload }) {
  try {
    const response = yield call(addBookmarkAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_ADD_BOOKMARK,
        payload: response,
        post_id: payload?.id,
      });
    } else {
      yield put({
        type: ERROR_ADD_BOOKMARK,
        payload: response,
      });
    }
  } catch (error) {}
}

function* removeBookmarkWorker({ payload }) {
  try {
    const response = yield call(removeBookmarkAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_REMOVE_BOOKMARK,
        payload: response,
        bookmark_id: payload?.bookmark_id,
      });
    } else {
      yield put({
        type: ERROR_REMOVE_BOOKMARK,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getBookmarkWorker({ payload }) {
  try {
    const response = yield call(getBookmarkAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_BOOKMARKS,
        payload: response,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_BOOKMARKS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getBookmarkMediaWorker({ payload }) {
  try {
    const response = yield call(getBookmarkMediaAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_BOOKMARK_MEDIA,
        payload: response,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_BOOKMARK_MEDIA,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getProfileMediaWorker({ payload }) {
  try {
    const response = yield call(getProfileMediaAPI, payload);
    if (response?.status) {
      yield put({
        type: SUCCESS_GET_PROFILE_MEDIA,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_PROFILE_MEDIA,
        payload: response,
      });
    }
  } catch (error) {}
}

function* unlockPostWorker({ payload }) {
  try {
    const res = yield call(unlockPostAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UNLOCK_POST,
        payload: res,
        post_id: payload?.post_id,
        data: payload,
      });
    } else {
      yield put({
        type: ERROR_UNLOCK_POST,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong! try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getMentionUsersWorker({ payload }) {
  try {
    const res = yield call(getMentionUsersAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_MENTION_USERS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_MENTION_USERS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* hideUsersPostsFromFeedWorker({ hide_user_id }) {
  try {
    const res = yield call(hideUserPostsFromFeedAPI, hide_user_id);
    if (res.status) {
      yield put({
        type: SUCCESS_HIDE_USERS_POSTS_FROM_FEED_ACTION,
        payload: res,
        hide_user_id,
      });
    } else {
      yield put({
        type: ERROR_HIDE_USERS_POSTS_FROM_FEED_ACTION,
        payload: res || "Something went wrong",
      });
    }
  } catch (error) {}
}

function* iDontLikePostWorker({ id }) {
  try {
    const res = yield call(iDontLikePostAPI, id);
    if (res.status) {
      yield put({
        type: SUCCESS_I_DONT_LIKE_POST_ACTION,
        payload: res,
        id,
      });
    } else {
      yield put({
        type: ERROR_I_DONT_LIKE_POST_ACTION,
        payload: res || "Something went wrong",
      });
    }
  } catch (error) {}
}

export function* PostWatcher() {
  yield takeEvery(START_CREATE_POST, createPostWorker);
  yield takeEvery(START_CREATE_NEW_POST, createNewPostWorker);
  yield takeEvery(START_GET_ALL_POST, getAllPostWorker);
  yield takeEvery(START_DELETE_POST, deletePostWorker);
  yield takeEvery(START_GET_SINGLE_POST_BYID, getSinglePostWorker);
  yield takeEvery(START_LINK_PREVIEW, getLinkPriviewWorker);
  yield takeEvery(START_UPDATE_POST, updatePostWorker);
  yield takeEvery(START_GET_OTHER_USER_POSTS, getOtherUserPostsWorker);
  yield takeEvery(START_USER_POST_LIKE, postLikeWorker);
  yield takeEvery(START_USER_POST_DISLIKE, postDislikeWorker);
  yield takeEvery(START_GET_POST_COMMENTS, getCommentsWorker);
  yield takeEvery(START_CREATE_POST_COMMENT, createPostCommentWorker);
  yield takeEvery(START_UPDATE_COMMENT, updateCommentWorker);
  yield takeEvery(START_DELETE_COMMENT, deleteCommentWorker);
  yield takeEvery(START_GET_COMMENT_REPLIES, getCommentRepliesWorker);
  yield takeEvery(START_ADD_BOOKMARK, addBookmarkWorker);
  yield takeEvery(START_REMOVE_BOOKMARK, removeBookmarkWorker);
  yield takeEvery(START_GET_BOOKMARKS, getBookmarkWorker);
  yield takeEvery(START_GET_BOOKMARK_MEDIA, getBookmarkMediaWorker);
  yield takeEvery(START_GET_PROFILE_MEDIA, getProfileMediaWorker);
  yield takeEvery(START_UNLOCK_POST, unlockPostWorker);
  yield takeEvery(START_UPDATE_POST_CREATORS, updatePostCreatorsWorker);
  yield takeEvery(START_GET_MENTION_USERS, getMentionUsersWorker);
  yield takeEvery(
    START_HIDE_USERS_POSTS_FROM_FEED_ACTION,
    hideUsersPostsFromFeedWorker
  );
  yield takeEvery(START_I_DONT_LIKE_POST_ACTION, iDontLikePostWorker);
}
