import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Typography,
  Divider,
  Button,
  DialogActions,
  CircularProgress,
} from "Components/Common/MuiComponents";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {
  const {
    open,
    handleDialogClose,
    loading,
    title = "",
    body = "",
    actionButtonTitle = "",
    handleClick = () => {},
    showCancelBtn = true,
    showActionButton = true,
    showDialogTitle = true,
    ...other
  } = props;

  return (
    <Dialog
      open={open}
      onClose={typeof handleDialogClose == "function" && handleDialogClose}
      maxWidth={"msm"}
      TransitionComponent={Transition}
      {...other}
    >
      {showDialogTitle && (
        <>
          <DialogTitle>
            <Typography variant="h4" medium color="black">
              {title}
            </Typography>
          </DialogTitle>
          <Divider />
        </>
      )}
      <DialogContent>
        <Typography variant="body">{body}</Typography>
      </DialogContent>
      <DialogActions>
        {showCancelBtn && (
          <Button variant="outlined" medium onClick={() => handleDialogClose()}>
            Cancel
          </Button>
        )}
        {showActionButton && (
          <Button
            variant="contained"
            medium
            disabled={loading}
            startIcon={
              loading && <CircularProgress size="20px" color="white" />
            }
            onClick={() => handleClick()}
          >
            {actionButtonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
