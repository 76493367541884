import {
  ERROR_SEARCH,
  ERROR_USER_SEARCH,
  START_SEARCH,
  START_USER_SEARCH,
  SUCCESS_SEARCH,
  SUCCESS_USER_SEARCH,
} from "Actions/SearchActions";
import { searchAPI, searchUserAPI } from "Api/SearchApi";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

function* searchWorker({ payload, searchType }) {
  try {
    const res = yield call(searchAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SEARCH,
        payload: res,
        searchType: searchType,
      });
    } else {
      yield put({
        type: ERROR_SEARCH,
        payload: res,
      });
    }
  } catch (error) {}
}

function* searchUserWorker({ payload }) {
  try {
    const res = yield call(searchUserAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_USER_SEARCH,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_USER_SEARCH,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* SearchWatcher() {
  yield takeLatest(START_SEARCH, searchWorker);
  yield takeLatest(START_USER_SEARCH, searchUserWorker);
}
