import {
  START_GET_MEMBERS,
  SUCCESS_GET_MEMBERS,
  ERROR_GET_MEMBERS,
  START_INVITE_MEMBERS,
  SUCCESS_INVITE_MEMBERS,
  ERROR_INVITE_MEMBERS,
  START_SUBMIT_INVITE_RESPONSE,
  SUCCESS_SUBMIT_INVITE_RESPONSE,
  ERROR_SUBMIT_INVITE_RESPONSE,
  START_REMOVE_MEMBERS,
  SUCCESS_REMOVE_MEMBERS,
  ERROR_REMOVE_MEMBERS,
  START_EDIT_MEMBER,
  SUCCESS_EDIT_MEMBER,
  ERROR_EDIT_MEMBER,
} from "Actions/AgencyAction";

const initialState = {
  getMembers: {
    isLoading: false,
    data: {},
    error: "",
  },
  sendInvitation: {
    isLoading: false,
    data: {},
    error: "",
  },
  submitInvitaionResponse: {
    isLoading: false,
    data: {},
    error: "",
  },
  removeMember: {
    isLoading: false,
    data: {},
    error: "",
  },
  editMember: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const agencyReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case START_GET_MEMBERS: {
      return {
        ...state,
        getMembers: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_MEMBERS: {
      return {
        ...state,
        getMembers: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_MEMBERS: {
      return {
        ...state,
        getMembers: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_INVITE_MEMBERS: {
      return {
        ...state,
        sendInvitation: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_INVITE_MEMBERS: {
      return {
        ...state,
        sendInvitation: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_INVITE_MEMBERS: {
      return {
        ...state,
        sendInvitation: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_SUBMIT_INVITE_RESPONSE: {
      return {
        ...state,
        submitInvitaionResponse: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SUBMIT_INVITE_RESPONSE: {
      return {
        ...state,
        submitInvitaionResponse: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SUBMIT_INVITE_RESPONSE: {
      return {
        ...state,
        submitInvitaionResponse: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_REMOVE_MEMBERS: {
      return {
        ...state,
        removeMember: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_REMOVE_MEMBERS: {
      return {
        ...state,
        getMembers : {
          ...state.getMembers,
          data : {
            ...state.getMembers.data,
            data : state.getMembers.data?.data?.filter(a => a.id !== action?.payload?.id)
          }
        },
        removeMember: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_REMOVE_MEMBERS: {
      return {
        ...state,
        removeMember: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_EDIT_MEMBER: {
      return {
        ...state,
        editMember: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_EDIT_MEMBER: {
      return {
        ...state,
        editMember: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_EDIT_MEMBER: {
      return {
        ...state,
        editMember: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};