import React from "react";
import { useTheme, makeStyles } from "Components/Common/MuiComponents";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
  yhPrimaryIcon: {
    "&:hover": {
      fill: `${theme.palette.primary.main}`,
    },
  },
}));

export default function PrimaryIcon(props) {
  const {
    icon,
    color = "secondary",
    width,
    height,
    small,
    className,
    changeOnHover,
    ...other
  } = props;
  const theme = useTheme();

  const classes = useStyle();

  let size = {
    small: {
      width: 20,
      height: 20,
    },
  };

  let colors = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    white: theme.palette.white.main,
    black: theme.palette.black.main,
    error: theme.palette.error.main,
    red: theme.palette.red.main,
  };
  Object.keys(theme?.palette?.grey || {}).map((key) => {
    colors["grey" + key] = theme?.palette?.grey[key];
  });

  return (
    <>
      {React.createElement(icon, {
        ...(small && { width: size.small.width, height: size.small.height }),
        ...(width && { width }),
        ...(height && { height }),
        fill: colors[color] || color,

        className: clsx(className, changeOnHover && classes.yhPrimaryIcon),
        ...other,
      })}
    </>
  );
}
