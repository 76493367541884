import { Transition } from "Components/Common/ConfirmationDialog";
import { alpha } from "Components/Common/MuiComponents";
export const MuiTypography = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      lineHeight: 1.5,
      ...(ownerState?.displayFlex && {
        display: "flex",
        alignItems: "center",
      }),
    }),
  },
  variants: [
    {
      props: { variant: "h1" },
      style: {
        fontSize: "34px !important",
      },
    },
    {
      props: { variant: "h2" },
      style: {
        fontSize: "30px !important",
      },
    },
    {
      props: { variant: "h3" },
      style: {
        fontSize: "24px !important",
      },
    },
    {
      props: { variant: "h4" },
      style: {
        fontSize: "20px !important",
      },
    },
    {
      props: { variant: "h5" },
      style: {
        fontSize: "18px !important",
      },
    },
    {
      props: { variant: "body1" },
      style: {
        fontSize: "16px",
      },
    },
    {
      props: { variant: "body2" },
      style: {
        fontSize: "14px",
      },
    },
    {
      props: { variant: "small" },
      style: {
        fontSize: "12px",
      },
    },
    {
      props: { upperCase: true },
      style: {
        textTransform: "uppercase",
      },
    },
    {
      props: { semiBold: true },
      style: {
        fontWeight: "600",
      },
    },
    {
      props: { medium: true },
      style: {
        fontWeight: "500",
      },
    },
  ],
};

export const MuiButton = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    // startIcon: {
    //   marginLeft: "auto",
    // },
    // endIcon: {
    //   marginRight: "auto",
    // },
    root: ({ theme, ownerState }) => ({
      textTransform: "none ",
      "&:disabled": {
        // background: `${theme.palette.primary.main}70`,
        // color: "inherit",
      },
    }),
  },
  variants: [
    {
      props: { variant: "contained" },
      style: {
        borderRadius: "5px",
        padding: "8px 20x !important",
        height: "36px",
        lineHeight: 1,
        color: "white",
        textTransform: "none ",
        minWidth: "80px",
      },
    },
    {
      props: { customColorsBtn: true },
      style: ({ theme, ownerState }) => ({
        backgroundColor: `${
          theme.palette?.[ownerState?.backgroundColor || "secondary"].main
        }30`,
        color: theme.palette?.[ownerState?.color || "black"].main,
        "& .MuiButton-startIcon": {
          "& svg": {
            fill: `${
              theme.palette?.[ownerState?.color || "black"].main
            } !important`,
          },
        },
        "&.active": {
          backgroundColor: theme.getHoverBgColorFromColor("primary", 0.2),
        },
        "&:hover": {
          backgroundColor: theme.getHoverBgColorFromColor(),
          color: theme.palette.primary.main,
          "& .MuiButton-startIcon": {
            "& svg": {
              fill: `${theme.palette.primary.main} !important`,
            },
          },
        },
      }),
    },
    {
      props: { variant: "outlined" },
      style: {
        borderRadius: "5px",
        padding: "8px 20px",
        height: "36px",
        lineHeight: 1,
        textTransform: "none ",
        minWidth: "80px",
      },
    },
    {
      props: { rounded: true },
      style: {
        borderRadius: "1000px",
      },
    },
    {
      props: { medium: true },
      style: {
        height: "30px",
        padding: "5px 18x !important",
      },
    },
    {
      props: { small: true },
      style: {
        height: "25px",
        padding: "5px 18x !important",
      },
    },
  ],
};

export const MuiDialog = {
  MuiDialog: {
    defaultProps: {
      // TransitionComponent: Transition,
    },
    styleOverrides: {
      root: {
        backdropFilter: "blur(5px)",
      },
      paper: ({ ownerState, theme }) => ({
        width: "100%",
        padding: ownerState?.disableSpace ? "0px" : "15px 15px 20px 15px ",
        borderRadius: "10px",
        margin: ownerState?.disableSpace ? "0px" : "20px auto",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "90%",
        },
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        textTransform: "uppercase",
        padding: ownerState?.withPadding
          ? "15px 15px 7px 15px"
          : "0px 0px 7px 0px",
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "10px 0px 0px 0px",
        overflowY: "unset",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        marginTop: "20px",
        padding: ownerState?.withPadding ? "10px 10px 10px 10px" : "0px",
        "&>button": {
          "&:not(:first-of-type)": {
            marginLeft: "15px !important",
          },
        },
      }),
    },
  },
};

export const MuiAvatar = {
  MuiAvatar: {
    variants: [
      {
        props: { variant: "rounded" },
        style: {
          borderRadius: "50%",
          img: {
            borderRadius: "50%",
          },
        },
      },
      {
        props: { isActive: true },
        style: ({ ownerState, theme }) => ({
          "&:after": {
            position: "absolute",
            content: !ownerState?.noborder && "''",
            width: ownerState?.dotLength || "12px",
            height: ownerState?.dotLength || "12px",
            right: ownerState?.variant == "rounded" ? "3px" : "-3px",
            bottom: ownerState?.variant == "rounded" ? "3px" : "-3px",
            borderRadius: "50%",
            zIndex: 2,
            border: `2px solid ${theme.palette.background.paper}`,
            backgroundColor: `${theme.palette.green.main}`,
          },
        }),
      },
    ],
    styleOverrides: {
      img: {
        crossOrigin: "anonymous",
      },
      root: ({ ownerState, theme }) => ({
        borderRadius: "5px",
        width: "40px",
        height: "40px",
        padding: !ownerState?.noborder ? "2px" : "0px",
        overflow: "inherit",
        "&:before": {
          position: "absolute",
          content: !ownerState?.noborder && "''",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          borderRadius: ownerState?.variant == "rounded" ? "50px" : "5px",
          backgroundColor: `${theme.palette.background.paper}`,
        },
        "&>span": {
          position: "relative",
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontSize: "20px",
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          background: theme.palette.background.default,
          borderRadius: ownerState?.variant == "rounded" ? "50%" : "2px",
        },
      }),
      img: {
        borderRadius: "5px",
        position: "relative",
      },
    },
  },
};

export const MuiAppBar = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      backgroundColor: `${theme.palette.background.paper}`,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: "none",
      paddingTop: "15px",
      paddingBottom: "15px",
      marginBottom: "20px",
      backgroundImage: "none",
      zIndex: 1300,
      [theme.breakpoints.down("mlg")]: {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    }),
  },
};

export const MuiDivider = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      backgroundColor: `${alpha(theme.palette?.secondary?.main, 0.5)}`,
      ...(ownerState?.regular
        ? {
            opacity: 0.3,
          }
        : {
            opacity: ownerState?.opacity || 1,
          }),
      // height: "1px !important",
      // borderBottomWidth: "inherit",
      // border: "0",
    }),
  },
  variants: [
    {
      props: { filterDivider: true },
      style: {
        marginTop: "8px",
        marginBottom: "8px",
      },
    },
    {
      props: { headingDivider: true },
      style: {
        marginTop: "8px",
        marginBottom: "14px",
      },
    },
  ],
};

export const MuiToolbar = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      minHeight: "100px !important",
      [theme.breakpoints.down("mlg")]: {
        minHeight: "80px !important",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "65px !important",
      },
    }),
  },
};

export const MuiBadge = {
  styleOverrides: {
    badge: {
      height: "auto",
      minWidth: "auto",
    },
  },
};

export const MuiTimeLine = {
  MuiTimelineDot: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        boxShadow: "none",
        ...(ownerState?.normal && {
          display: "inline-flex",
          padding: ownerState?.width || "4px",
          margin: `0px ${ownerState?.spacing || "5px"} `,
          borderWidth: "0px",
          alignSelf: "center",
        }),
      }),
    },
  },
};

export const MuiIconButton = {
  variants: [
    {
      props: { backicon: true },
      style: ({ ownerState, theme }) => ({
        "& svg": {
          transform: "scaleX(-1)",
        },
        ...(ownerState?.mobileOnly && {
          [theme.breakpoints.up("slg")]: {
            display: "none !important",
          },
        }),
      }),
    },
    {
      props: { contained: true },
      style: ({ ownerState, theme }) => ({
        background: theme.palette.primary.main,
        height: ownerState?.height || "inherit",
        width: ownerState?.width || "inherit",
      }),
    },
  ],
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      "&:hover": {
        "&>svg": {
          fill: theme.palette.primary.main,
        },
      },
    }),
  },
};

export const MuiChip = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      boxShadow: "none !important",
      "& .MuiChip-icon": {
        marginLeft: "10px",
      },
      ...(ownerState?.onHover && {
        "&:hover": {
          backgroundColor: theme.getHoverBgColorFromColor(
            ownerState?.onHover || "primary",
            "0.12"
          ),
          color: theme.palette?.[ownerState?.onHover || "primary"]?.main,
          "& .MuiChip-icon": {
            fill: theme.palette?.[ownerState?.onHover || "primary"]?.main,
          },
        },
      }),
      ...(ownerState?.selected && {
        backgroundColor: `${theme.getHoverBgColorFromColor(
          "primary",
          "0.12"
        )} !important`,
      }),
    }),
  },
  variants: [
    {
      props: { variant: "filledSecondary" },
      style: ({ ownerState, theme }) => ({
        color: theme.palette.black.main,
        backgroundColor: `${theme.palette.secondary.main}30`,
        maxWidth: "max-content",
        width: ownerState?.isLoading ? "calc(100% - 30px)" : "100%",
        "& .MuiChip-icon": {
          fill: theme.palette.black.main,
          marginLeft: 10,
        },
        "& .MuiChip-deleteIcon": {
          marginRight: 10,
          borderRadius: "50%",
          backgroundColor: theme.palette.secondary.main,
          padding: 4,
          "&>svg": {
            fill: theme.palette.black.main,
            width: 10,
            height: 10,
          },
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        "&:hover": {
          backgroundColor: theme.getHoverBgColorFromColor(),
          color: theme.palette.primary.main,
          "& .MuiChip-icon": {
            fill: theme.palette.primary.main,
          },
        },
      }),
    },
  ],
};

export const MuiList = {
  MuiList: {
    styleOverrides: {
      root: {
        padding: "0px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        minHeight: "auto",
        ...(ownerState?.filterMenuItem && {
          padding: "5px",
          fontSize: ownerState?.small == true ? "14px" : "16px",
          color: theme.palette?.[ownerState?.textColor || "secondary"].main,
          borderRadius: "5px",
          "&:hover": {
            background: `${
              theme.palette?.[ownerState?.color || "primary"].main
            }18`,
            color: theme.palette?.[ownerState?.color || "primary"].main,
          },
        }),
      }),
    },
  },
};

export const MuiTable = {
  MuiTableContainer: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        border: `1px solid ${theme.palette.secondary.main}40`,
        padding: "0px",
        borderRadius: "6px",
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        "& th": {
          color: theme.palette.secondary.main,
          padding: "10px",
          whiteSpace: "nowrap",
        },
      }),
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        "& td": {
          fontSize: "12px",
          padding: "10px",
          "&>a": {
            fontSize: "12px",
            color: theme.palette.primary.main,
            textDecoration: "none",
          },
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderBottom: ownerState?.noBorder ? "none" : "",
        minWidth: ownerState?.minWidth || "auto",
      }),
    },
    variants: [
      {
        props: { semiBold: true },
        style: {
          fontWeight: "600",
        },
      },
      {
        props: { medium: true },
        style: {
          fontWeight: "500",
        },
      },
    ],
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        verticalAlign: ownerState?.align || "middle",
      }),
    },
  },
};

export const MuiFormControlLabel = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      marginLeft: "-5px",
      "&>span": {
        padding: "5px",
      },
    }),
  },
};

export const MuiStack = {
  styleOverrides: {
    root: (ownerState) => ({
      ...(!ownerState?.disableOverflow && {
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }),
    }),
  },
};

export const MuiMasonry = {
  MuiMasonry: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        "&>div": {
          ...(ownerState?.fullWidth && {
            [theme.breakpoints.down(ownerState?.fullWidth)]: {
              width: "100%",
            },
          }),
        },
      }),
    },
  },
};

export const MuiTabs = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      minHeight: ownerState?.primary ? "40px" : "inherit",
      "& button": {
        ...(ownerState?.primary && {
          padding: "10px 10px",
          minHeight: "auto ",
          minWidth: "auto",
          "&:hover": {
            background: `${theme.palette.primary.main}15`,
            borderRadius: "6px",
            color: theme.palette.primary.main,
          },
        }),
      },
    }),
  },
};
