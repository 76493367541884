import {
  ADD_NEW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  RESET_CONFIG_STATE,
  SET_LIST_DIALOG_CONFIG,
  SET_STREAM_VIDEO_LOADING,
} from "Actions/ConfigAction";
import { SOCKET_VERIFY_IDENTITY, SUCCESS_GET_USER } from "Actions/UserAction";
import { SUCCESS_ADD_USERS_TO_LIST } from "Actions/ListActions";

const initialState = {
  isCreator: false,
  user_type: "",
  notificationDetail: {
    notifications: [],
  },
  changeTheme: false,
  openGlobalAuth: false,
  streamMediaLoading: false,
  listDialogConfigs: {
    open: false,
    data: {},
  },
};

const notificationConfig = {
  duration: 4000,
  type: "success",
  open: true,
  url: "",
};

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CONFIG_STATE: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }

    case SUCCESS_ADD_USERS_TO_LIST: {
      let dialogConfigs = { ...state.listDialogConfigs };
      if (action?.listData?.is_remove) {
        if (dialogConfigs?.data?.activeListId == action?.listData?.list_id) {
          dialogConfigs.open = false;
        }
      }
      return {
        ...state,
        listDialogConfigs: dialogConfigs,
      };
    }
    case ADD_NEW_NOTIFICATION: {
      const newNotification = {
        ...notificationConfig,
        ...action.payload,
        id: Math.round(new Date().valueOf()),
      };

      const data = [...state.notificationDetail.notifications, newNotification];

      return {
        ...state,
        notificationDetail: {
          notifications: data,
        },
      };
    }
    case CLOSE_NOTIFICATION: {
      let newNotification = state.notificationDetail.notifications.map(
        (item) => {
          if (item.id === action.id) {
            return {
              ...item,
              open: false,
            };
          } else {
            return item;
          }
        }
      );

      return {
        ...state,
        notificationDetail: {
          notifications: newNotification,
        },
      };
    }

    case SET_STREAM_VIDEO_LOADING: {
      return {
        ...state,
        streamMediaLoading: action.payload,
      };
    }

    case SET_LIST_DIALOG_CONFIG: {
      return {
        ...state,
        listDialogConfigs: {
          open: action.open || false,
          data: action.data || {},
        },
      };
    }

    case SUCCESS_GET_USER: {
      return {
        ...state,
        isCreator: action?.payload?.data?.idv_verification_status,
        user_type: action.payload?.data?.user_type,
      };
    }

    case SOCKET_VERIFY_IDENTITY: {
      let user_type = state?.user_type;
      if (action?.payload?.status == "Approved") {
        user_type = "fan";
      }
      return {
        ...state,
        user_type: user_type,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
