import {
  START_ADD_CARD,
  SUCCESS_ADD_CARD,
  ERROR_ADD_CARD,
  RESET_CARD_STATE,
  START_GET_CARD_LIST,
  SUCCESS_GET_CARD_LIST,
  ERROR_GET_CARD_LIST,
  START_DELETE_CARD,
  SUCCESS_DELETE_CARD,
  ERROR_DELETE_CARD,
  START_SET_DEFAULT_CARD,
  SUCCESS_SET_DEFAULT_CARD,
  ERROR_SET_DEFAULT_CARD,
  START_UPDATE_CARD_ADDRESS,
  SUCCESS_UPDATE_CARD_ADDRESS,
  ERROR_UPDATE_CARD_ADDRESS,
  START_MAKE_WALLET_PRIMARY,
  SUCCESS_MAKE_WALLET_PRIMARY,
  ERROR_MAKE_WALLET_PRIMARY,
  START_AUTO_RECHARGE_WALLET,
  SUCCESS_AUTO_RECHARGE_WALLET,
  ERROR_AUTO_RECHARGE_WALLET,
  START_SEND_TIP,
  SUCCESS_SEND_TIP,
  ERROR_SEND_TIP,
  START_GET_MY_WALLET,
  SUCCESS_GET_MY_WALLET,
  ERROR_GET_MY_WALLET,
  START_TOPUP_MY_WALLET,
  SUCCESS_TOPUP_MY_WALLET,
  ERROR_TOPUP_MY_WALLET,
  START_GET_TRANSACTIONS,
  SUCCESS_GET_TRANSACTIONS,
  ERROR_GET_TRANSACTIONS,
  START_GET_LATEST_TRANSACTION,
  SUCCESS_GET_LATEST_TRANSACTION,
  ERROR_GET_LATEST_TRANSACTION,
  SOCKET_ADDED_NEW_CARD,
} from "Actions/CardAction";

const initialState = {
  addCardData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getCardListData: {
    isLoading: false,
    data: {},
    error: "",
  },
  deleteCardData: {
    isLoading: false,
    data: {},
    error: "",
  },
  setDefaultCardData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateCardAddressData: {
    isLoading: false,
    data: {},
    error: "",
  },
  makeWalletPrimaryData: {
    isLoading: false,
    data: {},
    erro: "",
  },
  autoRechargeWalletData: {
    isLoading: false,
    data: {},
    error: "",
  },
  sendTipData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getMyWalletData: {
    isLoading: false,
    data: {},
    error: "",
  },
  topupMyWalletData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getTransactionData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getLatestTransactionData: {
    isLoading: false,
    data: {},
    error: "",
    refreshing: false,
  },
};

export const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CARD_STATE: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action?.key],
      };
    }
    case START_ADD_CARD: {
      return {
        ...state,
        addCardData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_CARD: {
      let oldList = { ...state?.getCardListData?.data };
      if (oldList?.cards?.length > 0) {
        oldList["cards"] = [action?.payload?.card, ...oldList?.cards];
      }

      return {
        ...state,
        addCardData: {
          isLoading: false,
          data: action?.payload,
          error: "",
        },
        getCardListData: {
          ...state.getCardListData,
          data: oldList,
        },
      };
    }
    case ERROR_ADD_CARD: {
      return {
        ...state,
        addCardData: {
          isLoading: false,
          data: {},
          error: action?.payload,
        },
      };
    }
    case START_GET_CARD_LIST: {
      return {
        ...state,
        getCardListData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_CARD_LIST: {
      return {
        ...state,
        getCardListData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_CARD_LIST: {
      return {
        ...state,
        getCardListData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_DELETE_CARD: {
      return {
        ...state,
        deleteCardData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DELETE_CARD: {
      let oldList = { ...state?.getCardListData?.data };
      if (action?.is_default) {
        oldList["cards"] = oldList?.cards
          ?.filter((item) => item?.id != action?.card_id)
          ?.map((item, i) => (i == 0 ? { ...item, is_default: true } : item));
      } else {
        oldList["cards"] = oldList?.cards?.filter(
          (item) => item?.id != action?.card_id
        );
      }

      return {
        ...state,
        deleteCardData: {
          isLoading: false,
          data: {},
          error: "",
        },
        getCardListData: {
          ...state.getCardListData,
          data: oldList,
        },
      };
    }
    case ERROR_DELETE_CARD: {
      return {
        ...state,
        deleteCardData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_SET_DEFAULT_CARD: {
      return {
        ...state,
        setDefaultCardData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SET_DEFAULT_CARD: {
      let oldList = { ...state?.getCardListData?.data };
      oldList["cards"] = oldList?.cards?.map((item) => {
        if (item?.id == action?.id) {
          return {
            ...item,
            is_default: true,
          };
        } else {
          return {
            ...item,
            is_default: false,
          };
        }
      });

      return {
        ...state,
        setDefaultCardData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getCardListData: {
          ...state.getCardListData,
          data: oldList,
        },
      };
    }
    case ERROR_SET_DEFAULT_CARD: {
      return {
        ...state,
        setDefaultCardData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_UPDATE_CARD_ADDRESS: {
      return {
        ...state,
        updateCardAddressData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_CARD_ADDRESS: {
      let oldList = { ...state?.getCardListData?.data };
      oldList["cards"] = oldList?.cards?.map((item) => {
        if (item?.id == action?.data?.id) {
          return {
            ...item,
            ...action?.data,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        updateCardAddressData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getCardListData: {
          ...state.getCardListData,
          data: oldList,
        },
      };
    }
    case ERROR_UPDATE_CARD_ADDRESS: {
      return {
        ...state,
        updateCardAddressData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_MAKE_WALLET_PRIMARY: {
      return {
        ...state,
        makeWalletPrimaryData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_MAKE_WALLET_PRIMARY: {
      return {
        ...state,
        makeWalletPrimaryData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_MAKE_WALLET_PRIMARY: {
      return {
        ...state,
        makeWalletPrimaryData: {
          isLoading: false,
          data: {},
          erro: action.payload,
        },
      };
    }
    case START_AUTO_RECHARGE_WALLET: {
      return {
        ...state,
        autoRechargeWalletData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_AUTO_RECHARGE_WALLET: {
      return {
        ...state,
        autoRechargeWalletData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_AUTO_RECHARGE_WALLET: {
      return {
        ...state,
        autoRechargeWalletData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_SEND_TIP: {
      return {
        ...state,
        sendTipData: {
          isLoading: true,
          data: {},
          erro: "",
        },
      };
    }
    case SUCCESS_SEND_TIP: {
      return {
        ...state,
        sendTipData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SEND_TIP: {
      return {
        ...state,
        sendTipData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_MY_WALLET: {
      return {
        ...state,
        getMyWalletData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_MY_WALLET: {
      return {
        ...state,
        getMyWalletData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_MY_WALLET: {
      return {
        ...state,
        getMyWalletData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_TOPUP_MY_WALLET: {
      return {
        ...state,
        topupMyWalletData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_TOPUP_MY_WALLET: {
      return {
        ...state,
        topupMyWalletData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_TOPUP_MY_WALLET: {
      return {
        ...state,
        topupMyWalletData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_TRANSACTIONS: {
      let oldList = { ...state?.getTransactionData?.data };
      return {
        ...state,
        getTransactionData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_TRANSACTIONS: {
      let oldList = { ...state?.getTransactionData?.data };
      if (oldList?.transactions?.length > 0) {
        oldList["transactions"] = [
          ...oldList?.transactions,
          ...action?.payload?.transactions,
        ];
      } else {
        oldList = action?.payload;
      }

      return {
        ...state,
        getTransactionData: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_TRANSACTIONS: {
      return {
        ...state,
        getTransactionData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_LATEST_TRANSACTION: {
      return {
        ...state,
        getLatestTransactionData: {
          isLoading: action?.refresh ? false : true,
          data: action?.refresh ? state?.getLatestTransactionData?.data : {},
          error: "",
          refreshing: action?.refresh,
        },
      };
    }
    case SUCCESS_GET_LATEST_TRANSACTION: {
      return {
        ...state,
        getLatestTransactionData: {
          isLoading: false,
          data: action.payload,
          error: "",
          refreshing: false,
        },
      };
    }
    case ERROR_GET_LATEST_TRANSACTION: {
      return {
        ...state,
        getLatestTransactionData: {
          isLoading: false,
          data: {},
          error: action.payload,
          refreshing: false,
        },
      };
    }

    case SOCKET_ADDED_NEW_CARD: {
      let oldList = { ...state?.getCardListData?.data };
      if (oldList?.cards?.length > 0) {
        oldList["cards"] = [action?.payload?.card, ...oldList?.cards];
      } else {
        oldList["cards"] = [action?.payload?.card];
      }

      return {
        ...state,
        getCardListData: {
          ...state.getCardListData,
          data: oldList,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
