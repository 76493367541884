import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  GET_PUBLIC_POSTS_BY_ID_ENDPOINT,
  GET_PUBLIC_PROFILE_MEDIA_ENDPOINT,
  GET_PUBLIC_PROFILE_POSTS_ENDPOINT,
} from "./endpoints";

export const getPublicProfilePostsAPI = (user_id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_PUBLIC_PROFILE_POSTS_ENDPOINT, { user_id }),
    payload
  );
};

export const getPublicProfileMediaAPI = (payload) => {
  return API.post(GET_PUBLIC_PROFILE_MEDIA_ENDPOINT, payload);
};

export const getPublicPostsByID = (payload) => {
  return API.post(GET_PUBLIC_POSTS_BY_ID_ENDPOINT, payload);
};
