import {
  START_GET_COLLAB_POSTS,
  SUCCESS_GET_COLLAB_POSTS,
  ERROR_GET_COLLAB_POSTS,
  START_COLLAB_POSTS_ACTION,
  SUCCESS_COLLAB_POSTS_ACTION,
  ERROR_COLLAB_POSTS_ACTION,
} from "Actions/CollabPosts";
import {
  SOCKET_ADD_POST_COMMENT,
  SOCKET_ADD_POST_LIKE,
  SOCKET_DELETE_POST,
  SOCKET_DELETE_POST_COMMENT,
  SOCKET_IMAGE_MEDIA_UPLOADED,
  SOCKET_POST_VIDEO_UPLOAD,
  SOCKET_REMOVE_POST_LIKE,
  SOCKET_UPDATE_POST_COMMENT,
  START_ADD_BOOKMARK,
  START_REMOVE_BOOKMARK,
  START_USER_POST_DISLIKE,
  START_USER_POST_LIKE,
  SUCCESS_ADD_BOOKMARK,
  SUCCESS_GET_COMMENT_REPLIES,
  SUCCESS_GET_POST_COMMENTS,
  SUCCESS_REMOVE_BOOKMARK,
} from "Actions/PostAction";

const initialState = {
  collabPosts: {
    isLoading: false,
    data: {},
    error: "",
  },
  collabPostsAction: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const collabPostsReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case START_GET_COLLAB_POSTS: {
      let oldCollabPosts = { ...state?.collabPosts?.data };
      return {
        ...state,
        collabPosts: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldCollabPosts : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_COLLAB_POSTS: {
      let oldCollabPosts = { ...state?.collabPosts?.data };
      if (oldCollabPosts?.posts?.length > 0) {
        oldCollabPosts["posts"] = [
          ...oldCollabPosts?.posts,
          ...action?.payload?.posts,
        ];
      } else {
        oldCollabPosts = action.payload;
      }

      return {
        ...state,
        collabPosts: {
          isLoading: false,
          data: oldCollabPosts,
          error: "",
        },
      };
    }
    case ERROR_GET_COLLAB_POSTS: {
      return {
        ...state,
        collabPosts: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_COLLAB_POSTS_ACTION: {
      return {
        ...state,
        collabPostsAction: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_COLLAB_POSTS_ACTION: {
      let oldCollabPosts = { ...state?.collabPosts?.data };
      if (oldCollabPosts?.posts?.length > 0) {
        oldCollabPosts["posts"] = oldCollabPosts["posts"].map((item) => {
          if (item?.id == action.post_id) {
            const updatedCollabs = item?.collabs?.map((collab) => {
              if (collab.user.id == root?.user?.userDetail?.data?.data?.id) {
                return {
                  ...collab,
                  status: action.actionStatus,
                };
              }
              return collab;
            });

            return {
              ...item,
              collabs: updatedCollabs,
              collab_status: action?.payload?.is_post_created
                ? "accepted"
                : action.actionStatus == "rejected"
                ? "rejected"
                : "pending",
            };
          }
          return item;
        });
      }

      return {
        ...state,
        collabPostsAction: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        collabPosts: {
          ...state.collabPosts,
          data: oldCollabPosts,
        },
      };
    }
    case ERROR_COLLAB_POSTS_ACTION: {
      return {
        ...state,
        collabPostsAction: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_USER_POST_LIKE: {
      const { post_id } = action;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts.posts = collabPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: 1,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case START_USER_POST_DISLIKE: {
      const { post_id } = action;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts.posts = collabPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: 0,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case START_ADD_BOOKMARK: {
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == action?.payload?.id) {
          return {
            ...item,
            bookmark_id: true,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SUCCESS_ADD_BOOKMARK: {
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            bookmark_id: action?.payload?.bookmark?.id,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case START_REMOVE_BOOKMARK: {
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.payload?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SUCCESS_REMOVE_BOOKMARK: {
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }

    case SUCCESS_GET_POST_COMMENTS: {
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            comments: [...item?.comments, ...action?.payload?.comments],
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }

    case SUCCESS_GET_COMMENT_REPLIES: {
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          let newComments = item?.comments;
          newComments = newComments?.map((comment) => {
            if (comment?.id == action?.comment_id) {
              let replyedComments = [
                ...comment?.replied_comments,
                ...action?.payload?.replied_comments,
              ];
              return {
                ...comment,
                replied_comments: replyedComments,
              };
            } else {
              return comment;
            }
          });
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }

    //  Posts Socket Cases
    case SOCKET_ADD_POST_LIKE: {
      const { post_id, liked_by } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts.posts = collabPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SOCKET_REMOVE_POST_LIKE: {
      const { post_id, unliked_by } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts.posts = collabPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SOCKET_ADD_POST_COMMENT: {
      const { post_comment_count, comment } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift({
                  ...comment,
                  editable: comment?.User?.id == myUserId ? true : false,
                });
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift({
              ...comment,
              editable: comment?.User?.id == myUserId ? true : false,
            });
          }
          return {
            ...item,
            comment_count: post_comment_count,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SOCKET_DELETE_POST_COMMENT: {
      const { comment_id, parent_comment_id, post_id } = action.payload;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          let newComments = item?.comments;
          let commentCount = item?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.comment_count - 1;
          }
          return {
            ...item,
            comment_count: commentCount,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SOCKET_UPDATE_POST_COMMENT: {
      const { comment } = action.payload;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts["posts"] = collabPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }
    case SOCKET_DELETE_POST: {
      const { post_id } = action.payload;
      let collabPosts = { ...state?.collabPosts?.data };
      if (Object.keys(state?.collabPosts?.data)?.length > 0) {
        collabPosts.post_count = collabPosts.post_count - 1;
        collabPosts.posts = collabPosts?.posts?.filter(
          (post) => post?.id != post_id
        );
      }

      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }

    case SOCKET_IMAGE_MEDIA_UPLOADED: {
      const { category, data } = action.payload;
      const { post_id, id } = data;
      let collabPosts = { ...state?.collabPosts?.data };
      if (category == "post_image") {
        collabPosts.posts = collabPosts?.posts?.map((item) => {
          if (item?.id == post_id) {
            return {
              ...item,
              post_images: item?.post_images?.map((image) => {
                if (image?.id == id) {
                  return {
                    ...image,
                    ...data,
                  };
                } else {
                  return image;
                }
              }),
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }

    case SOCKET_POST_VIDEO_UPLOAD: {
      const { post_id, uploaded_video } = action.payload;
      let collabPosts = { ...state?.collabPosts?.data };
      collabPosts.posts = collabPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            post_videos: item?.post_videos?.map((video) => {
              if (video?.id == uploaded_video?.id) {
                return {
                  ...video,
                  thumbnail: uploaded_video?.thumbnail_url,
                  video_url: uploaded_video?.video_url,
                  is_uploaded: true,
                };
              } else {
                return video;
              }
            }),
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        collabPosts: {
          ...state.collabPosts,
          data: collabPosts,
        },
      };
    }

    default: {
      return { ...(state || initialState) };
    }
  }
};
