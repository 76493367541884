export const START_GET_NOTIFICATIONS = "START_GET_NOTIFICATIONS";
export const SUCCESS_GET_NOTIFICATIONS = "SUCCESS_GET_NOTIFICATIONS";
export const ERROR_GET_NOTIFICATIONS = "ERROR_GET_NOTIFICATIONS";

export const START_READ_SINGLE_NOTIFICATION = "START_READ_SINGLE_NOTIFICATION";
export const SUCCESS_READ_SINGLE_NOTIFICATION =
  "SUCCESS_READ_SINGLE_NOTIFICATION";
export const ERROR_READ_SINGLE_NOTIFICATION = "ERROR_READ_SINGLE_NOTIFICATION";

export const START_READ_ALL_NOTIFICATION = "START_READ_ALL_NOTIFICATION";
export const SUCCESS_READ_ALL_NOTIFICATION = "SUCCESS_READ_ALL_NOTIFICATION";
export const ERROR_READ_ALL_NOTIFICATION = "ERROR_READ_ALL_NOTIFICATION";

export const SOCKET_ADD_NEW_NOTIFICATION = "SOCKET_ADD_NEW_NOTIFICATION";
export const SOCKET_REMOVE_NOTIFICATION = "SOCKET_REMOVE_NOTIFICATION";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

