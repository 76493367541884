import API from "service";
import {
  GET_STATEMENT_CHARGEBACK_LIST_ENDPOINT,
  GET_STATEMENT_CHARGEBACK_STATISTICS_ENDPOINT,
  GET_STATEMENT_EARNING_LIST_ENDPOINT,
  GET_STATEMENT_EARNING_STATISTICS_ENDPOINT,
  GET_STATEMENT_PAYOUT_LIST_ENDPOINT,
  GET_STATEMENT_PAYOUT_STATISTICS_ENDPOINT,
  GET_STATEMENT_REFERRALS_LIST_ENDPOINT,
  GET_STATEMENT_REFERRALS_STATISTICS_ENDPOINT,
  GET_STATISTICS_CONFIG_ENDPOINT,
} from "./endpoints";

export const getStatementEarningStatisticsAPI = (payload) => {
  return API.get(GET_STATEMENT_EARNING_STATISTICS_ENDPOINT, payload);
};

export const getStatementEarningListAPI = (payload) => {
  return API.get(GET_STATEMENT_EARNING_LIST_ENDPOINT, payload);
};

export const getStatisticsConfigAPI = (payload) => {
  return API.get(GET_STATISTICS_CONFIG_ENDPOINT, payload);
};

export const getStatementPayoutStatisticsAPI = (payload) => {
  return API.get(GET_STATEMENT_PAYOUT_STATISTICS_ENDPOINT, payload);
};

export const getStatementPayputListAPI = (payload) => {
  return API.get(GET_STATEMENT_PAYOUT_LIST_ENDPOINT, payload);
};

export const getStatementChargebackStatisticsAPI = (payload) => {
  return API.get(GET_STATEMENT_CHARGEBACK_STATISTICS_ENDPOINT, payload);
};

export const getStatementChargebackListAPI = (payload) => {
  return API.get(GET_STATEMENT_CHARGEBACK_LIST_ENDPOINT, payload);
};

export const getStatementReferralsStatisticsAPI = (payload) => {
  return API.get(GET_STATEMENT_REFERRALS_STATISTICS_ENDPOINT, payload);
};

export const getStatementReferralsListAPI = (payload) => {
  return API.get(GET_STATEMENT_REFERRALS_LIST_ENDPOINT, payload);
};
