export const RESET_SUBCRIPTION_STATE = "RESET_SUBCRIPTION_STATE";

export const START_SET_SUBSCRIPTION_PRICE = "START_SET_SUBSCRIPTION_PRICE";
export const SUCCESS_SET_SUBSCRIPTION_PRICE = "SUCCESS_SET_SUBSCRIPTION_PRICE";
export const ERROR_SET_SUBSCRIPTION_PRICE = "ERROR_SET_SUBSCRIPTION_PRICE";

export const START_GET_FREE_TRIAL_LINKS = "START_GET_FREE_TRIAL_LINKS";
export const SUCCESS_GET_FREE_TRIAL_LINKS = "SUCCESS_GET_FREE_TRIAL_LINKS";
export const ERROR_GET_FREE_TRIAL_LINKS = "ERROR_GET_FREE_TRIAL_LINKS";

export const START_CREATE_FREE_TRIAL_LINKS = "START_CREATE_FREE_TRIAL_LINKS";
export const SUCCESS_CREATE_FREE_TRIAL_LINKS =
  "SUCCESS_CREATE_FREE_TRIAL_LINKS";
export const ERROR_CREATE_FREE_TRIAL_LINKS = "ERROR_CREATE_FREE_TRIAL_LINKS";

export const START_DELETE_FREE_TRIAL_LINKS = "START_DELETE_FREE_TRIAL_LINKS";
export const SUCCESS_DELETE_FREE_TRIAL_LINKS =
  "SUCCESS_DELETE_FREE_TRIAL_LINKS";
export const ERROR_DELETE_FREE_TRIAL_LINKS = "ERROR_DELETE_FREE_TRIAL_LINKS";

export const START_CHECK_FREE_TRIAL_AVAILABILITY =
  "START_CHECK_FREE_TRIAL_AVAILABILITY";
export const SUCCESS_CHECK_FREE_TRIAL_AVAILABILITY =
  "SUCCESS_CHECK_FREE_TRIAL_AVAILABILITY";
export const ERROR_CHECK_FREE_TRIAL_AVAILABILITY =
  "ERROR_CHECK_FREE_TRIAL_AVAILABILITY";

export const START_JOIN_FREE_TRIAL = "START_JOIN_FREE_TRIAL";
export const SUCCESS_JOIN_FREE_TRIAL = "SUCCESS_JOIN_FREE_TRIAL";
export const ERROR_JOIN_FREE_TRIAL = "ERROR_JOIN_FREE_TRIAL";

export const START_ADD_SUBSCRIPTION_BUNDLE = "START_ADD_SUBSCRIPTION_BUNDLE";
export const SUCCCESS_ADD_SUBSCRIPTION_BUNDLE =
  "SUCCCESS_ADD_SUBSCRIPTION_BUNDLE";
export const ERROR_ADD_SUBSCRIPTION_BUNDLE = "ERROR_ADD_SUBSCRIPTION_BUNDLE";

export const START_DELETE_SUBSCRIPTION_BUNDLE =
  "START_DELETE_SUBSCRIPTION_BUNDLE";
export const SUCCESS_DELETE_SUBSCRIPTION_BUNDLE =
  "SUCCESS_DELETE_SUBSCRIPTION_BUNDLE";
export const ERROR_DELETE_SUBSCRIPTION_BUNDLE =
  "ERROR_DELETE_SUBSCRIPTION_BUNDLE";

export const START_ADD_PROMOTION_CAMPAIGN = "START_ADD_PROMOTION_CAMPAIGN";
export const SUCCESS_ADD_PROMOTION_CAMPAIGN = "SUCCESS_ADD_PROMOTION_CAMPAIGN";
export const ERROR_ADD_PROMOTION_CAMPAIGN = "ERROR_ADD_PROMOTION_CAMPAIGN";

export const START_DELETE_PROMOTION_CAMPAIGN =
  "START_DELETE_PROMOTION_CAMPAIGN";
export const SUCCESS_DELETE_PROMOTION_CAMPAIGN =
  "SUCCESS_DELETE_PROMOTION_CAMPAIGN";
export const ERROR_DELETE_PROMOTION_CAMPAIGN =
  "ERROR_DELETE_PROMOTION_CAMPAIGN";

export const START_STOP_PROMOTION_CAMPAIGN = "START_STOP_PROMOTION_CAMPAIGN";
export const SUCCESS_STOP_PROMOTION_CAMPAIGN =
  "SUCCESS_STOP_PROMOTION_CAMPAIGN";
export const ERROR_STOP_PROMOTION_CAMPAIGN = "ERROR_STOP_PROMOTION_CAMPAIGN";

export const START_SUBSCRIPTION_ACTION = "START_SUBSCRIPTION_ACTION";
export const SUCCESS_SUBSCRIPTION_ACTION = "SUCCESS_SUBSCRIPTION_ACTION";
export const ERROR_SUBSCRIPTION_ACTION = "ERROR_SUBSCRIPTION_ACTION";
