import {
  START_GET_BANK_DETAIL,
  SUCCESS_GET_BANK_DETAIL,
  ERROR_GET_BANK_DETAIL,
  RESET_PAYOUT_STATE,
  START_ADD_BANKING_DETAILS,
  SUCCESS_ADD_BANKING_DETAILS,
  ERROR_ADD_BANKING_DETAILS,
  START_SEND_PAYOUT_SECURITY_CODE,
  SUCCESS_SEND_PAYOUT_SECURITY_CODE,
  ERROR_SEND_PAYOUT_SECURITY_CODE,
  START_ADD_PAYOUT_METHOD,
  SUCCESS_ADD_PAYOUT_METHOD,
  ERROR_ADD_PAYOUT_METHOD,
  START_GET_STATEMENT_DETAIL,
  SUCCESS_GET_STATEMENT_DETAIL,
  ERROR_GET_STATEMENT_DETAIL,
  START_GET_EARNINGS_LIST,
  SUCCESS_GET_EARNINGS_LIST,
  ERROR_GET_EARNINGS_LIST,
  START_CHANGE_PAYOUT_TYPE,
  SUCCESS_CHANGE_PAYOUT_TYPE,
  ERROR_CHANGE_PAYOUT_TYPE,
  START_GET_PAYOUT_REQUESTS,
  SUCCESS_GET_PAYOUT_REQUESTS,
  ERROR_GET_PAYOUT_REQUESTS,
  START_GET_REFERRAL_EARNINGS,
  SUCCESS_GET_REFERRAL_EARNINGS,
  ERROR_GET_REFERRAL_EARNINGS,
  START_MANUAL_PAYOUT,
  SUCCESS_MANUAL_PAYOUT,
  ERROR_MANUAL_PAYOUT,
  START_GET_EARNING_STATISTICS,
  SUCCESS_GET_EARNING_STATISTICS,
  ERROR_GET_EARNING_STATISTICS,
  START_SUBMIT_W9_FORM,
  SUCCESS_SUBMIT_W9_FORM,
  ERROR_SUBMIT_W9_FORM,
  START_DOWNLOAD_W9_FORM,
  SUCCESS_DOWNLOAD_W9_FORM,
  ERROR_DOWNLOAD_W9_FORM,
  START_SUBMIT_W8_FORM,
  SUCCESS_SUBMIT_W8_FORM,
  ERROR_SUBMIT_W8_FORM,
} from "Actions/PayoutAction";

const initialState = {
  getBankDetailData: {
    isLoading: false,
    data: {},
    error: "",
  },
  addBankDetailData: {
    isLoading: false,
    data: {},
    error: "",
  },
  sendSecurityCodeData: {
    isLoading: false,
    data: {},
    error: "",
  },
  addPayoutMethodData: {
    isLoading: false,
    data: {},
    error: "",
  },
  statementDetailData: {
    isLoading: false,
    data: {},
    error: "",
  },
  earningList: {
    isLoading: false,
    data: {},
    error: "",
  },
  changePayoutTypeData: {
    isLoading: false,
    data: {},
    error: "",
  },
  payoutRequestsList: {
    isLoading: false,
    data: {},
    error: "",
  },
  referralEarningsList: {
    isLoading: false,
    data: {},
    error: "",
  },
  manualPayoutData: {
    isLoading: false,
    data: {},
    error: "",
  },
  earningStatistics: {
    isLoading: false,
    data: {},
    error: "",
  },
  submitW9FormData: {
    isLoading: false,
    data: {},
    error: "",
  },
  downloadW9FormData: {
    isLoading: false,
    data: {},
    error: "",
  },
  submitW8FormData: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const payoutReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_PAYOUT_STATE: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action?.key],
      };
    }
    case START_GET_BANK_DETAIL: {
      return {
        ...state,
        getBankDetailData: {
          isLoading: true,
          data: state?.getBankDetailData?.data ?? {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_BANK_DETAIL: {
      return {
        ...state,
        getBankDetailData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_BANK_DETAIL: {
      return {
        ...state,
        getBankDetailData: {
          isLoading: false,
          data: {},
          error: "",
        },
      };
    }
    case START_ADD_BANKING_DETAILS: {
      return {
        ...state,
        addBankDetailData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_BANKING_DETAILS: {
      let oldDetail = { ...state?.getBankDetailData?.data };
      oldDetail["bank_detail"] = {
        ...oldDetail?.bank_detail,
        ...action?.payload?.bank_detail,
      };
      return {
        ...state,
        addBankDetailData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getBankDetailData: {
          ...state.getBankDetailData,
          data: oldDetail,
        },
      };
    }
    case ERROR_ADD_BANKING_DETAILS: {
      return {
        ...state,
        addBankDetailData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_SEND_PAYOUT_SECURITY_CODE: {
      return {
        ...state,
        sendSecurityCodeData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SEND_PAYOUT_SECURITY_CODE: {
      return {
        ...state,
        sendSecurityCodeData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SEND_PAYOUT_SECURITY_CODE: {
      return {
        ...state,
        sendSecurityCodeData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_ADD_PAYOUT_METHOD: {
      return {
        ...state,
        addPayoutMethodData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_ADD_PAYOUT_METHOD: {
      return {
        ...state,
        addPayoutMethodData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_ADD_PAYOUT_METHOD: {
      return {
        ...state,
        addPayoutMethodData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_STATEMENT_DETAIL: {
      return {
        ...state,
        statementDetailData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_STATEMENT_DETAIL: {
      return {
        ...state,
        statementDetailData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_STATEMENT_DETAIL: {
      return {
        ...state,
        statementDetailData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_EARNINGS_LIST: {
      let oldList = { ...state?.earningList?.data };
      return {
        ...state,
        earningList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_EARNINGS_LIST: {
      let oldList = { ...state?.earningList?.data };
      if (oldList?.data?.length > 0) {
        oldList["data"] = [...oldList?.data, ...action?.payload?.data];
      } else {
        oldList = action.payload;
      }
      return {
        ...state,
        earningList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_EARNINGS_LIST: {
      return {
        ...state,
        earningList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_CHANGE_PAYOUT_TYPE: {
      return {
        ...state,
        changePayoutTypeData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CHANGE_PAYOUT_TYPE: {
      let statementData = { ...state?.statementDetailData?.data };
      if (Object.keys(statementData?.data)?.length > 0) {
        statementData["data"]["withdrawal_date"] =
          action?.payload?.withdrawal_date;
        statementData["data"]["auto_payout_type"] = action?.payoutType;
      }
      return {
        ...state,
        changePayoutTypeData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        statementDetailData: {
          ...state.statementDetailData,
          data: statementData,
        },
      };
    }
    case ERROR_CHANGE_PAYOUT_TYPE: {
      return {
        ...state,
        changePayoutTypeData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_PAYOUT_REQUESTS: {
      const oldList = { ...state?.payoutRequestsList?.data };
      return {
        ...state,
        payoutRequestsList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_PAYOUT_REQUESTS: {
      let oldList = { ...state?.payoutRequestsList?.data };
      if (oldList?.data?.length > 0) {
        oldList["data"] = [...oldList?.data, ...action?.payload?.data];
      } else {
        oldList = action.payload;
      }
      return {
        ...state,
        payoutRequestsList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_PAYOUT_REQUESTS: {
      return {
        ...state,
        payoutRequestsList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_REFERRAL_EARNINGS: {
      let oldLidt = { ...state?.referralEarningsList?.data };
      return {
        ...state,
        referralEarningsList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldLidt : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_REFERRAL_EARNINGS: {
      let oldList = { ...state?.referralEarningsList?.data };
      if (oldList?.data?.length > 0) {
        oldList["data"] = [...oldList?.data, ...action?.payload?.data];
      } else {
        oldList = action?.payload;
      }
      return {
        ...state,
        referralEarningsList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_GET_REFERRAL_EARNINGS: {
      return {
        ...state,
        referralEarningsList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_MANUAL_PAYOUT: {
      return {
        ...state,
        manualPayoutData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_MANUAL_PAYOUT: {
      let oldList = { ...state?.payoutRequestsList?.data };
      if (oldList?.data?.length > 0) {
        oldList["data"] = [action?.payload?.data, ...oldList?.data];
        oldList["count"] = oldList?.count + 1;
      } else {
        oldList["data"] = [action?.payload?.data];
        oldList["count"] = 1;
      }
      return {
        ...state,
        manualPayoutData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        payoutRequestsList: {
          ...state.payoutRequestsList,
          data: oldList,
        },
      };
    }
    case ERROR_MANUAL_PAYOUT: {
      return {
        ...state,
        manualPayoutData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_EARNING_STATISTICS: {
      let oldData = { ...state?.earningStatistics?.data };
      return {
        ...state,
        earningStatistics: {
          isLoading: action?.refresh ? false : true,
          data: action?.refresh ? oldData : {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_EARNING_STATISTICS: {
      return {
        ...state,
        earningStatistics: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_EARNING_STATISTICS: {
      return {
        ...state,
        earningStatistics: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_SUBMIT_W9_FORM: {
      return {
        ...state,
        submitW9FormData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }

    case SUCCESS_SUBMIT_W9_FORM: {
      return {
        ...state,
        submitW9FormData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SUBMIT_W9_FORM: {
      return {
        ...state,
        submitW9FormData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_DOWNLOAD_W9_FORM: {
      return {
        ...state,
        downloadW9FormData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_DOWNLOAD_W9_FORM: {
      const { pdf_access_url } = action.payload.data;

      if (pdf_access_url) {
        window.open(pdf_access_url);
      }

      return {
        ...state,
        downloadW9FormData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_DOWNLOAD_W9_FORM: {
      return {
        ...state,
        downloadW9FormData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_SUBMIT_W8_FORM: {
      return {
        ...state,
        submitW8FormData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SUBMIT_W8_FORM: {
      return {
        ...state,
        submitW8FormData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_SUBMIT_W8_FORM: {
      return {
        ...state,
        submitW8FormData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    default: {
      return {
        ...(state || initialState),
      };
    }
  }
};
