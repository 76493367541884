import clsx from "clsx";
import React from "react";
import { ReactComponent as MainLogo } from "assets/Logos/MainLogo.svg";
import { Grid, makeStyles } from "Components/Common/MuiComponents";

const useStyle = makeStyles((theme) => ({
  YhSplashScreen: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&>svg": {
      width: "700px",
      height: "208px",
      [theme.breakpoints.down("md")]: {
        width: "500px",
        height: "180px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "300px",
        height: "105px",
      },
    },
  },
}));

const SplashScreen = () => {
  const classes = useStyle();
  return (
    <>
      <Grid className={clsx(classes.YhSplashScreen)}>
        <MainLogo className={"splashAnimation"} />
      </Grid>
    </>
  );
};

export default SplashScreen;
