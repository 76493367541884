import { SET_VAULT_CONFIG_DATA } from "Actions/VaultAction";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleObjInArray } from "utils/generalUtils";

export const useVaultStore = () => {
  const dispatch = useDispatch();
  let vaultConfig = useSelector((state) => state?.vault?.vaultConfig);
  let vaultMediaList = useSelector((state) => state?.vault?.vaultMediaList);

  vaultMediaList = useMemo(() => vaultMediaList, [vaultMediaList]);
  vaultConfig = useMemo(() => vaultConfig, [vaultConfig]);

  const handleUpdateState = (key, value, options = {}) => {
    if (key == "vault") {
      dispatch({
        type: SET_VAULT_CONFIG_DATA,
        payload: {
          vaultMedia: {
            ...vaultConfig?.vaultMedia,
            ...value,
          },
        },
      });
    } else if (key == "vaultDialog") {
      dispatch({
        type: SET_VAULT_CONFIG_DATA,
        payload: {
          vaultDialog: {
            ...vaultConfig?.vaultDialog,
            ...value,
          },
        },
      });
    } else if (key == "category") {
      dispatch({
        type: SET_VAULT_CONFIG_DATA,
        payload: {
          category: {
            ...vaultConfig?.category,
            ...value,
          },
        },
      });
    } else if (key == "filter") {
      dispatch({
        type: SET_VAULT_CONFIG_DATA,
        payload: {
          selectedFilter: {
            ...vaultConfig?.selectedFilter,
            ...value,
          },
        },
      });
    } else if (key == "media") {
      let finalArr;
      if (options?.clear) {
        finalArr = [];
      } else if (options.all) {
        let allMedia = [];
        vaultMediaList?.data?.list
          ?.filter(
            (row) =>
              !vaultConfig?.selectedMedia?.some(
                (media) => media?.id == row?.vault_media?.id
              )
          )
          ?.map((item) =>
            allMedia.push({
              id: item?.vault_media?.id,
              vault_ids: item?.vault_media?.vault_media_media_list?.map(
                (item) => item?.vault_id
              ),
            })
          );
        finalArr = [...vaultConfig?.selectedMedia, ...allMedia];
      } else if (options?.filter) {
        let arr = [...vaultConfig?.selectedMedia];
        arr = arr.filter((item) => !item?.vault_ids?.includes(value?.vault_id));
        finalArr = arr;
      } else {
        finalArr = toggleObjInArray(vaultConfig?.selectedMedia, value);
      }
      dispatch({
        type: SET_VAULT_CONFIG_DATA,
        payload: {
          selectedMedia: finalArr,
        },
      });
    } else if (key == "use") {
      dispatch({
        type: SET_VAULT_CONFIG_DATA,
        payload: {
          mediaToUsed: options?.clear
            ? []
            : [
                ...(vaultConfig?.selectedMedia?.map((item) => item?.id) || []),
                ...(value?.mediaToUsed || []),
              ],
        },
      });
    }
  };

  return {
    handleUpdateState,
    vaultConfig,
  };
};
