import { all } from "redux-saga/effects";
import { CardWatcher } from "./CardSaga";
import { ChatWatcher } from "./ChatSaga";
import { globalProfileWatcher } from "./GlobalProfileSaga";
import { ListWatcher } from "./ListSaga";
import { NotificationWatcher } from "./NotificationSaga";
import { PayoutWatcher } from "./PayoutSaga";
import { PostWatcher } from "./PostSaga";
import { SearchWatcher } from "./SearchSaga";
import { SettingsWatcher } from "./SettingsSaga";
import { StatementWatcher } from "./StatementSaga";
import { StreamWatcher } from "./StreamSaga";
import { SubscriptionWatcher } from "./SubscriptionSaga";
import { UserWatcher } from "./UserSaga";
import { ReleaseFormWatcher } from "./ReleaseFormSaga";
import { CommonWatcher } from "./CommonSaga";
import { StatisticsWatcher } from "./StatisticsSaga";
import { QueueWatcher } from "./QueueSaga";
import { VaultWatcher } from "./VaultSaga";
import { CollabPostsWatcher } from "./CollabPostsSaga";
import { AgencyWatcher } from "./AgencySaga";

export default function* RootSaga() {
  yield all([
    UserWatcher(),
    PostWatcher(),
    ChatWatcher(),
    NotificationWatcher(),
    StreamWatcher(),
    StatementWatcher(),
    SearchWatcher(),
    CardWatcher(),
    SubscriptionWatcher(),
    PayoutWatcher(),
    globalProfileWatcher(),
    SettingsWatcher(),
    ListWatcher(),
    ReleaseFormWatcher(),
    CommonWatcher(),
    StatisticsWatcher(),
    QueueWatcher(),
    VaultWatcher(),
    CollabPostsWatcher(),
    AgencyWatcher()
  ]);
}
