import moment from "moment";
import socketIOClient from "socket.io-client";
import API from "../service";
import VisaCard from "assets/images/VisaCard.png";
import MasterCard from "assets/images/MasterCard.png";
import MaestroCard from "assets/images/MaestroCard.png";
import DiscoverCard from "assets/images/DiscoverCard.png";
import JcbCard from "assets/images/JcbCard.png";
import DinersClub from "assets/images/DinersClub.png";
import AmericanExpress from "assets/images/AmericanExpress.png";
import axios from "axios";
import momenttz from "moment-timezone";
import _ from "underscore";

/**
 * @author Vijay ahir
 * @param {String} cname
 * @param {String} value
 * @param {Object} options
 * @description Sets cookie with name and value
 */
export const setCookie = (name, value, options = {}) => {
  const now = new Date();
  now.setFullYear(now.getFullYear() + 1);
  return (document.cookie = `${name}=${value}; expires=${now?.toUTCString()}`);
};

/**
 * @author Vijay ahir
 * @param {String} cname
 * @returns specific cookit
 */
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
/**
 * @author Vijay ahir
 * @returns theme mode from localstorage
 */
export const getThemeMode = () => {
  return localStorage.getItem("themeMode");
};

export const protectedRoute = [
  "login",
  "signup",
  "reset-password",
  "two-step-auth",
];
export const allowPublicOnce = ["setting"];

/**
 * @author Vijay ahir
 * @param {object} params
 * @returns sanitized object based on sanitizeOnly, excludeSanitize, disableSanitize
 * @description when you pass sanitizeOnly array with params, sanitization only perform on those keys pass in sanitizeOnly array
 * @description when you pass excludeSanitize array with params, sanitization only perform on all params excluding keys in excludeSanitize array
 * @description when you pass disableSanitize boolean with params, sanitization is prevented for that
 * @description sanitization, excludeSanitize and disableSanitize will be remove from the params
 *
 */

export const sanitizeApiParams = (params) => {
  // example of the XXR script
  // `Hello, <img src="http://unsplash.it/100/100?random" onload="alert('you got hacked');" />`

  // param.excludeSanitize = ["hasc_description"];
  // param.sanitizeOnly = ["hasc_description"];
  // param.disableSanitize = true;
  let newParams = params;

  let whiteList = [];
  let blackList = [];

  if (params?.disableSanitize) {
    delete params?.disableSanitize;
    return params;
  }

  if (params?.sanitizeOnly && Array.isArray(params?.sanitizeOnly)) {
    whiteList = params.sanitizeOnly;
    delete params?.sanitizeOnly;
  } else if (
    params?.excludeSanitize &&
    Array.isArray(params?.excludeSanitize)
  ) {
    blackList = params.excludeSanitize;
    delete params?.excludeSanitize;
  }

  const handleSanitize = (key, value) => {
    if (blackList.length) {
      if (!blackList?.includes(key)) {
        return sanitizeApiParams(value);
      }
      return value;
    } else if (whiteList.length) {
      if (whiteList?.includes(key)) {
        return sanitizeApiParams(value);
      }
      return value;
    } else {
      return sanitizeApiParams(value);
    }
  };

  if (typeof newParams === "string") {
    // this will remove call and event is and script if any
    // use npm i dompurify
    // newParams = DOMPurify.sanitize(newParams);

    // this will remove whole html tag
    var targetDiv = document.createElement("div");
    targetDiv.innerHTML = newParams;
    let data = (targetDiv.textContent || targetDiv.innerText).trim();
    targetDiv.remove();
    return data;
  } else if (typeof newParams === "boolean") {
  } else if (Array.isArray(newParams)) {
    newParams = newParams.map((i) => sanitizeApiParams(i));
  } else if (newParams instanceof File) {
  } else if (newParams instanceof FormData) {
    var formData = new FormData();
    newParams.forEach((value, key) => {
      formData.append(key, handleSanitize(key, value));
    });
    return formData;
  } else if (typeof newParams === "object") {
    Object.keys(newParams || {}).map((key, index) => {
      newParams[key] = handleSanitize(key, newParams[key]);
    });
  }

  return newParams;
};

/**
 * @author Vijay ahir
 * @param {String} name
 * @returns Acronym | ex. name ='vijay ahir', result = 'VI'
 */
export const getAcronym = (name) => {
  return name
    ? name?.trim().split(/\s+/)?.length > 1
      ? name?.trim().split(/\s+/)[0]?.[0]?.toUpperCase() +
        name?.trim().split(/\s+/)[1]?.[0]?.toUpperCase()
      : name?.trim().slice(0, 2).toUpperCase()
    : "";
};

/**
 * @author Vijay ahir
 * @param {Object} obj
 * @returns remove {}, [], "", null, undefined value form the object
 */
export const getClearObject = (obj = {}) => {
  let newObj = {};
  Object.keys(obj).map((key) => {
    let item = obj[key];
    if (typeof item === "boolean") {
      newObj[key] = item;
    } else if (typeof item === "number") {
      newObj[key] = item;
    } else if (typeof item === "string") {
      if (item) {
        newObj[key] = item;
      }
    } else if (Array.isArray(item)) {
      if (item.length) {
        newObj[key] = item;
      }
    } else if (typeof item === "object") {
      // if object is type of date
      if (item instanceof Date) {
        if (!isNaN(item)) {
          newObj[key] = item;
        }
      } else if (Object?.keys(item || {})?.length) {
        newObj[key] = item;
      }
    }
  });

  return newObj;
};

/**
 *
 * @param {number} number
 * @param {number} index
 * @param {number} totalSec
 * @returns
 */
export const localeTimeFormate = function (number, index, totalSec) {
  return [
    ["just now", "right now"],
    ["%s seconds ago", "in %s seconds"],
    ["1 minute ago", "in 1 minute"],
    ["%s minutes ago", "in %s minutes"],
    ["1 hour ago", "in 1 hour"],
    ["%s hours ago", "in %s hours"],
    ["1 day ago", "in 1 day"],
    ["%s days ago", "in %s days"],
    ["1 week ago", "in 1 week"],
    ["%s weeks ago", "in %s weeks"],
    ["1 month ago", "in 1 month"],
    ["%s months ago", "in %s months"],
    ["1 year ago", "in 1 year"],
    ["%s years ago", "in %s years"],
  ][index];
};

export const massMessageTimeFormat = function (number, index, totalSec) {
  return [
    ["seconds ago", "right now"],
    ["seconds ago", "in %s seconds"],
    ["minute ago", "in 1 minute"],
    ["%s minutes ago", "in %s minutes"],
    ["1 hour ago", "in 1 hour"],
    ["%s hours ago", "in %s hours"],
    ["1 day ago", "in 1 day"],
    ["%s days ago", "in %s days"],
    ["1 week ago", "in 1 week"],
    ["%s weeks ago", "in %s weeks"],
    ["1 month ago", "in 1 month"],
    ["%s months ago", "in %s months"],
    ["1 year ago", "in 1 year"],
    ["%s years ago", "in %s years"],
  ][index];
};

/**
 *
 * @param {String} url
 * @param {variable} variables
 * @returns {String} updated url
 * @description this method take url and replace inner url with giving variable object
 */
export const generateDynamicEndpoint = (url, variables = {}) => {
  Object.keys(variables).map((i) => {
    url = url.replace(`{${i}}`, variables[i]);
  });
  return url;
};

export const googleTranslateLanguageHandler = (lan) => {
  if (lan) {
    localStorage.setItem("userLanguage", lan);
    document.cookie = `googtrans=/auto/${lan};path=/`;
  } else {
    const language = localStorage.getItem("userLanguage");
    if (language) {
      document.cookie = `googtrans=/auto/${language};path=/`;
    } else {
      localStorage.setItem("userLanguage", "en");
      document.cookie = `googtrans=/auto/en;path=/`;
    }
  }
};

export const convertUrl = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });
};

/**
 * Check is empty object
 *
 * @return {boolean}
 * @author Akshay Mahajan
 */
export function isEmptyObject(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

/**
 * Get URL parameters
 *
 * @return {object}
 * @author Akshay Mahajan
 */
export function getUrlParams() {
  const params = new URLSearchParams(window.location.search);
  return Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
}

/**
 * Get appended url params
 *
 * @return {object}
 * @author Akshay Mahajan
 */
export function getAppendUrlParams(_object) {
  const params = new URLSearchParams(window.location.search);
  const prevParams = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
  return {
    ...prevParams,
    ..._object,
  };
}

/**
 * Get removed url params
 *
 * @return {object}
 * @author Akshay Mahajan
 */
export function getRemovedUrlParam(arrayOfKey) {
  let params = new URLSearchParams(window.location.search);
  for (let i = 0; i < arrayOfKey.length; i++) {
    params.delete(arrayOfKey[i]);
  }
  return Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
}

/**
 *
 * @param {base64} url
 * @param {String} filename
 * @param {String} mimeType
 * @returns File Object from base64 data
 */
export async function urltoFile(
  url,
  filename = "user_img.jpeg",
  mimeType = "image/jpeg"
) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

let chatDate = "";
let todayDate = moment().format("DD MMM yyyy");
let yesterdayDate = moment().add(-1, "days").format("DD MMM yyyy");
export function getDayWiseDate(date) {
  const currentDate = moment(date).format("DD MMM yyyy");
  if (chatDate === "") {
    chatDate = currentDate;
    if (todayDate === currentDate) {
      return "Today";
    } else if (yesterdayDate === currentDate) {
      return "Yesterday";
    } else {
      return chatDate;
    }
  } else {
    if (chatDate !== currentDate) {
      chatDate = currentDate;
      if (todayDate === currentDate) {
        return "Today";
      } else if (yesterdayDate === currentDate) {
        return "Yesterday";
      } else {
        return chatDate;
      }
    } else {
      return null;
    }
  }
}

export const cardTypes = {
  visa: VisaCard,
  mastercard: MasterCard,
  "american-express": AmericanExpress,
  "diners-club": DinersClub,
  discover: DiscoverCard,
  jcb: JcbCard,
  maestro: MaestroCard,
};

export function parseAmount(amt = 0, val = 2) {
  let newAmt = amt?.toString();
  let indexOfAmt = newAmt?.indexOf(".");
  newAmt =
    indexOfAmt == -1
      ? newAmt
      : newAmt.slice(0, (indexOfAmt != -1 ? indexOfAmt : 0) + val + 1);
  newAmt = newAmt?.split(".")?.length > 1 ? Number(newAmt)?.toFixed(2) : newAmt;
  return Number(newAmt);
  // return newAmt;
}

export const calculatePercentage = (price, duration, discount) => {
  return parseAmount(price * duration - price * duration * (discount * 0.01));
};

export const replaceIfSameInArray = (array, newItem) => {
  let foundIndex = -1;
  for (let i = 0; i < array.length; i++) {
    if (array[i].key === newItem.key) {
      foundIndex = i;
      break;
    }
  }
  if (foundIndex !== -1) {
    array[foundIndex] = newItem;
  } else {
    array.push(newItem);
  }
  return array;
};

export const stopStreamp = () => {
  window.videoStream?.getTracks()?.map((track) => track.stop());
  window.audioStream?.getTracks()?.map((track) => track.stop());
  window?.broadcastClient?.stopBroadcast();
  window.isBroadcasting = false;
};

export const getFileName = (file, type) => {
  return Date.now() + file?.name;
};

export const getFilePartsSize = (file, partSize = 5) => {
  return Math.ceil(file.size / (1024 * 1024 * partSize));
};

export const toggleItemInArray = (arr = [], item) => {
  let temp = arr;
  const index = temp.indexOf(item);
  if (index === -1) {
    temp.push(item);
  } else {
    temp.splice(index, 1);
  }
  return temp;
};

export const toggleObjInArray = (arr = [], value, key = "id") => {
  let temp = arr;
  if (temp?.some((item) => item?.[key] == value?.[key])) {
    temp = temp?.filter((item) => item?.[key] != value?.[key]);
  } else {
    temp.push(value);
  }
  return temp;
};

export const getFileType = (file) => {
  const fileType = file?.type?.split("/")?.[0];
  switch (fileType || "") {
    case "image":
      return "image";
    case "video":
      return "video";
    case "audio":
      return "audio";
    case "application":
      switch (file?.type?.split("/")?.[1] || "") {
        case "pdf":
          return "pdf";
        default:
          return "unknown";
      }
    default:
      return "unknown";
  }
};

export const getTypewiseReleaseForms = ({
  releaseForms = [],
  exclude = [],
}) => {
  let rfDoc = [],
    rfLink = [],
    rfTag = [],
    rfGuest = [];
  if (exclude?.length)
    releaseForms = releaseForms?.filter((item) => !exclude.includes(item?.id));
  for (const tag of releaseForms) {
    if (tag?.type === "doc") {
      rfDoc.push(tag?.id);
    } else if (tag?.type === "link") {
      rfLink.push(tag?.id);
    } else if (tag?.type === "user") {
      rfTag.push(tag?.id);
    } else if (tag?.type === "guest") {
      rfGuest.push(tag?.id);
    }
  }
  return {
    rfDoc,
    rfGuest,
    rfLink,
    rfTag,
  };
};

export const myIpAndCountryDetail = async () => {
  return await fetch("https://geolocation-db.com/json/")
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const isMobileNumberExists = (text = "") => {
  let mobileRegex = /\b\+?\d{1,3}(?:\s?\d{9,13})\b/;
  return mobileRegex.test(text?.trim());
};

export const isEmailExists = (text = "") => {
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(text?.trim());
};

export const isEmailOrPhoneExists = (text) => {
  return isMobileNumberExists(text) || isEmailExists(text);
};

export const replaceNumberWithAstrict = (number = "") => {
  return number?.toString().replace(/\d(?=\d{4})/g, "*");
};

/**
 *
 * @param {String} text
 * @returns String after removing all element tags so that can not create issue while no need to show or wrap the html tags
 */
export const removeHtmlElementsFromString = (text) => {
  return (text || "").replace(/<[^>]+>/g, "");
};

export const getMinutesAndSecondsFromSeconds = (s = 0) => {
  s = s > 0 ? 0 : Math.abs(s);
  let minutes = Math.floor(s / 60);
  let seconds = Math.floor(s % 60);
  return { min: minutes, sec: seconds };
};

export const getUsersUtcOffset = () => {
  const userTimezone = momenttz.tz.guess();
  const utcOffset = momenttz.tz(userTimezone).format("Z");
  return utcOffset;
};

// Set value at specific path in object
export function setValueFromPath(data, path, value) {
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");
  var a = path.split(".");
  var o = data;
  while (a.length - 1) {
    var n = a.shift();
    if (!(n in o)) o[n] = {};
    o = o[n];
  }
  o[a[0]] = value;
}
