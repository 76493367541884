import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  START_GET_SIGNED_RELEASE_FORM,
  SUCCESS_GET_SIGNED_RELEASE_FORM,
  ERROR_GET_SIGNED_RELEASE_FORM,
  START_SUBMIT_SIGNED_RELEASE_FORM,
  SUCCESS_SUBMIT_SIGNED_RELEASE_FORM,
  ERROR_SUBMIT_SIGNED_RELEASE_FORM,
  START_UPDATE_SIGNED_RELEASE_FORM,
  SUCCESS_UPDATE_SIGNED_RELEASE_FORM,
  ERROR_UPDATE_SIGNED_RELEASE_FORM,
  START_UPDATE_RF_DEFAULT_VISIBILITY,
  SUCCESS_UPDATE_RF_DEFAULT_VISIBILITY,
  ERROR_UPDATE_RF_DEFAULT_VISIBILITY,
  START_CREATE_INVITAION_LINK,
  START_UPDATE_INVITATION_LINK,
  START_UPDATE_LINK_VISIBILITY,
  START_GET_TAGGED_USERS,
  SUCCESS_CREATE_INVITATION_LINK,
  ERROR_CREATE_INVITAION_LINK,
  SUCCESS_UPDATE_INVITATION_LINK,
  ERROR_UPDATE_INVITATION_LINK,
  SUCCESS_UPDATE_LINK_VISIBILITY,
  ERROR_UPDATE_LINK_VISIBILITY,
  SUCCESS_GET_TAGGED_USERS,
  ERROR_GET_TAGGED_USERS,
  START_VERIFY_GUEST_TOKEN,
  SUCCESS_VERIFY_GUEST_TOKEN,
  ERROR_VERIFY_GUEST_TOKEN,
} from "Actions/ReleaseFormAction";
import {
  postSignedReleaseForm,
  getSignedReleaseForm,
  updateSignedReleaseForm,
  updateRFDefaultVisibility,
  createInvitationLinkAPI,
  updateInvitationLinkAPI,
  updateLinkVisibilityAPI,
  getTaggedUsersAPI,
  verifyGuestTokenAPI,
} from "Api/ReleaseFormApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* getSignedReleaseFormWorker({ payload }) {
  try {
    const res = yield call(getSignedReleaseForm, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_SIGNED_RELEASE_FORM,
        payload: { ...res, ...payload },
      });
    } else {
      yield put({
        type: ERROR_GET_SIGNED_RELEASE_FORM,
        payload: { ...res, ...payload },
      });
    }
  } catch (error) {}
}

function* postSignedReleaseFormWorker({ payload }) {
  try {
    const res = yield call(postSignedReleaseForm, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SUBMIT_SIGNED_RELEASE_FORM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SUBMIT_SIGNED_RELEASE_FORM,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateSignedReleaseFormWorker({ id, payload }) {
  try {
    const res = yield call(updateSignedReleaseForm, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_SIGNED_RELEASE_FORM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_SIGNED_RELEASE_FORM,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateRFDefaultVisibilityWorker({ payload }) {
  try {
    const res = yield call(updateRFDefaultVisibility, {
      release_form_id: payload?.id,
    });
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_RF_DEFAULT_VISIBILITY,
        payload: {
          ...res,
          data: payload,
        },
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: payload?.hidden_for_release_form
            ? "Successfully back to default list"
            : "Successfully removed from default list",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_RF_DEFAULT_VISIBILITY,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createInvitationLinkWorker({ payload }) {
  try {
    const res = yield call(createInvitationLinkAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_INVITATION_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CREATE_INVITAION_LINK,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateInvitationLinkWorker({ id, payload }) {
  try {
    const res = yield call(updateInvitationLinkAPI, id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_INVITATION_LINK,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_INVITATION_LINK,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateLinkVisibilityWorker({ payload }) {
  try {
    const res = yield call(updateLinkVisibilityAPI, { id: payload?.id });
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_LINK_VISIBILITY,
        payload: { ...res, data: payload },
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: payload?.hidden_for_default_list
            ? "Successfully back to default list"
            : "Successfully removed from default list",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_LINK_VISIBILITY,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getTaggedUsersWorker({ payload }) {
  try {
    const res = yield call(getTaggedUsersAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_TAGGED_USERS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_TAGGED_USERS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* verifyGuestTokenWorker({ payload }) {
  try {
    const res = yield call(verifyGuestTokenAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_VERIFY_GUEST_TOKEN,
        payload: res,
        verifyType: payload?.type,
      });
    } else {
      yield put({
        type: ERROR_VERIFY_GUEST_TOKEN,
        payload: res,
        verifyType: payload?.type,
      });
    }
  } catch (error) {}
}

export function* ReleaseFormWatcher() {
  yield takeEvery(START_GET_SIGNED_RELEASE_FORM, getSignedReleaseFormWorker);
  yield takeEvery(
    START_SUBMIT_SIGNED_RELEASE_FORM,
    postSignedReleaseFormWorker
  );
  yield takeEvery(
    START_UPDATE_SIGNED_RELEASE_FORM,
    updateSignedReleaseFormWorker
  );
  yield takeEvery(
    START_UPDATE_RF_DEFAULT_VISIBILITY,
    updateRFDefaultVisibilityWorker
  );
  yield takeEvery(START_CREATE_INVITAION_LINK, createInvitationLinkWorker);
  yield takeEvery(START_UPDATE_INVITATION_LINK, updateInvitationLinkWorker);
  yield takeEvery(START_UPDATE_LINK_VISIBILITY, updateLinkVisibilityWorker);
  yield takeEvery(START_GET_TAGGED_USERS, getTaggedUsersWorker);
  yield takeEvery(START_VERIFY_GUEST_TOKEN, verifyGuestTokenWorker);
}
