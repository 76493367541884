import {
  ERROR_ADD_CARD,
  ERROR_AUTO_RECHARGE_WALLET,
  ERROR_DELETE_CARD,
  ERROR_GET_CARD_LIST,
  ERROR_GET_LATEST_TRANSACTION,
  ERROR_GET_MY_WALLET,
  ERROR_GET_TRANSACTIONS,
  ERROR_MAKE_WALLET_PRIMARY,
  ERROR_SEND_TIP,
  ERROR_SET_DEFAULT_CARD,
  ERROR_TOPUP_MY_WALLET,
  ERROR_UPDATE_CARD_ADDRESS,
  START_ADD_CARD,
  START_AUTO_RECHARGE_WALLET,
  START_DELETE_CARD,
  START_GET_CARD_LIST,
  START_GET_LATEST_TRANSACTION,
  START_GET_MY_WALLET,
  START_GET_TRANSACTIONS,
  START_MAKE_WALLET_PRIMARY,
  START_SEND_TIP,
  START_SET_DEFAULT_CARD,
  START_TOPUP_MY_WALLET,
  START_UPDATE_CARD_ADDRESS,
  SUCCESS_ADD_CARD,
  SUCCESS_AUTO_RECHARGE_WALLET,
  SUCCESS_DELETE_CARD,
  SUCCESS_GET_CARD_LIST,
  SUCCESS_GET_LATEST_TRANSACTION,
  SUCCESS_GET_MY_WALLET,
  SUCCESS_GET_TRANSACTIONS,
  SUCCESS_MAKE_WALLET_PRIMARY,
  SUCCESS_SEND_TIP,
  SUCCESS_SET_DEFAULT_CARD,
  SUCCESS_TOPUP_MY_WALLET,
  SUCCESS_UPDATE_CARD_ADDRESS,
} from "Actions/CardAction";
import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  addCardAPI,
  autoRechargeWalletAPI,
  deleteCardAPI,
  getCardListAPI,
  getLatestTransactionAPI,
  getTransactionsAPI,
  getWalletAPI,
  makeWalletPrimaryAPI,
  sendTipAPI,
  setDefaultCardAPI,
  topupMyWalletAPI,
  updateCardAPI,
} from "Api/CardApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* addCardWorker({ payload }) {
  try {
    const res = yield call(addCardAPI, payload);
    if (res?.status) {
      yield put({
        type: SUCCESS_ADD_CARD,
        payload: res,
      });
    } else {
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message,
          type: "error",
        },
      });
      yield put({
        type: ERROR_ADD_CARD,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getCardListWorker({ payload }) {
  try {
    const res = yield call(getCardListAPI, payload);
    if (res?.status) {
      yield put({
        type: SUCCESS_GET_CARD_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_CARD_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deleteCardWorker({ card_id, is_default }) {
  try {
    const res = yield call(deleteCardAPI, card_id, is_default);
    if (res?.status) {
      yield put({
        type: SUCCESS_DELETE_CARD,
        payload: res,
        card_id: card_id,
        is_default: is_default,
      });
    } else {
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message,
          type: "error",
        },
      });
      yield put({
        type: ERROR_DELETE_CARD,
        payload: res,
      });
    }
  } catch (error) {}
}

function* setDefaultCardWorker({ card_id }) {
  try {
    const res = yield call(setDefaultCardAPI, card_id);
    if (res.status) {
      yield put({
        type: SUCCESS_SET_DEFAULT_CARD,
        payload: res,
        id: card_id,
      });
    } else {
      yield put({
        type: ERROR_SET_DEFAULT_CARD,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateCardAddressWorker({ card_id, payload }) {
  try {
    const res = yield call(updateCardAPI, card_id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_CARD_ADDRESS,
        payload: res,
        data: payload,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_CARD_ADDRESS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* makeWalletPrimaryWorker({ payload }) {
  try {
    const res = yield call(makeWalletPrimaryAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_MAKE_WALLET_PRIMARY,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved",
          type: "success",
        },
      });
    } else {
      yield put({
        tyep: ERROR_MAKE_WALLET_PRIMARY,
        payload: res,
      });
    }
  } catch (error) {}
}

function* autoRechargeWalletWorker({ payload }) {
  try {
    const res = yield call(autoRechargeWalletAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_AUTO_RECHARGE_WALLET,
        payload: res,
        data: payload,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_AUTO_RECHARGE_WALLET,
        payload: res,
      });
    }
  } catch (error) {}
}

function* sendTipWorker({ payload, user_id }) {
  try {
    const res = yield call(sendTipAPI, payload, user_id);
    if (res.status) {
      yield put({
        type: SUCCESS_SEND_TIP,
        payload: res,
        data: payload,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Tip was sent successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_SEND_TIP,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message ||
            "Transaction can not be procced please try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getMyWalletWorker() {
  try {
    const res = yield call(getWalletAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_MY_WALLET,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_MY_WALLET,
        payload: res,
      });
    }
  } catch (error) {}
}

function* topupMyWalletWorker({ payload }) {
  try {
    const res = yield call(topupMyWalletAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_TOPUP_MY_WALLET,
        payload: res,
        amount: payload?.amount,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: `Your wallet has been topped up with $${payload?.amount}`,
        },
      });
    } else {
      yield put({
        type: ERROR_TOPUP_MY_WALLET,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong. Please try after sometime.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getTransactionsWorker({ payload }) {
  try {
    const res = yield call(getTransactionsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_TRANSACTIONS,
        payload: res,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_TRANSACTIONS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getLatestTransactionWorker({ payload, refresh }) {
  try {
    const res = yield call(getLatestTransactionAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_LATEST_TRANSACTION,
        payload: res,
        refresh: refresh,
      });
    } else {
      yield put({
        tyep: ERROR_GET_LATEST_TRANSACTION,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* CardWatcher() {
  yield takeEvery(START_ADD_CARD, addCardWorker);
  yield takeEvery(START_GET_CARD_LIST, getCardListWorker);
  yield takeEvery(START_DELETE_CARD, deleteCardWorker);
  yield takeEvery(START_SET_DEFAULT_CARD, setDefaultCardWorker);
  yield takeEvery(START_UPDATE_CARD_ADDRESS, updateCardAddressWorker);
  yield takeEvery(START_MAKE_WALLET_PRIMARY, makeWalletPrimaryWorker);
  yield takeEvery(START_AUTO_RECHARGE_WALLET, autoRechargeWalletWorker);
  yield takeEvery(START_SEND_TIP, sendTipWorker);
  yield takeEvery(START_GET_MY_WALLET, getMyWalletWorker);
  yield takeEvery(START_TOPUP_MY_WALLET, topupMyWalletWorker);
  yield takeEvery(START_GET_TRANSACTIONS, getTransactionsWorker);
  yield takeEvery(START_GET_LATEST_TRANSACTION, getLatestTransactionWorker);
}
