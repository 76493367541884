import {
  START_GET_MEMBERS,
  SUCCESS_GET_MEMBERS,
  ERROR_GET_MEMBERS,
  START_INVITE_MEMBERS,
  SUCCESS_INVITE_MEMBERS,
  ERROR_INVITE_MEMBERS,
  START_SUBMIT_INVITE_RESPONSE,
  SUCCESS_SUBMIT_INVITE_RESPONSE,
  ERROR_SUBMIT_INVITE_RESPONSE,
  START_REMOVE_MEMBERS,
  SUCCESS_REMOVE_MEMBERS,
  ERROR_REMOVE_MEMBERS,
  START_EDIT_MEMBER,
  SUCCESS_EDIT_MEMBER,
  ERROR_EDIT_MEMBER,
} from "Actions/AgencyAction";

import {
  createAgencyInvitesAPI,
  getAgencyMembersAPI,
  submitAgencyInviteResponseAPI,
  removeAgencyMemberAPI,
  editAgencyMemberAPI,
} from "Api/AgencyApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* getAgencyMembersWorker({ payload }) {
  try {
    const response = yield call(getAgencyMembersAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_MEMBERS,
        payload: response,
        offset: payload?.offset,
      });
    } else {
      yield put({
        type: ERROR_GET_MEMBERS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* createAgencyInvitesWorker({ payload }) {
  try {
    const response = yield call(createAgencyInvitesAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_INVITE_MEMBERS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_INVITE_MEMBERS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* submitAgencyInviteResponseWorker({ payload, onSuccess = ()=>{}, onError = ()=>{} }) {
  try {
    const response = yield call(submitAgencyInviteResponseAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_SUBMIT_INVITE_RESPONSE,
        payload: response,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_SUBMIT_INVITE_RESPONSE,
        payload: response,
      });
      onError(response)
    }
  } catch (error) {
    onError(error)
  }
}

function* removeAgencyMemberWorker({ payload }) {
  try {
    const response = yield call(removeAgencyMemberAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_REMOVE_MEMBERS,
        payload: {...payload , status : true},
      });
    } else {
      yield put({
        type: ERROR_REMOVE_MEMBERS,
        payload: response,
      });
    }
  } catch (error) {console.log(error)}
}

function* editAgencyMemberWorker({ payload , onSuccess = () => {}}) {
  try {
    const response = yield call(editAgencyMemberAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_EDIT_MEMBER,
        payload: {...payload , status : true},
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_EDIT_MEMBER,
        payload: response,
      });
    }
  } catch (error) {console.log(error)}
}

export function* AgencyWatcher() {
  yield takeEvery(START_GET_MEMBERS, getAgencyMembersWorker);
  yield takeEvery(START_INVITE_MEMBERS, createAgencyInvitesWorker);
  yield takeEvery(
    START_SUBMIT_INVITE_RESPONSE,
    submitAgencyInviteResponseWorker
  );
  yield takeEvery(START_REMOVE_MEMBERS, removeAgencyMemberWorker);
  yield takeEvery(START_EDIT_MEMBER, editAgencyMemberWorker);
}
