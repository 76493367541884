import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_CREATE_NEW_STREAM,
  ERROR_GET_STREAM_BY_ID,
  ERROR_JOIN_LIVE_STREAM,
  ERROR_LEAVE_LIVE_STREAM,
  ERROR_PURCHASE_STREAM,
  ERROR_SHARE_STREAM_AS_POST,
  ERROR_STREAM_STOP,
  ERROR_UPDATE_TIP_GOAL,
  START_CREATE_NEW_STREAM,
  START_GET_STREAM_BY_ID,
  START_JOIN_LIVE_STREAM,
  START_LEAVE_LIVE_STREAM,
  START_PURCHASE_STREAM,
  START_SHARE_STREAM_AS_POST,
  START_STREAM_STOP,
  START_UPDATE_TIP_GOAL,
  SUCCESS_CREATE_NEW_STREAM,
  SUCCESS_GET_STREAM_BY_ID,
  SUCCESS_JOIN_LIVE_STREAM,
  SUCCESS_LEAVE_LIVE_STREAM,
  SUCCESS_PURCHASE_STREAM,
  SUCCESS_SHARE_STREAM_AS_POST,
  SUCCESS_STREAM_STOP,
  SUCCESS_UPDATE_TIP_GOAL,
} from "Actions/StreamAction";
import {
  createNewStreamAPI,
  getStreamByIdAPI,
  joinLiveStreamAPI,
  leaveLiveStreamAPI,
  purchaseStreamAPI,
  shareStreamAsPosAPI,
  stopStreamAPI,
  updateTipGoalAPI,
} from "Api/StreamApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* createNewStreamWorker({ payload }) {
  try {
    const res = yield call(createNewStreamAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_NEW_STREAM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CREATE_NEW_STREAM,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong! Please try again later",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* stopStreamWorker({ payload }) {
  try {
    const res = yield call(stopStreamAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_STREAM_STOP,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_STREAM_STOP,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStreamByIdWorker({ stream_id }) {
  try {
    const res = yield call(getStreamByIdAPI, stream_id);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STREAM_BY_ID,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STREAM_BY_ID,
        payload: res,
      });
    }
  } catch (error) {}
}

function* joinLiveStreamWorker({ stream_id }) {
  try {
    const res = yield call(joinLiveStreamAPI, stream_id);
    if (res.status) {
      yield put({
        type: SUCCESS_JOIN_LIVE_STREAM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_JOIN_LIVE_STREAM,
        payload: res,
      });
    }
  } catch (error) {}
}

function* shareStreamAsPostWorker({ stream_id, payload }) {
  try {
    const res = yield call(shareStreamAsPosAPI, stream_id, payload);

    if (res.status) {
      yield put({
        type: SUCCESS_SHARE_STREAM_AS_POST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SHARE_STREAM_AS_POST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* purchaseStreamWorker({ payload }) {
  try {
    const res = yield call(purchaseStreamAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_PURCHASE_STREAM,
        payload: res,
        data: payload,
      });
    } else {
      yield put({
        type: ERROR_PURCHASE_STREAM,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* leaveLiveStreamWorker({ stream_id }) {
  try {
    const res = yield call(leaveLiveStreamAPI, stream_id);
    if (res.status) {
      yield put({
        type: SUCCESS_LEAVE_LIVE_STREAM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_LEAVE_LIVE_STREAM,
        payload: res,
      });
    }
  } catch (error) {}
}

function* updateTipGoalWorker({ stream_id, payload }) {
  try {
    const res = yield call(updateTipGoalAPI, stream_id, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_UPDATE_TIP_GOAL,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_TIP_GOAL,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* StreamWatcher() {
  yield takeEvery(START_CREATE_NEW_STREAM, createNewStreamWorker);
  yield takeEvery(START_STREAM_STOP, stopStreamWorker);
  yield takeEvery(START_GET_STREAM_BY_ID, getStreamByIdWorker);
  yield takeEvery(START_JOIN_LIVE_STREAM, joinLiveStreamWorker);
  yield takeEvery(START_LEAVE_LIVE_STREAM, leaveLiveStreamWorker);
  yield takeEvery(START_SHARE_STREAM_AS_POST, shareStreamAsPostWorker);
  yield takeEvery(START_PURCHASE_STREAM, purchaseStreamWorker);
  yield takeEvery(START_UPDATE_TIP_GOAL, updateTipGoalWorker);
}
