import {
  START_GET_MY_REFERRALS,
  SUCCESS_GET_MY_REFERRALS,
  ERROR_GET_MY_REFERRALS,
} from "Actions/StatementAction";

const initialState = {
  myReferralsData: {
    isLoading: false,
    data: {},
    error: "",
    referralType: "",
  },
};

export const statementReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case START_GET_MY_REFERRALS: {
      let oldList = { ...state?.myReferralsData?.data };

      return {
        ...state,
        myReferralsData: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
          referralType: action?.payload?.type,
        },
      };
    }
    case SUCCESS_GET_MY_REFERRALS: {
      let oldList = { ...state?.myReferralsData?.data };
      if (
        state?.myReferralsData?.referralType == action?.referalType &&
        oldList?.referred_users?.length > 0
      ) {
        oldList.referred_users = [
          ...oldList?.referred_users,
          ...action?.payload?.referred_users,
        ];
      } else {
        oldList = action?.payload;
      }
      return {
        ...state,
        myReferralsData: {
          isLoading: false,
          data: oldList,
          error: "",
          referralType: action?.referalType,
        },
      };
    }
    case ERROR_GET_MY_REFERRALS: {
      return {
        ...state,
        myReferralsData: {
          isLoading: false,
          data: {},
          error: action.payload,
          referralType: action?.referalType,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
