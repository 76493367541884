import API from "../service";
import {
  GET_COLLAB_POSTS_ENDPOINT,
  TAKE_ACTION_COLLAB_POSTS_ENDPOINT,
} from "./endpoints";

export const getCollabPostsAPI = (payload) => {
  return API.get(GET_COLLAB_POSTS_ENDPOINT, payload);
};

export const collabPostsActionsAPI = (payload) => {
  return API.post(TAKE_ACTION_COLLAB_POSTS_ENDPOINT, payload);
};
