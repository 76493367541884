import {
  RESET_RELEASE_FORM_REDUCER,
  START_SUBMIT_SIGNED_RELEASE_FORM,
  SUCCESS_SUBMIT_SIGNED_RELEASE_FORM,
  ERROR_SUBMIT_SIGNED_RELEASE_FORM,
  ERROR_GET_SIGNED_RELEASE_FORM,
  START_GET_SIGNED_RELEASE_FORM,
  SUCCESS_GET_SIGNED_RELEASE_FORM,
  ERROR_UPDATE_SIGNED_RELEASE_FORM,
  START_UPDATE_SIGNED_RELEASE_FORM,
  SUCCESS_UPDATE_SIGNED_RELEASE_FORM,
  ERROR_UPDATE_RF_DEFAULT_VISIBILITY,
  START_UPDATE_RF_DEFAULT_VISIBILITY,
  SUCCESS_UPDATE_RF_DEFAULT_VISIBILITY,
  START_CREATE_INVITAION_LINK,
  SUCCESS_CREATE_INVITATION_LINK,
  ERROR_CREATE_INVITAION_LINK,
  START_UPDATE_INVITATION_LINK,
  SUCCESS_UPDATE_INVITATION_LINK,
  START_GET_TAGGED_USERS,
  SUCCESS_GET_TAGGED_USERS,
  ERROR_GET_TAGGED_USERS,
  ERROR_UPDATE_INVITATION_LINK,
  START_UPDATE_LINK_VISIBILITY,
  SUCCESS_UPDATE_LINK_VISIBILITY,
  ERROR_UPDATE_LINK_VISIBILITY,
  START_VERIFY_GUEST_TOKEN,
  SUCCESS_VERIFY_GUEST_TOKEN,
  ERROR_VERIFY_GUEST_TOKEN,
} from "Actions/ReleaseFormAction";

const initialState = {
  postSignedReleaseFormData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getSignedReleaseFormData: {
    isLoading: false,
    data: {
      count: 0,
      rows: [],
    },
    error: "",
    status: false,
    offset: 0,
  },
  updateSignedReleaseFormData: {
    isLoading: false,
    data: {},
    error: "",
    status: false,
  },
  updateRFDefaultVisibilityData: {
    isLoading: false,
    data: {},
    error: "",
    status: false,
  },
  createInvitationLinkData: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateInvitationLinkData: {
    isLoading: false,
    data: {},
    error: "",
    status: false,
  },
  updateLinkVisibilityData: {
    isLoading: false,
    data: {},
    error: "",
  },
  getTaggedUsersData: {
    isLoading: false,
    data: {},
    error: "",
  },
  verifyGuestTokenData: {
    isLoading: false,
    data: {},
    error: "",
    confirmed: false,
  },
};

export const releaseFormReducer = (state = initialState, action, root) => {
  switch (action.type) {
    case RESET_RELEASE_FORM_REDUCER: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action.key],
      };
    }
    case START_UPDATE_RF_DEFAULT_VISIBILITY: {
      return {
        ...state,
        updateRFDefaultVisibilityData: {
          isLoading: true,
          data: {},
          error: "",
          status: false,
        },
      };
    }
    case SUCCESS_UPDATE_RF_DEFAULT_VISIBILITY: {
      let obj = { ...state.getSignedReleaseFormData };
      if (
        !action?.payload?.data?.hidden_for_release_form &&
        !action?.payload?.data?.isSearchOpen
      ) {
        obj["data"]["rows"] = obj?.data?.rows?.filter(
          (item) => item?.id !== action.payload?.data?.id
        );
        obj["data"]["count"] = obj?.data?.rows?.length;
      } else {
        obj["data"]["rows"] = obj?.data?.rows?.map((item) =>
          item?.id === action.payload?.data?.id
            ? {
                ...item,
                hidden_for_release_form: !item?.hidden_for_release_form,
              }
            : item
        );
      }
      return {
        ...state,
        updateRFDefaultVisibilityData: {
          ...action.payload,
          isLoading: false,
        },
        getSignedReleaseFormData: obj,
      };
    }
    case ERROR_UPDATE_RF_DEFAULT_VISIBILITY: {
      return {
        ...state,
        updateRFDefaultVisibilityData: {
          isLoading: false,
          data: {},
          error: "",
          status: false,
        },
      };
    }
    case START_UPDATE_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        updateSignedReleaseFormData: {
          isLoading: true,
          data: {},
          error: "",
          status: false,
        },
      };
    }
    case SUCCESS_UPDATE_SIGNED_RELEASE_FORM: {
      let obj = { ...state.getSignedReleaseFormData };
      obj["data"]["rows"] = obj?.data?.rows?.map((item) =>
        item?.id === action.payload?.data?.id
          ? {
              ...item,
              reference_name: action?.payload?.data?.reference_name,
            }
          : item
      );
      return {
        ...state,
        updateSignedReleaseFormData: {
          ...action.payload,
          isLoading: false,
        },
        getSignedReleaseFormData: obj,
      };
    }
    case ERROR_UPDATE_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        updateSignedReleaseFormData: {
          isLoading: false,
          data: {},
          error: "",
          status: false,
        },
      };
    }
    case START_GET_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        getSignedReleaseFormData: {
          ...state.getSignedReleaseFormData,
          ...action.payload,
          data:
            action.payload?.offset === 0
              ? {
                  count: 0,
                  rows: [],
                }
              : state.getSignedReleaseFormData.data,
          isLoading: true,
        },
      };
    }
    case SUCCESS_GET_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        getSignedReleaseFormData: {
          ...action.payload,
          data:
            action.payload?.offset === 0
              ? action.payload?.data
              : {
                  count: state.getSignedReleaseFormData.data.count,
                  rows: state.getSignedReleaseFormData.data.rows.concat(
                    action.payload?.data?.rows
                  ),
                },
          isLoading: false,
        },
      };
    }
    case ERROR_GET_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        getSignedReleaseFormData: {
          ...state.getSignedReleaseFormData,
          ...action.payload,
          isLoading: false,
        },
      };
    }
    case START_SUBMIT_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        postSignedReleaseFormData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_SUBMIT_SIGNED_RELEASE_FORM: {
      const obj = { ...state.getSignedReleaseFormData };
      obj["data"]["rows"] = [action?.payload?.data, ...obj?.data?.rows];
      obj["data"]["count"] = obj?.data?.rows?.length;
      return {
        ...state,
        postSignedReleaseFormData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getSignedReleaseFormData: obj,
      };
    }
    case ERROR_SUBMIT_SIGNED_RELEASE_FORM: {
      return {
        ...state,
        postSignedReleaseFormData: {
          isLoading: false,
          data: {},
          error: "",
        },
      };
    }
    // Tagged Users
    case START_CREATE_INVITAION_LINK: {
      return {
        ...state,
        createInvitationLinkData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_CREATE_INVITATION_LINK: {
      let oldData = { ...state?.getTaggedUsersData?.data };
      oldData.rows = [action?.payload?.data, ...(oldData?.rows || [])];
      oldData.count = oldData.count + 1;
      return {
        ...state,
        createInvitationLinkData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getTaggedUsersData: {
          ...state?.getTaggedUsersData,
          data: oldData,
        },
      };
    }
    case ERROR_CREATE_INVITAION_LINK: {
      return {
        ...state,
        createInvitationLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UPDATE_INVITATION_LINK: {
      return {
        ...state,
        updateInvitationLinkData: {
          isLoading: true,
          data: {},
          error: "",
          status: false,
        },
      };
    }
    case SUCCESS_UPDATE_INVITATION_LINK: {
      let oldData = { ...state?.getTaggedUsersData?.data };
      oldData.rows = oldData?.rows?.map((item) => {
        if (item?.id === action?.payload?.data?.id) {
          return {
            ...item,
            ...action?.payload?.data,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        updateInvitationLinkData: {
          ...action.payload,
          isLoading: false,
        },
        getTaggedUsersData: {
          ...state?.getTaggedUsersData,
          data: oldData,
        },
      };
    }
    case ERROR_UPDATE_INVITATION_LINK: {
      return {
        ...state,
        updateInvitationLinkData: {
          isLoading: false,
          data: {},
          error: action.payload,
          state: false,
        },
      };
    }

    case START_UPDATE_LINK_VISIBILITY: {
      return {
        ...state,
        updateLinkVisibilityData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_LINK_VISIBILITY: {
      let obj = { ...state.getTaggedUsersData };
      if (
        !action?.payload?.data?.hidden_for_default_list &&
        !action?.payload?.data?.isSearchOpen
      ) {
        obj["data"]["rows"] = obj?.data?.rows?.filter(
          (item) => item?.id !== action.payload?.data?.id
        );
        obj["data"]["count"] = obj?.data?.rows?.length;
      } else {
        obj["data"]["rows"] = obj?.data?.rows?.map((item) =>
          item?.id === action.payload?.data?.id
            ? {
                ...item,
                hidden_for_default_list: !item?.hidden_for_default_list,
              }
            : item
        );
      }
      return {
        ...state,
        updateLinkVisibilityData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        getTaggedUsersData: obj,
      };
    }
    case ERROR_UPDATE_LINK_VISIBILITY: {
      return {
        ...state,
        updateLinkVisibilityData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_TAGGED_USERS: {
      let oldData = { ...state?.getTaggedUsersData?.data };
      return {
        ...state,
        getTaggedUsersData: {
          isLoading: action.payload.offset > 0 ? false : true,
          data: action.payload?.offset > 0 ? oldData : {},
          error: "",
        },
      };
    }

    case SUCCESS_GET_TAGGED_USERS: {
      let oldData = { ...state?.getTaggedUsersData?.data };
      if (oldData?.rows?.length > 0) {
        oldData["rows"] = [...oldData.rows, ...action?.payload?.data?.rows];
      } else {
        oldData = action.payload.data;
      }
      return {
        ...state,
        getTaggedUsersData: {
          isLoading: false,
          data: oldData,
          error: "",
        },
      };
    }

    case ERROR_GET_TAGGED_USERS: {
      return {
        ...state,
        getTaggedUsersData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_VERIFY_GUEST_TOKEN: {
      return {
        ...state,
        verifyGuestTokenData: {
          isLoading: true,
          data:
            action?.payload?.type == "verify"
              ? {}
              : { ...state?.verifyGuestTokenData?.data },
          error: "",
          confirmed: false,
        },
      };
    }
    case SUCCESS_VERIFY_GUEST_TOKEN: {
      return {
        ...state,
        verifyGuestTokenData: {
          isLoading: false,
          data: action.payload,
          error: "",
          confirmed: action?.verifyType == "confirm" ? true : false,
        },
      };
    }
    case ERROR_VERIFY_GUEST_TOKEN: {
      return {
        ...state,
        verifyGuestTokenData: {
          isLoading: false,
          data: { ...state?.verifyGuestTokenData?.data },
          error: action.payload,
          confirmed: false,
        },
      };
    }

    default: {
      return {
        ...(state || initialState),
      };
    }
  }
};
