import {
  ERROR_GET_STATEMENT_CHARGEBACK_LIST,
  ERROR_GET_STATEMENT_CHARGEBACK_STATISTICS,
  ERROR_GET_STATEMENT_EARNING_LIST,
  ERROR_GET_STATEMENT_EARNING_STATISTICS,
  ERROR_GET_STATEMENT_PAYOUT_LIST,
  ERROR_GET_STATEMENT_PAYOUT_STATISTICS,
  ERROR_GET_STATEMENT_REFERRALS_LIST,
  ERROR_GET_STATEMENT_REFERRALS_STATISTICS,
  ERROR_GET_STATISTICS_CONFIG,
  START_GET_STATEMENT_CHARGEBACK_LIST,
  START_GET_STATEMENT_CHARGEBACK_STATISTICS,
  START_GET_STATEMENT_EARNING_LIST,
  START_GET_STATEMENT_EARNING_STATISTICS,
  START_GET_STATEMENT_PAYOUT_LIST,
  START_GET_STATEMENT_PAYOUT_STATISTICS,
  START_GET_STATEMENT_REFERRALS_LIST,
  START_GET_STATEMENT_REFERRALS_STATISTICS,
  START_GET_STATISTICS_CONFIG,
  SUCCESS_GET_STATEMENT_CHARGEBACK_LIST,
  SUCCESS_GET_STATEMENT_CHARGEBACK_STATISTICS,
  SUCCESS_GET_STATEMENT_EARNING_LIST,
  SUCCESS_GET_STATEMENT_EARNING_STATISTICS,
  SUCCESS_GET_STATEMENT_PAYOUT_LIST,
  SUCCESS_GET_STATEMENT_PAYOUT_STATISTICS,
  SUCCESS_GET_STATEMENT_REFERRALS_LIST,
  SUCCESS_GET_STATEMENT_REFERRALS_STATISTICS,
  SUCCESS_GET_STATISTICS_CONFIG,
} from "Actions/StatisticsAction";
import {
  getStatementChargebackListAPI,
  getStatementChargebackStatisticsAPI,
  getStatementEarningListAPI,
  getStatementEarningStatisticsAPI,
  getStatementPayoutStatisticsAPI,
  getStatementPayputListAPI,
  getStatementReferralsListAPI,
  getStatementReferralsStatisticsAPI,
  getStatisticsConfigAPI,
} from "Api/StatisticsApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* getStatementEarningStatisticsWorker({ payload }) {
  try {
    const res = yield call(getStatementEarningStatisticsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_EARNING_STATISTICS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_EARNING_STATISTICS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementEarningListWorker({ payload }) {
  try {
    const res = yield call(getStatementEarningListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_EARNING_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_EARNING_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatisticsConfigWorker({ payload }) {
  try {
    const res = yield call(getStatisticsConfigAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATISTICS_CONFIG,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATISTICS_CONFIG,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementPayoutStatisticsWorker({ payload }) {
  try {
    const res = yield call(getStatementPayoutStatisticsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_PAYOUT_STATISTICS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_PAYOUT_STATISTICS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementPayoutListWorker({ payload }) {
  try {
    const res = yield call(getStatementPayputListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_PAYOUT_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_PAYOUT_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementChargebakStatisticsWorker({ payload }) {
  try {
    const res = yield call(getStatementChargebackStatisticsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_CHARGEBACK_STATISTICS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_CHARGEBACK_STATISTICS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementChargebackListWorker({ payload }) {
  try {
    const res = yield call(getStatementChargebackListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_CHARGEBACK_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_CHARGEBACK_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementReferralsStatisticsWorker({ payload }) {
  try {
    const res = yield call(getStatementReferralsStatisticsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_REFERRALS_STATISTICS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_REFERRALS_STATISTICS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getStatementReferralsListWorker({ payload }) {
  try {
    const res = yield call(getStatementReferralsListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_REFERRALS_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_REFERRALS_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* StatisticsWatcher() {
  yield takeEvery(
    START_GET_STATEMENT_EARNING_STATISTICS,
    getStatementEarningStatisticsWorker
  );
  yield takeEvery(
    START_GET_STATEMENT_EARNING_LIST,
    getStatementEarningListWorker
  );
  yield takeEvery(START_GET_STATISTICS_CONFIG, getStatisticsConfigWorker);
  yield takeEvery(
    START_GET_STATEMENT_PAYOUT_STATISTICS,
    getStatementPayoutStatisticsWorker
  );
  yield takeEvery(
    START_GET_STATEMENT_PAYOUT_LIST,
    getStatementPayoutListWorker
  );
  yield takeEvery(
    START_GET_STATEMENT_CHARGEBACK_STATISTICS,
    getStatementChargebakStatisticsWorker
  );
  yield takeEvery(
    START_GET_STATEMENT_CHARGEBACK_LIST,
    getStatementChargebackListWorker
  );
  yield takeEvery(
    START_GET_STATEMENT_REFERRALS_STATISTICS,
    getStatementReferralsStatisticsWorker
  );
  yield takeEvery(
    START_GET_STATEMENT_REFERRALS_LIST,
    getStatementReferralsListWorker
  );
}
