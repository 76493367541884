import { generateDynamicEndpoint } from "utils/generalUtils";
import API from "../service";
import {
  CHANGE_MEDIA_CATEGORY_ENDPOINT,
  CHANGE_VAULT_LIST_ORDER_ENDPOINT,
  CREATE_VAULT_LIST_ENDPOINT,
  DELETE_VAULT_LIST_ENDPOINT,
  DELETE_VAULT_MEDIA_ENDPOINT,
  GET_SINGLE_VAULT_LIST_ENDPOINT,
  GET_VAULT_LIST_ENDPOINT,
  GET_VAULT_MEDIA_BY_ID_ENDPOINT,
  GET_VAULT_MEDIA_ENDPOINT,
  UPDATE_VAULT_LIST_ENDPOINT,
} from "./endpoints";

export const getVaultListAPI = (payload) => {
  return API.get(GET_VAULT_LIST_ENDPOINT, payload);
};

export const createVaultListAPI = (payload) => {
  return API.post(CREATE_VAULT_LIST_ENDPOINT, payload);
};

export const updateVaultListAPI = (payload, id) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_VAULT_LIST_ENDPOINT, { id }),
    payload
  );
};

export const deleteVaultListAPI = (id, payload) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_VAULT_LIST_ENDPOINT, { id }),
    payload
  );
};

export const getSingleVaultListAPI = (id) => {
  return API.get(
    generateDynamicEndpoint(GET_SINGLE_VAULT_LIST_ENDPOINT, { id })
  );
};

export const changeVaultListOrderAPI = (payload) => {
  return API.post(CHANGE_VAULT_LIST_ORDER_ENDPOINT, payload);
};

export const getVaultMediaListAPI = (payload) => {
  return API.get(GET_VAULT_MEDIA_ENDPOINT, payload);
};

export const deleteVaultMediaAPI = (payload) => {
  return API.deleteM(DELETE_VAULT_MEDIA_ENDPOINT, payload);
};

export const changeMediaCategoryAPI = (id, payload) => {
  return API.put(
    generateDynamicEndpoint(CHANGE_MEDIA_CATEGORY_ENDPOINT, { id }),
    payload
  );
};

export const getVaultMediaByIdAPI = (payload) => {
  return API.post(GET_VAULT_MEDIA_BY_ID_ENDPOINT, payload);
};
