export const RESET_CARD_STATE = "RESET_CARD_STATE";

export const START_ADD_CARD = "START_ADD_CARD";
export const SUCCESS_ADD_CARD = "SUCCESS_ADD_CARD";
export const ERROR_ADD_CARD = "ERROR_ADD_CARD";

export const START_GET_CARD_LIST = "START_GET_CARD_LIST";
export const SUCCESS_GET_CARD_LIST = "SUCCESS_GET_CARD_LIST";
export const ERROR_GET_CARD_LIST = "ERROR_GET_CARD_LIST";

export const START_DELETE_CARD = "START_DELETE_CARD";
export const SUCCESS_DELETE_CARD = "SUCCESS_DELETE_CARD";
export const ERROR_DELETE_CARD = "ERROR_DELETE_CARD";

export const START_SET_DEFAULT_CARD = "START_SET_DEFAULT_CARD";
export const SUCCESS_SET_DEFAULT_CARD = "SUCCESS_SET_DEFAULT_CARD";
export const ERROR_SET_DEFAULT_CARD = "ERROR_SET_DEFAULT_CARD";

export const START_UPDATE_CARD_ADDRESS = "START_UPDATE_CARD_ADDRESS";
export const SUCCESS_UPDATE_CARD_ADDRESS = "SUCCESS_UPDATE_CARD_ADDRESS";
export const ERROR_UPDATE_CARD_ADDRESS = "ERROR_UPDATE_CARD_ADDRESS";

export const START_MAKE_WALLET_PRIMARY = "START_MAKE_WALLET_PRIMARY";
export const SUCCESS_MAKE_WALLET_PRIMARY = "SUCCESS_MAKE_WALLET_PRIMARY";
export const ERROR_MAKE_WALLET_PRIMARY = "ERROR_MAKE_WALLET_PRIMARY";

export const START_AUTO_RECHARGE_WALLET = "START_AUTO_RECHARGE_WALLET";
export const SUCCESS_AUTO_RECHARGE_WALLET = "SUCCESS_AUTO_RECHARGE_WALLET";
export const ERROR_AUTO_RECHARGE_WALLET = "ERROR_AUTO_RECHARGE_WALLET";

export const START_SEND_TIP = "START_SEND_TIP";
export const SUCCESS_SEND_TIP = "SUCCESS_SEND_TIP";
export const ERROR_SEND_TIP = "ERROR_SEND_TIP";

export const START_GET_MY_WALLET = "START_GET_MY_WALLET";
export const SUCCESS_GET_MY_WALLET = "SUCCESS_GET_MY_WALLET";
export const ERROR_GET_MY_WALLET = "ERROR_GET_MY_WALLET";

export const START_TOPUP_MY_WALLET = "START_TOPUP_MY_WALLET";
export const SUCCESS_TOPUP_MY_WALLET = "SUCCESS_TOPUP_MY_WALLET";
export const ERROR_TOPUP_MY_WALLET = "ERROR_TOPUP_MY_WALLET";

export const START_GET_TRANSACTIONS = "START_GET_TRANSACTIONS";
export const SUCCESS_GET_TRANSACTIONS = "SUCCESS_GET_TRANSACTIONS";
export const ERROR_GET_TRANSACTIONS = "ERROR_GET_TRANSACTIONS";

export const START_GET_LATEST_TRANSACTION = "START_GET_LATEST_TRANSACTION";
export const SUCCESS_GET_LATEST_TRANSACTION = "SUCCESS_GET_LATEST_TRANSACTION";
export const ERROR_GET_LATEST_TRANSACTION = "ERROR_GET_LATEST_TRANSACTION";

// Socket
export const SOCKET_AUTO_RECHARGE_WALLET = "SOCKET_AUTO_RECHARGE_WALLET";
export const SOCKET_ADDED_NEW_CARD = "SOCKET_ADDED_NEW_CARD";
