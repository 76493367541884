import moment from "moment";
import {
  RESET_QUEUE_ACTION,
  SET_QUEUE_CONFIG_DATA,
  START_GET_QUEUE_COUNTERS,
  SUCCESS_GET_QUEUE_COUNTERS,
  ERROR_GET_QUEUE_COUNTERS,
  START_GET_QUEUE_LIST,
  SUCCESS_GET_QUEUE_LIST,
  ERROR_GET_QUEUE_LIST,
  START_TAKE_SCHEDULE_ACTION,
  SUCCESS_TAKE_SCHEDULE_ACTION,
  ERROR_TAKE_SCHEDULE_ACTION,
  SOCKET_REFRESH_SCHEDULE_ACTION,
  START_GET_POST_SAVED_FOR_LATER_CONFIG,
  SUCCESS_GET_POST_SAVED_FOR_LATER_CONFIG,
  ERROR_GET_POST_SAVED_FOR_LATER_CONFIG,
  START_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
  SUCCESS_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
  ERROR_UPDATE_POST_SAVED_FOR_LATER_CONFIG,
  START_GET_SAVED_FOR_LATER_POSTS,
  SUCCESS_GET_SAVED_FOR_LATER_POSTS,
  ERROR_GET_SAVED_FOR_LATER_POSTS,
} from "../Actions/QueueAction";
import {
  SOCKET_IMAGE_MEDIA_UPLOADED,
  SOCKET_POST_VIDEO_UPLOAD,
  SOCKET_UPDATE_POST_CREATORS,
} from "Actions/PostAction";
import { SOCKET_CHAT_VIDEO_UPLOAD } from "Actions/ChatAction";

const initialState = {
  queueConfigData: {
    date: "",
    displayDate: moment().add(10, "minutes"),
    savedForLater: false,
    addToQueue: false,
    addToQueueType: "scheduled",
    viewType: "day", // list - other option
    filter: [],
    daySchedule: false,
    isFromQueue: false,
  },
  queueCounts: {
    isLoading: false,
    data: {},
    error: "",
  },
  queueList: {
    isLoading: false,
    list: {},
    error: "",
  },
  scheduleActionData: {
    isLoading: false,
    data: {},
    error: "",
  },
  needRefresh: false,
  savedForLaterConfig: {
    isLoading: false,
    data: {},
    error: "",
  },
  updateSavedForLaterData: {
    isLoading: false,
    data: {},
    error: "",
  },
  savedForLaterPosts: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const queueReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_QUEUE_ACTION: {
      return {
        ...state,
        [action.key]: action.payload || initialState[action?.key],
      };
    }
    case SET_QUEUE_CONFIG_DATA: {
      return {
        ...state,
        queueConfigData: {
          ...state?.queueConfigData,
          ...action.payload,
        },
      };
    }
    case START_GET_QUEUE_COUNTERS: {
      return {
        ...state,
        queueCounts: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_QUEUE_COUNTERS: {
      return {
        ...state,
        queueCounts: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_QUEUE_COUNTERS: {
      return {
        ...state,
        queueCounts: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case START_GET_QUEUE_LIST: {
      return {
        ...state,
        queueList: {
          isLoading: true,
          list: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_QUEUE_LIST: {
      return {
        ...state,
        queueList: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_QUEUE_LIST: {
      return {
        ...state,
        queueList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_TAKE_SCHEDULE_ACTION: {
      return {
        ...state,
        scheduleActionData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_TAKE_SCHEDULE_ACTION: {
      return {
        ...state,
        scheduleActionData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_TAKE_SCHEDULE_ACTION: {
      return {
        ...state,
        scheduleActionData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_POST_SAVED_FOR_LATER_CONFIG: {
      return {
        ...state,
        savedForLaterConfig: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_POST_SAVED_FOR_LATER_CONFIG: {
      return {
        ...state,
        savedForLaterConfig: {
          isLoading: false,
          data: action.payload?.data,
          error: "",
        },
      };
    }
    case ERROR_GET_POST_SAVED_FOR_LATER_CONFIG: {
      return {
        ...state,
        savedForLaterConfig: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_UPDATE_POST_SAVED_FOR_LATER_CONFIG: {
      return {
        ...state,
        updateSavedForLaterData: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_UPDATE_POST_SAVED_FOR_LATER_CONFIG: {
      return {
        ...state,
        updateSavedForLaterData: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
        savedForLaterConfig: {
          ...state?.savedForLaterConfig,
          data: {
            ...state?.savedForLaterConfig?.data,
            period_publish_later_post: action?.data?.period_publish_later_post,
          },
        },
      };
    }
    case ERROR_UPDATE_POST_SAVED_FOR_LATER_CONFIG: {
      return {
        ...state,
        updateSavedForLaterData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case START_GET_SAVED_FOR_LATER_POSTS: {
      return {
        ...state,
        savedForLaterPosts: {
          isLoading: true,
          data: {},
          error: "",
        },
      };
    }
    case SUCCESS_GET_SAVED_FOR_LATER_POSTS: {
      return {
        ...state,
        savedForLaterPosts: {
          isLoading: false,
          data: action.payload,
          error: "",
        },
      };
    }
    case ERROR_GET_SAVED_FOR_LATER_POSTS: {
      return {
        ...state,
        savedForLaterPosts: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    // Socket

    case SOCKET_REFRESH_SCHEDULE_ACTION: {
      return {
        ...state,
        needRefresh: true,
      };
    }

    case SOCKET_IMAGE_MEDIA_UPLOADED: {
      const { category, data } = action.payload;
      const { post_id, id, chat_message_id } = data;
      let postData = { ...state?.queueList?.data };
      if (category == "post_image") {
        postData.list = postData?.list?.map((item) => {
          if (item?.post?.id == post_id) {
            return {
              ...item,
              post: {
                ...item?.post,
                post_images: item?.post?.post_images?.map((image) => {
                  if (image?.id == id) {
                    return {
                      ...image,
                      ...data,
                    };
                  } else {
                    return image;
                  }
                }),
              },
            };
          } else {
            return item;
          }
        });
      }
      if (category == "chat_message_image") {
        const { mass_message_id, id } = data?.[0];
        postData.list = postData?.list?.map((item) => {
          if (item?.message?.id == mass_message_id) {
            return {
              ...item,
              message: {
                ...item?.message,
                mass_message_images: item?.message?.mass_message_images?.map(
                  (image) => {
                    if (image?.id == id) {
                      return {
                        ...image,
                        ...data?.[0],
                      };
                    } else {
                      return image;
                    }
                  }
                ),
              },
            };
          } else {
            return item;
          }
        });
      }
      return {
        ...state,
        queueList: {
          ...state?.queueList,
          data: postData,
        },
      };
    }
    case SOCKET_POST_VIDEO_UPLOAD: {
      const { post_id, uploaded_video } = action.payload;
      let postData = { ...state?.queueList?.data };
      postData.list = postData?.list?.map((item) => {
        if (item?.post?.id == post_id) {
          return {
            ...item,
            post: {
              ...item?.post,
              post_videos: item?.post?.post_videos?.map((video) => {
                if (video?.id == uploaded_video?.id) {
                  return {
                    ...video,
                    thumbnail: uploaded_video?.thumbnail_url,
                    video_url: uploaded_video?.video_url,
                    is_uploaded: true,
                  };
                } else {
                  return video;
                }
              }),
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        queueList: {
          ...state?.queueList,
          data: postData,
        },
      };
    }

    case SOCKET_CHAT_VIDEO_UPLOAD: {
      const { chat_message_video } = action.payload;
      let postData = { ...state?.queueList?.data };
      postData.list = postData?.list?.map((item) => {
        if (item?.message?.id == chat_message_video?.mass_message_id) {
          return {
            ...item,
            message: {
              ...item?.message,
              mass_message_videos: item?.message?.mass_message_videos?.map(
                (video) => {
                  if (video?.id == chat_message_video?.id) {
                    return {
                      ...video,
                      ...action?.payload?.chat_message_video,
                    };
                  } else {
                    return video;
                  }
                }
              ),
            },
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        queueList: {
          ...state?.queueList,
          data: postData,
        },
      };
    }

    case SOCKET_UPDATE_POST_CREATORS: {
      const { post_id, release_forms } = action.payload;
      let postData = { ...state?.queueList?.data };
      postData.list = postData?.list?.map((item) =>
        item?.post?.id === post_id
          ? { ...item, post: { ...item?.post, release_forms } }
          : item
      );

      return {
        ...state,
        queueList: {
          ...state?.queueList,
          data: postData,
        },
      };
    }
    default: {
      return {
        ...(state || initialState),
      };
    }
  }
};
