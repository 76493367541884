import API from "service";
import {
  CREATE_AGENCY_MEMBERS_INVITE,
  GET_AGENCY_MEMBERS,
} from "./endpoints";

export const getAgencyMembersAPI = (payload) => {
  return API.post(GET_AGENCY_MEMBERS, payload);
};


export const createAgencyInvitesAPI = (payload) => {
  return API.post(CREATE_AGENCY_MEMBERS_INVITE, payload);
};

export const submitAgencyInviteResponseAPI = (payload) => {
  return API.put(CREATE_AGENCY_MEMBERS_INVITE, payload);
};

export const removeAgencyMemberAPI = (payload) => {
  return API.deleteM(`${GET_AGENCY_MEMBERS}/${payload?.id}`);
};

export const editAgencyMemberAPI = (payload) => {
  return API.put(`${GET_AGENCY_MEMBERS}/${payload?.id}` , payload);
};
