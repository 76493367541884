export const RESET_RELEASE_FORM_REDUCER = "RESET_RELEASE_FORM_REDUCER";

export const START_SUBMIT_SIGNED_RELEASE_FORM =
  "START_SUBMIT_SIGNED_RELEASE_FORM";
export const SUCCESS_SUBMIT_SIGNED_RELEASE_FORM =
  "SUCCESS_SUBMIT_SIGNED_RELEASE_FORM";
export const ERROR_SUBMIT_SIGNED_RELEASE_FORM =
  "ERROR_SUBMIT_SIGNED_RELEASE_FORM";

export const START_GET_SIGNED_RELEASE_FORM = "START_GET_SIGNED_RELEASE_FORM";
export const SUCCESS_GET_SIGNED_RELEASE_FORM =
  "SUCCESS_GET_SIGNED_RELEASE_FORM";
export const ERROR_GET_SIGNED_RELEASE_FORM = "ERROR_GET_SIGNED_RELEASE_FORM";

export const START_UPDATE_SIGNED_RELEASE_FORM =
  "START_UPDATE_SIGNED_RELEASE_FORM";
export const SUCCESS_UPDATE_SIGNED_RELEASE_FORM =
  "SUCCESS_UPDATE_SIGNED_RELEASE_FORM";
export const ERROR_UPDATE_SIGNED_RELEASE_FORM =
  "ERROR_UPDATE_SIGNED_RELEASE_FORM";

export const START_UPDATE_RF_DEFAULT_VISIBILITY =
  "START_UPDATE_RF_DEFAULT_VISIBILITY";
export const SUCCESS_UPDATE_RF_DEFAULT_VISIBILITY =
  "SUCCESS_UPDATE_RF_DEFAULT_VISIBILITY";
export const ERROR_UPDATE_RF_DEFAULT_VISIBILITY =
  "ERROR_UPDATE_RF_DEFAULT_VISIBILITY";

export const START_CREATE_INVITAION_LINK = "START_CREATE_INVITAION_LINK";
export const SUCCESS_CREATE_INVITATION_LINK = "SUCCESS_CREATE_INVITATION_LINK";
export const ERROR_CREATE_INVITAION_LINK = "ERROR_CREATE_INVITAION_LINK";

export const START_UPDATE_INVITATION_LINK = "START_UPDATE_INVITATION_LINK";
export const SUCCESS_UPDATE_INVITATION_LINK = "SUCCESS_UPDATE_INVITATION_LINK";
export const ERROR_UPDATE_INVITATION_LINK = "ERROR_UPDATE_INVITATION_LINK";

export const START_UPDATE_LINK_VISIBILITY = "START_UPDATE_LINK_VISIBILITY";
export const SUCCESS_UPDATE_LINK_VISIBILITY = "SUCCESS_UPDATE_LINK_VISIBILITY";
export const ERROR_UPDATE_LINK_VISIBILITY = "ERROR_UPDATE_LINK_VISIBILITY";

export const START_GET_TAGGED_USERS = "START_GET_TAGGED_USERS";
export const SUCCESS_GET_TAGGED_USERS = "SUCCESS_GET_TAGGED_USERS";
export const ERROR_GET_TAGGED_USERS = "ERROR_GET_TAGGED_USERS";

export const START_VERIFY_GUEST_TOKEN = "START_VERIFY_GUEST_TOKEN";
export const SUCCESS_VERIFY_GUEST_TOKEN = "SUCCESS_VERIFY_GUEST_TOKEN";
export const ERROR_VERIFY_GUEST_TOKEN = "ERROR_VERIFY_GUEST_TOKEN";
