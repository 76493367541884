import API from "service";
import { generateDynamicEndpoint } from "utils/generalUtils";
import {
  ADD_CARD_ENDPOINT,
  AUTO_RECHARGE_WALLET_ENDPOINT,
  DELETE_CARD_ENDPOINT,
  GET_CARD_LIST_ENDPOINT,
  GET_LATEST_TRANSACTION_ENDPOINT,
  GET_MY_WALLET_ENDPOINT,
  GET_TRANSACTIONS_ENDPOINT,
  MAKE_WALLET_PRIMARY_ENDPOINT,
  SEND_TIP_ENDPOINT,
  SET_DEFAULT_CARD_ENDPOINT,
  TOPUP_MY_WALLET_ENDPOINT,
  UPDATE_CARD_ADDRESS_ENDPOINT,
} from "./endpoints";

export const addCardAPI = (payload) => {
  return API.post(ADD_CARD_ENDPOINT, payload);
};

export const getCardListAPI = (payload) => {
  return API.post(GET_CARD_LIST_ENDPOINT, payload);
};

export const deleteCardAPI = (card_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_CARD_ENDPOINT, { card_id })
  );
};

export const setDefaultCardAPI = (card_id) => {
  return API.post(
    generateDynamicEndpoint(SET_DEFAULT_CARD_ENDPOINT, { card_id })
  );
};

export const updateCardAPI = (card_id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_CARD_ADDRESS_ENDPOINT, { card_id }),
    payload
  );
};

export const makeWalletPrimaryAPI = (payload) => {
  return API.put(MAKE_WALLET_PRIMARY_ENDPOINT, payload);
};

export const autoRechargeWalletAPI = (payload) => {
  return API.put(AUTO_RECHARGE_WALLET_ENDPOINT, payload);
};

export const sendTipAPI = (payload, user_id) => {
  return API.post(
    generateDynamicEndpoint(SEND_TIP_ENDPOINT, { user_id }),
    payload
  );
};

export const getWalletAPI = () => {
  return API.get(GET_MY_WALLET_ENDPOINT);
};

export const topupMyWalletAPI = (payload) => {
  return API.post(TOPUP_MY_WALLET_ENDPOINT, payload);
};

export const getTransactionsAPI = (payload) => {
  return API.post(GET_TRANSACTIONS_ENDPOINT, payload);
};

export const getLatestTransactionAPI = (payload) => {
  return API.get(GET_LATEST_TRANSACTION_ENDPOINT, payload);
};
