import { generateDynamicEndpoint } from "utils/generalUtils";
import API from "../service";
import {
  CREATE_NEW_CHAT_ENDPOINT,
  DELETE_CHAT_ENDPOINT,
  DELETE_CHAT_MESSAGES_ENDPOINT,
  GET_CHAT_CONVERSATION_ENDPOINT,
  GET_CHAT_LIST_ENDPOINT,
  GET_CHAT_MEDIA_URL_ENDPOINT,
  GET_NEW_MESSAGE_LIST_ENDPOINT,
  GET_SINGLE_MASS_MESSAGE_ENDPOINT,
  PURCHASE_MESSAGE_ENDPOINT,
  READ_MESSAGE_ENDPOINT,
  SEND_MESSAGE_ENDPOINT,
  UPDATE_MASS_MESSAGE_ENDPOINT,
} from "./endpoints";

export const getChatListAPI = (payload) => {
  return API.post(GET_CHAT_LIST_ENDPOINT, payload);
};

export const getNewMessageListAPI = (payload) => {
  return API.post(GET_NEW_MESSAGE_LIST_ENDPOINT, payload);
};

export const createNewChatAPI = (payload) => {
  return API.post(CREATE_NEW_CHAT_ENDPOINT, payload);
};

export const getChatConversationAPI = (chat_id, payload) => {
  return API.post(
    generateDynamicEndpoint(GET_CHAT_CONVERSATION_ENDPOINT, { chat_id }),
    payload
  );
};

export const getChatMediaUrlAPI = (payload) => {
  return API.post(GET_CHAT_MEDIA_URL_ENDPOINT, payload);
};

export const uploadChatMediaOnAwsAPI = async (url, data) => {
  let fileCount = 0;
  let res = data?.map(
    async (file, i) =>
      await fetch(url[i], {
        method: "PUT",
        headers: {},
        body: file,
      }).then((res) => (fileCount += 1))
  );
  return Promise.all(res)?.then((res) => fileCount === data?.length);
};

export const sendMessageAPI = (payload) => {
  return API.post(SEND_MESSAGE_ENDPOINT, payload);
};

export const readMessageAPI = (payload) => {
  return API.post(READ_MESSAGE_ENDPOINT, payload);
};

export const deleteChatAPI = (payload) => {
  return API.post(DELETE_CHAT_ENDPOINT, payload);
};

export const deleteChatMessageAPI = (chat_id, payload) => {
  return API.post(
    generateDynamicEndpoint(DELETE_CHAT_MESSAGES_ENDPOINT, { chat_id }),
    payload
  );
};

export const purchaseMessageAPI = (payload) => {
  return API.post(PURCHASE_MESSAGE_ENDPOINT, payload);
};

export const getSingleMassMessageAPI = (id, payload) => {
  return API.get(
    generateDynamicEndpoint(GET_SINGLE_MASS_MESSAGE_ENDPOINT, {
      message_id: id,
    }),
    payload
  );
};

export const updateMassMessageAPI = (id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_MASS_MESSAGE_ENDPOINT, {
      message_id: id,
    }),
    payload
  );
};
