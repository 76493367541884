import {
  ERROR_GET_PUBLIC_POST_BY_ID,
  ERRRO_GET_PUBLIC_PROFILE_MEDIA,
  ERRRO_GET_PUBLIC_PROFILE_POSTS,
  START_GET_PUBLIC_POST_BY_ID,
  START_GET_PUBLIC_PROFILE_MEDIA,
  START_GET_PUBLIC_PROFILE_POSTS,
  SUCCESS_GET_PUBLIC_POST_BY_ID,
  SUCCESS_GET_PUBLIC_PROFILE_MEDIA,
  SUCCESS_GET_PUBLIC_PROFILE_POSTS,
} from "Actions/GlobalProfileAction";
import {
  getPublicPostsByID,
  getPublicProfileMediaAPI,
  getPublicProfilePostsAPI,
} from "Api/GlobalProfileApi";
import { call, put, takeEvery } from "redux-saga/effects";

function* getPublicProfilePostsWorker({ user_id, payload }) {
  try {
    const response = yield call(getPublicProfilePostsAPI, user_id, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_PUBLIC_PROFILE_POSTS,
        payload: response,
      });
    } else {
      yield put({
        type: ERRRO_GET_PUBLIC_PROFILE_POSTS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getPublicProfileMediaWorker({ payload }) {
  try {
    const response = yield call(getPublicProfileMediaAPI, payload);
    if (response?.status) {
      yield put({
        type: SUCCESS_GET_PUBLIC_PROFILE_MEDIA,
        payload: response,
      });
    } else {
      yield put({
        type: ERRRO_GET_PUBLIC_PROFILE_MEDIA,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getPublicPostsByIdWorker({ payload }) {
  try {
    const response = yield call(getPublicPostsByID, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_PUBLIC_POST_BY_ID,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_PUBLIC_POST_BY_ID,
        payload: response,
      });
    }
  } catch (error) {}
}

export function* globalProfileWatcher() {
  yield takeEvery(START_GET_PUBLIC_PROFILE_POSTS, getPublicProfilePostsWorker);
  yield takeEvery(START_GET_PUBLIC_PROFILE_MEDIA, getPublicProfileMediaWorker);
  yield takeEvery(START_GET_PUBLIC_POST_BY_ID, getPublicPostsByIdWorker);
}
