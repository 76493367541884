import {
  SOCKET_ADD_POST_COMMENT,
  SOCKET_ADD_POST_LIKE,
  SOCKET_DELETE_POST_COMMENT,
  SOCKET_REMOVE_POST_LIKE,
  SOCKET_UPDATE_POST_COMMENT,
  START_ADD_BOOKMARK,
  START_REMOVE_BOOKMARK,
  START_USER_POST_DISLIKE,
  START_USER_POST_LIKE,
  SUCCESS_ADD_BOOKMARK,
  SUCCESS_REMOVE_BOOKMARK,
  SUCCESS_UNLOCK_POST,
} from "Actions/PostAction";
import {
  ERROR_SEARCH,
  RESET_SEARCH_STATE,
  START_SEARCH,
  SUCCESS_SEARCH,
  START_USER_SEARCH,
  SUCCESS_USER_SEARCH,
  ERROR_USER_SEARCH,
} from "Actions/SearchActions";
import {
  SUCCESS_BLOCK_USER,
  SUCCESS_SUBSCRIBE_USER,
  SUCCESS_UNBLOCK_USER,
} from "Actions/UserAction";
import { SUCCESS_SUBSCRIPTION_ACTION } from "Actions/SubscriptionAction";

const initialState = {
  searchData: {
    isLoading: false,
    data: {},
    error: "",
  },
  searchUsersList: {
    isLoading: false,
    data: {},
    error: "",
  },
};

export const searchReducer = (state = initialState, action, root) => {
  switch (action?.type) {
    case RESET_SEARCH_STATE: {
      return {
        ...state,
        [action.key]: action?.payload || initialState[action?.key],
      };
    }
    case SUCCESS_SUBSCRIPTION_ACTION: {
      const oldData = { ...state.searchData?.data };
      if (Object.keys(oldData)?.length > 0) {
        oldData["users"] = oldData?.users?.map((item) => {
          if (item?.id == action?.payload?.user?.id) {
            return {
              ...item,
              ...action?.payload?.user,
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldData,
        },
      };
    }
    case START_SEARCH: {
      let oldData = { ...state?.searchData?.data };
      return {
        ...state,
        searchData: {
          isLoading:
            action?.searchType == "user"
              ? action?.payload?.user_offset > 0
                ? false
                : true
              : action?.payload?.post_offset > 0
              ? false
              : true,
          data:
            action?.payload?.user_offset > 0 || action?.payload?.post_offset > 0
              ? oldData
              : {},
          error: "",
        },
      };
    }
    case SUCCESS_SEARCH: {
      let { searchType } = action;
      let oldData = { ...state?.searchData?.data };
      if (searchType == "user") {
        if (oldData?.users?.length > 0) {
          oldData["users"] = [...oldData?.users, ...action?.payload?.users];
        } else {
          oldData = action?.payload;
        }
      } else {
        if (oldData?.posts?.length > 0) {
          oldData["posts"] = [...oldData?.posts, ...action?.payload?.posts];
        } else {
          oldData = action?.payload;
        }
      }
      return {
        ...state,
        searchData: {
          isLoading: false,
          data: oldData,
          error: "",
        },
      };
    }
    case ERROR_SEARCH: {
      return {
        ...state,
        searchData: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    case SUCCESS_SUBSCRIBE_USER: {
      let { followStatus, user_id } = action;

      let oldData = { ...state?.searchData?.data };
      if (Object?.keys(oldData)?.length > 0) {
        oldData["users"] = oldData?.users?.map((item) => {
          if (item?.id == user_id) {
            return {
              ...item,
              subscribed_to: followStatus ? 1 : 0,
            };
          } else {
            return item;
          }
        });
      }

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldData,
        },
      };
    }

    case SUCCESS_BLOCK_USER: {
      let { user_id } = action;
      let oldData = { ...state?.searchData?.data };
      if (Object?.keys(oldData)?.length > 0) {
        oldData["users"] = oldData?.users?.filter(
          (item) => item?.id != user_id
        );
      }
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldData,
        },
      };
    }

    case START_USER_POST_LIKE: {
      const { post_id } = action;
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: 1,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case START_USER_POST_DISLIKE: {
      const { post_id } = action;
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: 0,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SOCKET_ADD_POST_LIKE: {
      const { post_id, liked_by } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count + 1,
            liked_by_user: liked_by == myUserId ? 1 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SOCKET_REMOVE_POST_LIKE: {
      const { post_id, unliked_by } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;

      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          return {
            ...item,
            like_count: item?.like_count - 1,
            liked_by_user: unliked_by == myUserId ? 0 : item?.liked_by_user,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SOCKET_ADD_POST_COMMENT: {
      const { post_comment_count, comment } = action.payload;
      const myUserId = root?.user?.userDetail?.data?.data?.id;
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments || [];
                replyedComments.unshift({
                  ...comment,
                  editable: comment?.User?.id == myUserId ? true : false,
                });
                return {
                  ...cmt,
                  replied_comment_count: cmt?.replied_comment_count
                    ? cmt?.replied_comment_count + 1
                    : 1,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments.unshift({
              ...comment,
              editable: comment?.User?.id == myUserId ? true : false,
            });
          }
          return {
            ...item,
            comment_count: post_comment_count,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SOCKET_DELETE_POST_COMMENT: {
      const { comment_id, parent_comment_id, post_id } = action.payload;
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == post_id) {
          let newComments = item?.comments;
          let commentCount = item?.comment_count;
          if (parent_comment_id) {
            newComments = newComments?.map((comment) => {
              if (comment?.id == parent_comment_id) {
                let replyedComments = comment?.replied_comments;
                replyedComments = replyedComments.filter(
                  (reply) => reply?.id != comment_id
                );
                return {
                  ...comment,
                  replied_comment_count: comment?.replied_comment_count - 1,
                  replied_comments: replyedComments,
                };
              } else {
                return comment;
              }
            });
          } else {
            newComments = newComments?.filter(
              (comment) => comment?.id != comment_id
            );
            commentCount = item?.comment_count - 1;
          }
          return {
            ...item,
            comment_count: commentCount,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SOCKET_UPDATE_POST_COMMENT: {
      const { comment } = action.payload;
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == comment?.post_id) {
          let newComments = item?.comments;
          if (comment?.comment_id) {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.comment_id) {
                let replyedComments = cmt?.replied_comments;
                replyedComments = replyedComments?.map((reply) => {
                  if (reply?.id == comment?.id) {
                    return {
                      ...reply,
                      comment: comment?.comment,
                    };
                  } else {
                    return reply;
                  }
                });
                return {
                  ...cmt,
                  replied_comments: replyedComments,
                };
              } else {
                return cmt;
              }
            });
          } else {
            newComments = newComments?.map((cmt) => {
              if (cmt?.id == comment?.id) {
                return {
                  ...cmt,
                  comment: comment?.comment,
                };
              } else {
                return cmt;
              }
            });
          }
          return {
            ...item,
            comments: newComments,
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case START_ADD_BOOKMARK: {
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.payload?.id) {
          return {
            ...item,
            bookmark_id: true,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SUCCESS_ADD_BOOKMARK: {
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            bookmark_id: action?.payload?.bookmark?.id,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case START_REMOVE_BOOKMARK: {
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.payload?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SUCCESS_REMOVE_BOOKMARK: {
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.bookmark_id == action?.bookmark_id) {
          return {
            ...item,
            bookmark_id: "",
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case SUCCESS_UNLOCK_POST: {
      let oldPosts = { ...state?.searchData?.data };
      oldPosts["posts"] = oldPosts?.posts?.map((item) => {
        if (item?.id == action?.post_id) {
          return {
            ...item,
            ...action?.payload?.post,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: oldPosts,
        },
      };
    }

    case START_USER_SEARCH: {
      let oldList = { ...state?.searchUsersList?.data };
      return {
        ...state,
        searchUsersList: {
          isLoading: action?.payload?.offset > 0 ? false : true,
          data: action?.payload?.offset > 0 ? oldList : {},
          error: "",
        },
      };
    }
    case SUCCESS_USER_SEARCH: {
      let oldList = { ...state?.searchUsersList?.data };
      if (oldList?.users?.rows?.length > 0) {
        oldList["users"]["rows"] = [
          ...oldList?.users?.rows,
          ...action?.payload?.users?.rows,
        ];
      } else {
        oldList = action?.payload;
      }
      return {
        ...state,
        searchUsersList: {
          isLoading: false,
          data: oldList,
          error: "",
        },
      };
    }
    case ERROR_USER_SEARCH: {
      return {
        ...state,
        searchUsersList: {
          isLoading: false,
          data: {},
          error: action.payload,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
