export const RESET_GLOBAL_STATE = "RESET_GLOBAL_STATE";

export const START_GET_PUBLIC_PROFILE_POSTS = "START_GET_PUBLIC_PROFILE_POSTS";
export const SUCCESS_GET_PUBLIC_PROFILE_POSTS =
  "SUCCESS_GET_PUBLIC_PROFILE_POSTS";
export const ERRRO_GET_PUBLIC_PROFILE_POSTS = "ERRRO_GET_PUBLIC_PROFILE_POSTS";

export const START_GET_PUBLIC_PROFILE_MEDIA = "START_GET_PUBLIC_PROFILE_MEDIA";
export const SUCCESS_GET_PUBLIC_PROFILE_MEDIA =
  "SUCCESS_GET_PUBLIC_PROFILE_MEDIA";
export const ERRRO_GET_PUBLIC_PROFILE_MEDIA = "ERRRO_GET_PUBLIC_PROFILE_MEDIA";

export const START_GET_PUBLIC_POST_BY_ID = "START_GET_PUBLIC_POST_BY_ID";
export const SUCCESS_GET_PUBLIC_POST_BY_ID = "SUCCESS_GET_PUBLIC_POST_BY_ID";
export const ERROR_GET_PUBLIC_POST_BY_ID = "ERROR_GET_PUBLIC_POST_BY_ID";
