import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_ADD_PROMOTION_CAMPAIGN,
  ERROR_ADD_SUBSCRIPTION_BUNDLE,
  ERROR_CHECK_FREE_TRIAL_AVAILABILITY,
  ERROR_CREATE_FREE_TRIAL_LINKS,
  ERROR_DELETE_FREE_TRIAL_LINKS,
  ERROR_DELETE_PROMOTION_CAMPAIGN,
  ERROR_DELETE_SUBSCRIPTION_BUNDLE,
  ERROR_GET_FREE_TRIAL_LINKS,
  ERROR_JOIN_FREE_TRIAL,
  ERROR_SET_SUBSCRIPTION_PRICE,
  ERROR_STOP_PROMOTION_CAMPAIGN,
  ERROR_SUBSCRIPTION_ACTION,
  START_ADD_PROMOTION_CAMPAIGN,
  START_ADD_SUBSCRIPTION_BUNDLE,
  START_CHECK_FREE_TRIAL_AVAILABILITY,
  START_CREATE_FREE_TRIAL_LINKS,
  START_DELETE_FREE_TRIAL_LINKS,
  START_DELETE_PROMOTION_CAMPAIGN,
  START_DELETE_SUBSCRIPTION_BUNDLE,
  START_GET_FREE_TRIAL_LINKS,
  START_JOIN_FREE_TRIAL,
  START_SET_SUBSCRIPTION_PRICE,
  START_STOP_PROMOTION_CAMPAIGN,
  START_SUBSCRIPTION_ACTION,
  SUCCCESS_ADD_SUBSCRIPTION_BUNDLE,
  SUCCESS_ADD_PROMOTION_CAMPAIGN,
  SUCCESS_CHECK_FREE_TRIAL_AVAILABILITY,
  SUCCESS_CREATE_FREE_TRIAL_LINKS,
  SUCCESS_DELETE_FREE_TRIAL_LINKS,
  SUCCESS_DELETE_PROMOTION_CAMPAIGN,
  SUCCESS_DELETE_SUBSCRIPTION_BUNDLE,
  SUCCESS_GET_FREE_TRIAL_LINKS,
  SUCCESS_JOIN_FREE_TRIAL,
  SUCCESS_SET_SUBSCRIPTION_PRICE,
  SUCCESS_STOP_PROMOTION_CAMPAIGN,
  SUCCESS_SUBSCRIPTION_ACTION,
} from "Actions/SubscriptionAction";
import {
  addPromotionCampaignAPI,
  addSubscriptionBundleAPI,
  checkFreeTrialAvailabilityAPI,
  createFreeTrialLinkAPI,
  deleteFreeTrialLinkAPI,
  deletePromotionCampaignAPI,
  deleteSubscriptionBundleAPI,
  getFreeTrialLinksAPI,
  joinFreeTrialAPI,
  setSubscriptionPriceAPI,
  stopPromotionCampaignAPI,
  subscriptionActionAPI,
} from "Api/SubscriptionApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* setSubscriptionPriceWorker({ payload }) {
  try {
    const res = yield call(setSubscriptionPriceAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SET_SUBSCRIPTION_PRICE,
        payload: res,
        price: payload?.subscription_price_per_month,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved successfully",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_SET_SUBSCRIPTION_PRICE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong!",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getFreetrialLinksWorker({ payload }) {
  try {
    const res = yield call(getFreeTrialLinksAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_FREE_TRIAL_LINKS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_FREE_TRIAL_LINKS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* createFreeTrialLinksWorker({ payload }) {
  try {
    const res = yield call(createFreeTrialLinkAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CREATE_FREE_TRIAL_LINKS,
        payload: res,
      });
    } else {
      yield put({
        tyep: ERROR_CREATE_FREE_TRIAL_LINKS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deleteFreeTrialLinksWorker({ link_id }) {
  try {
    const res = yield call(deleteFreeTrialLinkAPI, link_id);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_FREE_TRIAL_LINKS,
        payload: res,
        link_id: link_id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_FREE_TRIAL_LINKS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* checkFreeTrialAvailabilityWorker({ payload }) {
  try {
    const res = yield call(checkFreeTrialAvailabilityAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CHECK_FREE_TRIAL_AVAILABILITY,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CHECK_FREE_TRIAL_AVAILABILITY,
        payload: res,
      });
    }
  } catch (error) {}
}

function* joinFreeTrialWorker({ payload }) {
  try {
    const res = yield call(joinFreeTrialAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_JOIN_FREE_TRIAL,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_JOIN_FREE_TRIAL,
        payload: res,
      });
    }
  } catch (error) {}
}

function* addSubscriptionBundleWorker({ payload }) {
  try {
    const res = yield call(addSubscriptionBundleAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCCESS_ADD_SUBSCRIPTION_BUNDLE,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_ADD_SUBSCRIPTION_BUNDLE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          type: "error",
          message: res?.message || "Something went wrong! Bundle not creared.",
        },
      });
    }
  } catch (error) {}
}

function* deleteSubscriptionBundleWorker({ bundle_id }) {
  try {
    const res = yield call(deleteSubscriptionBundleAPI, bundle_id);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_SUBSCRIPTION_BUNDLE,
        payload: res,
        bundle_id: bundle_id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_SUBSCRIPTION_BUNDLE,
        payload: res,
      });
    }
  } catch (error) {}
}

function* addPromotionCampaignWorker({ payload }) {
  try {
    const res = yield call(addPromotionCampaignAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_ADD_PROMOTION_CAMPAIGN,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_ADD_PROMOTION_CAMPAIGN,
        payload: res,
      });
    }
  } catch (error) {}
}

function* deletePromotionCampaignWorker({ campaign_id }) {
  try {
    const res = yield call(deletePromotionCampaignAPI, campaign_id);
    if (res.status) {
      yield put({
        type: SUCCESS_DELETE_PROMOTION_CAMPAIGN,
        payload: res,
        campaign_id: campaign_id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_PROMOTION_CAMPAIGN,
        payload: res,
      });
    }
  } catch (error) {}
}

function* stopPromotionCampaignWorker({ payload }) {
  try {
    const res = yield call(stopPromotionCampaignAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_STOP_PROMOTION_CAMPAIGN,
        payload: res,
        promotion_campaign_id: payload?.promotion_campaign_id,
      });
    } else {
      yield put({
        type: ERROR_STOP_PROMOTION_CAMPAIGN,
        payload: res,
      });
    }
  } catch (error) {}
}

function* subscriptionActionWorker({ user_id, payload, amount }) {
  try {
    const res = yield call(subscriptionActionAPI, user_id, payload, amount);
    if (res.status) {
      yield put({
        type: SUCCESS_SUBSCRIPTION_ACTION,
        payload: res,
        data: payload,
        amount: amount,
      });
    } else {
      yield put({
        type: ERROR_SUBSCRIPTION_ACTION,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong! Please try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

export function* SubscriptionWatcher() {
  yield takeEvery(START_SET_SUBSCRIPTION_PRICE, setSubscriptionPriceWorker);
  yield takeEvery(START_GET_FREE_TRIAL_LINKS, getFreetrialLinksWorker);
  yield takeEvery(START_CREATE_FREE_TRIAL_LINKS, createFreeTrialLinksWorker);
  yield takeEvery(START_DELETE_FREE_TRIAL_LINKS, deleteFreeTrialLinksWorker);
  yield takeEvery(
    START_CHECK_FREE_TRIAL_AVAILABILITY,
    checkFreeTrialAvailabilityWorker
  );
  yield takeEvery(START_JOIN_FREE_TRIAL, joinFreeTrialWorker);
  yield takeEvery(START_ADD_SUBSCRIPTION_BUNDLE, addSubscriptionBundleWorker);
  yield takeEvery(
    START_DELETE_SUBSCRIPTION_BUNDLE,
    deleteSubscriptionBundleWorker
  );
  yield takeEvery(START_ADD_PROMOTION_CAMPAIGN, addPromotionCampaignWorker);
  yield takeEvery(
    START_DELETE_PROMOTION_CAMPAIGN,
    deletePromotionCampaignWorker
  );
  yield takeEvery(START_STOP_PROMOTION_CAMPAIGN, stopPromotionCampaignWorker);
  yield takeEvery(START_SUBSCRIPTION_ACTION, subscriptionActionWorker);
}
